import { Modal, Form, Button } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { createConfirmation, confirmable } from "../utils/confirm";
import { useEffectOnce } from "../utils/hooks";
import NumberCustomInput from "./utils/NumberCustomInput";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import DateTime from "react-datetime";
import moment from "moment";
import Select from "react-select";
import { useStoreState } from "easy-peasy";
import { useQuery } from "react-query";
import { fetchActionsUtil } from "../utils/helpers";
import { useAuth } from "../hooks/useAuth";

function UpdateBatchTons({
  proceed,
  show,
  batch,
  Tons,
  Pieces,
  formatType,
  ExpireDate,
  ManDate,
}) {
  const generalSettings = useStoreState((state) => state?.generalSettings);
  const [isLoading, setIsLoading] = useState(false);

  const { backendUrl } = useAuth();

  const { data } = useQuery(
    ["GET_STORAGE_LOCATION_ITEMS"],
    () => fetchActionsUtil(`${backendUrl}/api/items/storage-location`, "GET"),
    {
      enabled: true,
    }
  );
  // console.log(data);
  const initialValues = {
    UnitCost: "",
    UnitPrice: "",
    Tons: "",
    Pieces: "",
    VAT: "", // unit price in pieces is here

    //--------------
    Margin: 0,
    Incentive: 0,
    ExpireDate: "",
    Batch_Name: "",
    StorageLocation: "",
  };
  const schema = yup.object().shape({
    UnitCost: yup.string().required(),
    UnitPrice: yup.string().required(),
    // Pieces: yup.string().required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      proceed(values);
    },
  });

  const setUp = () => {
    formik.setValues({
      UnitCost: batch.UnitCost,
      UnitPrice: batch.UnitPrice,
      VAT: batch.VAT,
      Tons,
      Pieces,
      ExpireDate: ExpireDate ? new Date(ExpireDate) : new Date(),
      ManDate: ManDate ? new Date(ManDate) : new Date(),
      Batch_Name: batch.Batch_Name,
      StorageLocation: batch.StorageLocation,
    });
  };

  useEffectOnce(() => {
    setUp();
  });

  const canClose = () => {
    proceed();
  };

  return (
    <Modal
      show={show}
      onHide={() => canClose()}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h5">
            Edit Batch {batch.Bar_Code ? batch.Bar_Code.split("-")[1] : "..."}
          </h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          {generalSettings.canAddBatchName ? (
            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">
                {generalSettings.Batch_Name}
              </Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder={`Enter ${generalSettings.Batch_Name}`}
                name="Batch_Name"
                value={formik.values.Batch_Name}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.Batch_Name && !!formik.errors.Batch_Name
                }
              />
            </Form.Group>
          ) : null}

          <Form.Group className="mb-3">
            <Form.Label>UnitCost</Form.Label>
            <CurrencyCustomInput
              type="text"
              placeholder="Enter Unit Cost"
              name="UnitCost"
              value={formik.values.UnitCost}
              onValueChange={(value, name) => {
                formik.setFieldValue(name, value);
              }}
              isInvalid={formik.touched.UnitCost && !!formik.errors.UnitCost}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.UnitCost}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>UnitPrice</Form.Label>
            <CurrencyCustomInput
              type="text"
              placeholder="Enter Unit Price"
              name="UnitPrice"
              value={formik.values.UnitPrice}
              onValueChange={(value, name) => {
                formik.setFieldValue(name, value);
              }}
              isInvalid={formik.touched.UnitPrice && !!formik.errors.UnitPrice}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.UnitPrice}
            </Form.Control.Feedback>
          </Form.Group>

          {ManDate && (
            <Form.Group className="mb-3">
              <Form.Label>Man Date</Form.Label>
              <DateTime
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside={true}
                dateFormat="MMM DD, YYYY"
                inputProps={{
                  className: `date-input form-control`,
                  placeholder: "Select date",
                  readOnly: true,
                }}
                value={formik.values.ManDate}
                onChange={(date) => {
                  formik.setFieldValue("ManDate", date, true);
                }}
              />
            </Form.Group>
          )}

          {ExpireDate && (
            <Form.Group className="mb-3">
              <Form.Label>Expire Date</Form.Label>
              <DateTime
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside={true}
                dateFormat="MMM DD, YYYY"
                inputProps={{
                  className: `date-input form-control`,
                  placeholder: "Select date",
                  readOnly: true,
                }}
                value={formik.values.ExpireDate}
                onChange={(date) => {
                  formik.setFieldValue("ExpireDate", date, true);
                }}
              />
            </Form.Group>
          )}

          {formatType !== "normal" ? (
            <>
              <Form.Group className="mb-3">
                <Form.Label>UnitPrice (Pieces)</Form.Label>
                <CurrencyCustomInput
                  type="text"
                  placeholder="Enter Unit Price (Pieces)"
                  name="VAT"
                  value={formik.values.VAT}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={
                    formik.touched.UnitPrice && !!formik.errors.UnitPrice
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.UnitPrice}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>{generalSettings.convertableUOM}</Form.Label>
                <NumberCustomInput
                  type="text"
                  placeholder={`Enter Tons ${generalSettings.convertableUOM}`}
                  name="Tons"
                  value={formik.values.Tons}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={formik.touched.Pieces && !!formik.errors.Tons}
                //  disabled
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Tons}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Pieces</Form.Label>
                <NumberCustomInput
                  type="text"
                  placeholder="Enter Pieces"
                  name="Pieces"
                  value={formik.values.Pieces}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={formik.touched.Pieces && !!formik.errors.Pieces}
                //   disabled
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Pieces}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          ) : (
            <Form.Group className="mb-3">
              <Form.Label>Quantity</Form.Label>
              <NumberCustomInput
                type="text"
                placeholder="Enter Quantity"
                name="Pieces"
                value={formik.values.Pieces}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                isInvalid={formik.touched.Pieces && !!formik.errors.Pieces}
              //   disabled
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.Pieces}
              </Form.Control.Feedback>
            </Form.Group>
          )}

          <Form.Group className="mb-3 pb-2">
            <Form.Label className="mb-1">Storage Location</Form.Label>
            <Select
              classNamePrefix={`form-select`}
              options={data?.storage?.map((el) => ({
                value: el.storageID,
                label: el.name,
              }))}
              value={data?.storage
                ?.map((el) => ({
                  value: el.storageID,
                  label: el.name,
                }))
                ?.find((el) => el.value === formik.values.StorageLocation)}
              onChange={({ value }) => {
                formik.setFieldValue("StorageLocation", value);
              }}
            />
            {formik.touched.StorageLocation &&
              !!formik.errors.StorageLocation ? (
              <span className="custom-invalid-feedback">
                {formik.errors.StorageLocation}
              </span>
            ) : null}
          </Form.Group>

          <Button
            disabled={isLoading}
            variant="primary"
            className="w-100 p-2"
            type="submit"
          >
            {isLoading ? "Please wait…" : "Save"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default function BatchQuantityDialog(props) {
  return createConfirmation(confirmable(UpdateBatchTons))({ ...props });
}
