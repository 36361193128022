import { Modal, Form, Button, Row } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import { useBackendUrl, useEffectOnce } from "../../utils/hooks";
import moment from "moment";
import DateTime from "react-datetime";
import { isEmpty } from "lodash";

function DriverInfo({ proceed, show, previousDetails }) {
  const backendUrl = useBackendUrl();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    DriverName: "",
    DriverPhoneNumber: "",
    TruckNumber: "",
    Date_Log: moment(),
  };
  const schema = yup.object().shape({
    DriverName: yup.string().required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      proceed(values);
    },
  });

  const canClose = () => {
    proceed();
  };

  useEffectOnce(() => {
    if (!isEmpty(previousDetails)) {
      formik.setValues({
        ...previousDetails,
      });
    }
  });

  return (
    <Modal
      show={show}
      onHide={() => canClose()}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h5">Driver Information</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3 pb-1">
            <Form.Label className="mb-1">Driver</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Driver's Name"
              name="DriverName"
              value={formik.values.DriverName}
              onChange={formik.handleChange}
              isInvalid={
                formik.touched.DriverName && !!formik.errors.DriverName
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.DriverName}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3 pb-1">
            <Form.Label className="mb-1">Driver Phone Number</Form.Label>
            <Form.Control
              type="tel"
              placeholder="Enter Phone number"
              name="DriverPhoneNumber"
              value={formik.values.DriverPhoneNumber}
              onChange={formik.handleChange}
            />
          </Form.Group>

          <Row>
            <Form.Group className="col mb-3 pb-1">
              <Form.Label className="mb-1">Truck Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Truck Number"
                name="TruckNumber"
                value={formik.values.TruckNumber}
                onChange={formik.handleChange}
              />
            </Form.Group>

            <Form.Group className="col mb-3 pb-2">
              <Form.Label className="mb-1">Date</Form.Label>
              <DateTime
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside={true}
                dateFormat="MMM DD, YYYY"
                inputProps={{
                  className: `date-input form-control`,
                  placeholder: "Select date",
                  readOnly: true,
                }}
                value={formik.values.Date_Log}
                onChange={(date) => {
                  formik.setFieldValue("Date_Log", date, true);
                }}
              />
            </Form.Group>
          </Row>

          <Button
            disabled={isLoading}
            variant="primary"
            className="w-100 p-2"
            type="submit"
          >
            {isLoading ? "Please wait…" : "Post"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default function DriverInfoDialog(props) {
  return createConfirmation(confirmable(DriverInfo))({ ...props });
}
