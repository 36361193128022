import React, { useEffect, useMemo, useState } from "react";
import PageHeader from "../PageHeader";
import { PaymentAndBillingIcon } from "../Icons";
import { Button, Card, Form, Modal, Table } from "react-bootstrap";
import PaystackPop from "@paystack/inline-js";
import Select from "react-select";

import * as yup from "yup";

import {
  Form as FormikForm,
  ErrorMessage,
  useFormik,
  FieldArray,
  FormikProvider,
} from "formik";
import { waitFor } from "../../utils/helpers";
import currency from "currency.js";
import { ConfirmRemoveUserBillingDialog } from "../ConfirmDialogue";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import { useMutation, useQueries, useQuery } from "react-query";
import NumberCustomInput, {
  NumberCustomInputForPayment,
} from "../utils/NumberCustomInput";
import { useNavigate } from "react-router-dom";
import { uniqBy } from "lodash";
import { backendApis } from "../../config";

const apis = [backendApis];
const allDepartment = [
  "",
  "Admin",
  "Accountant",
  "Cashier",
  "Content Management",
  "Contracts",
  "HR",
  "Sales",
  // "Shout Out",
  "Store",
  "Warehouse",
  "Production",
  "Sales Manager",
  "Operations",
  "Business Development",
  "Procurement",
  "QHSE",
  "Maintenance",
  "Document Control",
  "Government InvexERP",
  "Supply and Logistics",
  "Hospital Management Board",
  "Out Grower"
];

const departmentOptions = [
  { label: "", value: "" },
  { label: "Admin", value: "Admin" },
  { label: "Accountant", value: "Accountant" },
  { label: "Content Management", value: "Content Management" },
  { label: "Contracts", value: "Contracts" },
  { label: "Sales", value: "Sales" },
  { label: "HR", value: "HR" },
  { label: "Warehouse", value: "Warehouse" },
  { label: "Production", value: "Production" },
  { label: "Sales Manager", value: "Sales Manager" },
  { label: "Operations", value: "Operations" },
  { label: "Business Development", value: "Business Development" },
  { label: "Procurement", value: "Business Development" },
  { label: "QHSE", value: "QHSE" },
  { label: "Maintenance", value: "Maintenance" },
  { label: "Document Control", value: "Document Control" },
  { label: "Government InvexERP", value: "Government InvexERP" },
  { label: "Supply and Logistics", value: "Supply and Logistics" },
  { label: "Hospital Management Board", value: "Hospital Management Board" },
];

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const RemoveUserModal = ({
  show,
  handleHide,
  userStatus,
  handleSubmit: submit,
  companyStaff,
  totalUsers,
  currentUsers,
  coreUsers,
  passiveUsers,
  isLoading,
}) => {
  const [removedUser, setRemovedUsers] = useState([]);
  const [currentCoreUsers, setCurrentCoreUsers] = useState(null);
  const [currentPassiveUses, setCurrentPassiveUsers] = useState(null);
  const formik = useFormik({
    initialValues: {
      user: [{ Name: "", Department: "", existingUsers: "", Staff_ID: "" }],
    },

    onSubmit: (values) => {
      if (process.env.REACT_APP_SITE_TITLE === "InvexERP") {
        const selectCoreUsers = values.user.filter(
          (user) => user.userType === "core"
        ).length;

        const selectedPassiveUsers = values.user.filter(
          (user) => user.userType === "passive"
        ).length;

        if (selectCoreUsers > coreUsers) {
          formik.setErrors({
            user: `core user limit exceeded`,
          });
          throw new Error(`core user limit exceeded`);
        }

        // if (selectCoreUsers < coreUsers) {
        //   formik.setErrors({
        //     user: `add more ${coreUsers - selectCoreUsers} core users`,
        //   });
        //   throw new Error(`add more ${coreUsers - selectCoreUsers} core users`);
        // }
        // if (selectedPassiveUsers < passiveUsers) {
        //   formik.setErrors({
        //     user: `add more ${passiveUsers -
        //       selectedPassiveUsers} passive users`,
        //   });
        //   throw new Error(
        //     `add more ${passiveUsers - selectedPassiveUsers} passive users`
        //   );
        // }
        if (selectedPassiveUsers > passiveUsers) {
          formik.setErrors({
            user: `passive user limit exceeded`,
          });
          throw new Error(`passive user limit exceeded`);
        }
      }

      if (values.user.length > totalUsers) {
        formik.setErrors({
          user: `user limit exceeded`,
        });
        throw new Error(`user limit exceeded`);
      } else {
        const addedUsers = 0;
        const removedUsers = currentUsers - totalUsers;
        const userActionType = "expiredRemovedUsers";

        const userDetails = removedUser.map((user) => {
          return {
            Name: user.Name,
            Staff_ID: user.Staff_ID,
            email: user?.email ? user?.email : "",
            branchUrl: user?.url ? user?.url : [],
            branch: user.Branch ? user.Branch : "",
            department: user.Department,
            username: user?.username,
          };
        });

        const userType = values.user.map((staff) => ({
          Staff_ID: staff.Staff_ID,
          branchUrl: staff?.url ? staff?.url : [],
          userType: staff.userType,
          username: staff?.username,
        }));

        submit(
          userDetails,
          addedUsers,
          removedUsers,
          userActionType,
          currentUsers,
          process.env.REACT_APP_SITE_TITLE === "InvexERP" ? userType : ""
        );
      }
    },
  });

  useEffect(() => {
    const staffUsers = companyStaff.map((user) => ({
      ...user,
      userType: user?.userType ? user?.userType : "core",
    }));

    formik.setFieldValue("user", staffUsers);

    formik.setFieldValue("existingUsers", staffUsers);

    const staffOptions = allDepartment?.map((department) => ({
      label: department,
      value: department,
    }));

    formik.setFieldValue("staff", staffOptions);

    setCurrentCoreUsers(
      companyStaff.filter((user) => user.userType === "core").length
    );
    setCurrentPassiveUsers(
      companyStaff.filter((user) => user.userType === "passive").length
    );
    setRemovedUsers([]);
  }, [companyStaff]);

  useEffect(() => {
    if (process.env.REACT_APP_SITE_TITLE === "InvexERP") {
      setCurrentCoreUsers(
        formik.values?.user.filter((user) => user.userType === "core").length
      );
      setCurrentPassiveUsers(
        formik.values.user.filter((user) => user.userType === "passive").length
      );
    }
  }, [formik.values?.user]);

  return (
    <Modal
      show={show}
      centered
      onHide={handleHide}
      enforceFocus={false}
      backdrop="true"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>{userStatus}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <div className="content px-4 pb-4">
              <FieldArray
                name="user"
                render={(arrayHelpers) => (
                  <div className="content px-4 pb-4">
                    <Table responsive className="product-table " striped>
                      <thead>
                        <tr>
                          <td className=" fw-bold">Staff ID</td>
                          <td className=" fw-bold">Name</td>
                          <td className=" fw-bold">Department</td>
                          <td className=" fw-bold">Branch</td>
                          {process.env.REACT_APP_SITE_TITLE === "InvexERP" && (
                            <td className=" fw-bold">User Type</td>
                          )}

                          <td className=" fw-bold" />
                        </tr>
                      </thead>
                      <tbody>
                        {formik.values.user.map((el, index) => (
                          <tr>
                            <td className=" ">
                              {formik.values.user[index].Staff_ID}
                            </td>

                            <td className="">
                              {formik.values.user[index].Name}
                            </td>

                            <td className="">
                              {formik.values.user[index].Department}
                            </td>
                            <td className="">
                              {formik.values.user[index].Branch}
                            </td>

                            {process.env.REACT_APP_SITE_TITLE ===
                              "InvexERP" && (
                                <td>
                                  <div className=" w-100">
                                    <select
                                      className="form-select"
                                      aria-label="Default select example"
                                      name={`user[${index}].userType`}
                                      value={
                                        formik?.values?.user[index]?.userType
                                      }
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          `user[${index}].userType`,
                                          e.target.value
                                        );
                                      }}
                                    >
                                      <option
                                        value={"core"}
                                        selected={
                                          formik?.values?.user[index]
                                            ?.userType === "core"
                                        }
                                      >
                                        core
                                      </option>
                                      <option
                                        value={"passive"}
                                        selected={
                                          formik?.values?.user[index]
                                            ?.userType === "passive"
                                        }
                                      >
                                        passive
                                      </option>
                                    </select>
                                  </div>
                                </td>
                              )}

                            <td>
                              <Button
                                title="Remove"
                                variant=""
                                type="button"
                                size="xs"
                                onClick={() => {
                                  setRemovedUsers([...removedUser, el]);

                                  arrayHelpers.remove(index);
                                }}
                              >
                                ✖
                              </Button>
                            </td>
                          </tr>
                        ))}{" "}
                      </tbody>

                      <tfoot>
                        <div className=" text-danger my-3">
                          {formik.touched &&
                            formik.errors &&
                            formik?.errors?.user}
                        </div>
                        <tr>
                          <td colspan="7 ">
                            <div className="w-100  d-flex  justify-content-between align-items-center">
                              <div>
                                {process.env.REACT_APP_SITE_TITLE ===
                                  "InvexERP" && (
                                    <tr className=" my-0 p-1  ">
                                      <td className=" my-0 p-1  ">
                                        Core users:{" "}
                                      </td>
                                      <td className=" my-0 p-1  ">
                                        {" "}
                                        {currentCoreUsers} / {coreUsers}
                                      </td>
                                    </tr>
                                  )}

                                {process.env.REACT_APP_SITE_TITLE ===
                                  "InvexERP" && (
                                    <tr className=" my-0 p-1  ">
                                      <td className=" my-0 p-1  ">
                                        {" "}
                                        Passive users:
                                      </td>
                                      <td className=" my-0 p-1  ">
                                        {currentPassiveUses} / {passiveUsers}
                                      </td>
                                    </tr>
                                  )}

                                <tr className=" my-0 p-1  ">
                                  <td className=" my-0 p-1  ">Total users:</td>
                                  <td className=" my-0 p-1  ">
                                    {formik.values.user.length} / {totalUsers}
                                  </td>
                                </tr>
                              </div>
                              <Button variant="primary" type="submit">
                                {isLoading ? "Please wait" : " Continue"}
                              </Button>
                            </div>
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </div>
                )}
              />
            </div>
          </Form>
        </FormikProvider>
      </Modal.Body>
    </Modal>
  );
};

const AssignUserTypeModal = ({
  show,
  handleHide,
  userStatus,
  handleSubmit: submit,
  companyStaff,
  totalUsers,
  currentUsers,
  coreUsers,
  passiveUsers,
  isLoading,
}) => {
  const [removedUser, setRemovedUsers] = useState([]);
  const [currentCoreUsers, setCurrentCoreUsers] = useState(null);
  const [currentPassiveUses, setCurrentPassiveUsers] = useState(null);
  const formik = useFormik({
    initialValues: {
      user: [{ Name: "", Department: "", existingUsers: "", Staff_ID: "" }],
    },

    onSubmit: (values) => {
      if (process.env.REACT_APP_SITE_TITLE === "InvexERP") {
        const selectCoreUsers = values.user.filter(
          (user) => user.userType === "core"
        ).length;

        const selectedPassiveUsers = values.user.filter(
          (user) => user.userType === "passive"
        ).length;

        if (selectCoreUsers > coreUsers) {
          formik.setErrors({
            user: `core user limit exceeded`,
          });
          throw new Error(`core user limit exceeded`);
        }
        if (selectedPassiveUsers > passiveUsers) {
          formik.setErrors({
            user: `passive user limit exceeded`,
          });
          throw new Error(`passive user limit exceeded`);
        }

        // if (selectCoreUsers < coreUsers) {
        //   formik.setErrors({
        //     user: `add more ${coreUsers - selectCoreUsers} core users`,
        //   });
        //   throw new Error(`add more ${coreUsers - selectCoreUsers} core users`);
        // }
        // if (selectedPassiveUsers < passiveUsers) {
        //   formik.setErrors({
        //     user: `add more ${passiveUsers -
        //       selectedPassiveUsers} passive users`,
        //   });
        //   throw new Error(
        //     `add more ${passiveUsers - selectedPassiveUsers} passive users`
        //   );
        // }
      }
      const userType = values.user.map((staff) => ({
        Staff_ID: staff.Staff_ID,
        userType: staff.userType,
        branchUrl: staff?.url ? staff?.url : [],
        username: staff?.username,
      }));

      submit(userType);

      // if (values.user.length > totalUsers) {
      //   formik.setErrors({
      //     user: `user limit exceeded`,
      //   });
      //   throw new Error(`user limit exceeded`);
      // } else {
      //   const addedUsers = 0;
      //   const removedUsers = currentUsers - totalUsers;
      //   const userActionType = "expiredRemovedUsers";

      //   const userDetails = removedUser.map((user) => {
      //     return {
      //       Name: user.Name,
      //       Staff_ID: user.Staff_ID,
      //       email: user?.email ? user?.email : "",
      //       branchUrl: user?.url ? user?.url : [],
      //       branch: user.Branch ? user.Branch : "",
      //       department: user.Department,
      //     };
      //   });

      //   const userType = values.user.map((staff) => ({
      //     Staff_ID: staff.Staff_ID,

      //     userType: staff.userType,
      //   }));

      //   submit(userType);
      // }
    },
  });

  useEffect(() => {
    const staffUsers = companyStaff.map((user) => ({
      ...user,
      userType: user?.userType ? user?.userType : "core",
    }));
    formik.setFieldValue("user", staffUsers);

    formik.setFieldValue("existingUsers", staffUsers);

    const staffOptions = allDepartment?.map((department) => ({
      label: department,
      value: department,
    }));

    formik.setFieldValue("staff", staffOptions);

    setCurrentCoreUsers(
      companyStaff.filter((user) => user.userType === "core").length
    );
    setCurrentPassiveUsers(
      companyStaff.filter((user) => user.userType === "passive").length
    );
    setRemovedUsers([]);
  }, [companyStaff]);

  useEffect(() => {
    if (process.env.REACT_APP_SITE_TITLE === "InvexERP") {
      setCurrentCoreUsers(
        formik.values?.user.filter((user) => user.userType === "core").length
      );
      setCurrentPassiveUsers(
        formik.values.user.filter((user) => user.userType === "passive").length
      );
    }
  }, [formik?.values?.user, currentCoreUsers, currentPassiveUses]);

  return (
    <Modal
      show={show}
      centered
      onHide={handleHide}
      enforceFocus={false}
      backdrop="true"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title className="h5">Assign User Type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <div className="content px-4 pb-4">
              <FieldArray
                name="user"
                render={(arrayHelpers) => (
                  <div className="content px-4 pb-4">
                    <Table responsive className="product-table " striped>
                      <thead>
                        <tr>
                          <td className=" fw-bold">Staff ID</td>
                          <td className=" fw-bold">Name</td>
                          <td className=" fw-bold">Department</td>
                          <td className=" fw-bold">Branch</td>
                          {process.env.REACT_APP_SITE_TITLE === "InvexERP" && (
                            <td className=" fw-bold">User Type</td>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {formik.values.user.map((el, index) => (
                          <tr>
                            <td className=" ">
                              {formik.values.user[index].Staff_ID}
                            </td>

                            <td className="">
                              {formik.values.user[index].Name}
                            </td>

                            <td className="">
                              {formik.values.user[index].Department}
                            </td>
                            <td className="">
                              {formik.values.user[index].Branch}
                            </td>

                            {process.env.REACT_APP_SITE_TITLE ===
                              "InvexERP" && (
                                <td>
                                  <div className=" w-100">
                                    <select
                                      className="form-select"
                                      aria-label="Default select example"
                                      name={`user[${index}].userType`}
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          `user[${index}].userType`,
                                          e.target.value
                                        );
                                      }}
                                    >
                                      <option
                                        selected={
                                          formik?.values?.user[index]
                                            ?.userType === "core"
                                        }
                                        value={"core"}
                                      >
                                        core
                                      </option>
                                      <option
                                        selected={
                                          formik?.values?.user[index]
                                            ?.userType === "passive"
                                        }
                                        value={"passive"}
                                      >
                                        passive
                                      </option>
                                    </select>
                                  </div>
                                </td>
                              )}
                          </tr>
                        ))}{" "}
                      </tbody>

                      <tfoot>
                        <div className=" text-danger my-3">
                          {formik.touched &&
                            formik.errors &&
                            formik?.errors?.user}
                        </div>
                        <tr>
                          <td colspan="7 ">
                            <div className="w-100  d-flex  justify-content-between align-items-center">
                              <div>
                                <tr className=" my-0 p-1  ">
                                  <td className=" my-0 p-1  ">Core users: </td>
                                  <td className=" my-0 p-1  ">
                                    {" "}
                                    {currentCoreUsers} / {coreUsers}
                                  </td>
                                </tr>
                                <tr className=" my-0 p-1  ">
                                  <td className=" my-0 p-1  ">
                                    {" "}
                                    Passive users:
                                  </td>
                                  <td className=" my-0 p-1  ">
                                    {currentPassiveUses} / {passiveUsers}
                                  </td>
                                </tr>
                              </div>
                              <Button variant="primary" type="submit">
                                {isLoading ? "Please wait" : " Continue"}
                              </Button>
                            </div>
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </div>
                )}
              />
            </div>
          </Form>
        </FormikProvider>
      </Modal.Body>
    </Modal>
  );
};
const initialValues = {
  addUsers: 1,
  totalUsers: 0,
  totalAmount: 0,
  currentBalance: 0,
  billing: 0,
  currentPlan: "",
  currentUsers: null,
  planDurationType: "",
  pricePerUser: 0,
  coreUsers: 0,
  passiveUsers: 0,
  coreUsersAmount: 0,
  passiveUsersAmount: 0,
};

export default function RenewExpiredAccount() {
  const { backendUrl, token, user } = useAuth();
  const [companyStaff, setComapanyStaff] = useState([]);
  const [totalUsers, setTotalUsers] = useState(null);
  const [show, setShow] = useState(false);
  const [removeUserModal, setRemoveUserModal] = useState(false);
  const [userStatus, setUserStatus] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const [activeBtn, setActiveBtn] = useState("");
  const navigate = useNavigate();
  const [companyUrl, setCompanyUrl] = useState("");
  const [showUserTypeModal, setShowUserTypeModal] = useState(false);
  const [showAddUserTypeModal, setShowAddUserTypeModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleShow = () => {
    setShow(true);
  };
  const handleHideRemoveUserModal = () => {
    setRemoveUserModal(false);
  };

  const handleHide = () => {
    setShow(false);
  };
  const paystackConfig = {
    //  reference: new Date().getTime().toString(),
    //  email: "user@example.com",
    // amount: 20000, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    key: process.env.REACT_APP_BILLING_AND_PAYMENT_PAYSTACK_PUBLIC_KEY,
  };

  const pay = async ({ amount, reference, email, companyUrl }) => {
    try {
      await waitFor(500);
      const paystack = new PaystackPop();
      paystack.newTransaction({
        ...paystackConfig,
        reference,
        email,
        amount: currency(amount).multiply(100).value,
        metadata: {
          type: "billing",
          companyUrl: companyUrl,
        },
        // other params

        onSuccess: async (transaction) => {
          // await fetch(`${backendUrl}/api/company/company-billing`, {
          //   method: "GET",
          //   credentials: "include",
          //   headers: {
          //     Accept: "Application/json",
          //     "Content-Type": "Application/json",
          //     ...(token ? { Authorization: `Bearer ${token}` } : {}),
          //   },
          // });

          navigate(
            `/process-billing-payment-transaction?reference=${transaction.reference
            }&q=${formik.values.addUsers}`
          );
        },
        onCancel: async () => {
          // user closed popup

          console.log("closed");
        },
      });
    } catch (err) {
      console.log(err);
      toast.error(JSON.stringify(err));
    }
  };
  const payForRemainingUsers = async ({
    amount,
    reference,
    email,
    companyUrl,
  }) => {
    try {
      await waitFor(500);
      const paystack = new PaystackPop();
      paystack.newTransaction({
        ...paystackConfig,
        companyUrl,
        reference,
        email,
        amount: currency(amount).multiply(100).value,
        metadata: {
          type: "billing",

          companyUrl: companyUrl,
        },
        // other params

        onSuccess: async (transaction) => {
          // await fetch(`${backendUrl}/api/company/company-billing`, {
          //   method: "GET",
          //   credentials: "include",
          //   headers: {
          //     Accept: "Application/json",
          //     "Content-Type": "Application/json",
          //     ...(token ? { Authorization: `Bearer ${token}` } : {}),
          //   },
          // });

          navigate(
            `/process-billing-payment-transaction?reference=${transaction.reference
            }&q=${formik.values.addUsers}`
          );
        },
        onCancel: async () => {
          // user closed popup
          console.log("closed");
        },
      });
    } catch (err) {
      console.log(err);
      toast.error(JSON.stringify(err));
    }
  };

  const handleSubmit = async (
    values,
    addedUsers,
    removedUsers,
    userActionType,
    currentUsers,
    userType
  ) => {
    const reference = new Date().getTime().toString();

    let checkAmount =
      process.env.REACT_APP_SITE_TITLE === "InvexERP"
        ? Number(formik.values.coreUsersAmount) *
        Number(formik.values.coreUsers) +
        Number(formik.values.passiveUsersAmount) *
        Number(formik.values.passiveUsers)
        : formik.values.billing;

    if (!Boolean(checkAmount)) {
      toast.error("invalid number of users entered");

      return;
    }

    setIsLoading(true);
    const res = await fetch(
      `${backendUrl}/api/company/save-company-billing-metadata`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          metadata: {
            email: user?.email,
            type: "billing",
            amount:
              process.env.REACT_APP_SITE_TITLE === "InvexERP"
                ? Number(formik.values.coreUsersAmount) *
                Number(formik.values.coreUsers) +
                Number(formik.values.passiveUsersAmount) *
                Number(formik.values.passiveUsers)
                : formik.values.billing,
            reference,
            totalUsers: formik.values.addUsers,
            userDetails: JSON.stringify(values),
            addedUsers,
            removedUsers,
            userActionType,
            currentUsers,
            planDurationType: formik.values.planDurationType,

            pricePerUser: formik.values.pricePerUser,
            coreUsers:
              process.env.REACT_APP_SITE_TITLE === "InvexERP"
                ? Number(formik.values.coreUsers)
                : "",
            passiveUsers:
              process.env.REACT_APP_SITE_TITLE === "InvexERP"
                ? Number(formik.values.passiveUsers)
                : "",
            invexVersion: process.env.REACT_APP_SITE_TITLE,
            userType,
            backendApis: backendApis,
            staffName: user?.Name,
            // coreUsers: formik.values.coreUsers,
            // passiveUsers: formik.values.passiveUsers,
            coreUsersAmount: formik.values.coreUsersAmount,
            passiveUsersAmount: formik.values.passiveUsersAmount,
          },
        }),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      }
    );

    setIsLoading(false);

    if (!res.ok) {
      toast.error("try again!");

      return;
    }

    await payForRemainingUsers({
      email: user?.email,
      amount:
        process.env.REACT_APP_SITE_TITLE === "InvexERP"
          ? Number(formik.values.coreUsersAmount) *
          Number(formik.values.coreUsers) +
          Number(formik.values.passiveUsersAmount) *
          Number(formik.values.passiveUsers)
          : formik.values.billing,
      reference,
      companyUrl: companyUrl,
    });
  };

  const formik = useFormik({
    initialValues,

    onSubmit: async () => {
      if (process.env.REACT_APP_SITE_TITLE === "InvexERP") {
        if (
          Number(formik.values.coreUsers) +
          Number(formik.values.passiveUsers) !==
          Number(formik.values.addUsers) ||
          Number(formik.values.coreUsers) > Number(formik.values.addUsers) ||
          Number(formik.values.passiveUsers) > Number(formik.values.addUsers) ||
          Number(formik.values.coreUsersAmount) *
          Number(formik.values.coreUsers) +
          Number(formik.values.passiveUsersAmount) *
          Number(formik.values.passiveUsers) <=
          0
        ) {
          formik.setErrors({ error: "user limit exceeded" });
          throw new Error("user limit exceeded");
        }
      }

      if (formik.values.addUsers === formik.values.currentUsers) {
        if (process.env.REACT_APP_SITE_TITLE === "InvexERP") {
          setShowUserTypeModal(true);
        } else {
          let checkAmount =
            process.env.REACT_APP_SITE_TITLE === "InvexERP"
              ? Number(formik.values.coreUsersAmount) *
              Number(formik.values.coreUsers) +
              Number(formik.values.passiveUsersAmount) *
              Number(formik.values.passiveUsers)
              : formik.values.billing;

          if (!Boolean(checkAmount)) {
            toast.error("invalid number of users entered");

            return;
          }
          const reference = new Date().getTime().toString();

          setIsLoading(true);

          const res = await fetch(
            `${backendUrl}/api/company/save-company-billing-metadata`,
            {
              method: "POST",
              credentials: "include",
              body: JSON.stringify({
                metadata: {
                  email: user?.email,
                  amount:
                    process.env.REACT_APP_SITE_TITLE === "InvexERP"
                      ? Number(formik.values.coreUsersAmount) *
                      Number(formik.values.coreUsers) +
                      Number(formik.values.passiveUsersAmount) *
                      Number(formik.values.passiveUsers)
                      : formik.values.billing,
                  reference,
                  totalUsers: formik.values.addUsers,
                  type: "billing",
                  addedUsers: 0,
                  removedUsers: 0,
                  userActionType: "expiredRenew",
                  currentUsers: formik.values.currentUsers,
                  planDurationType: formik.values.planDurationType,
                  // invexVersion: process.env.REACT_APP_SITE_TITLE,
                  pricePerUser: formik.values.pricePerUser,
                  coreUsers:
                    process.env.REACT_APP_SITE_TITLE === "InvexERP"
                      ? Number(formik.values.coreUsers)
                      : "",
                  passiveUsers:
                    process.env.REACT_APP_SITE_TITLE === "InvexERP"
                      ? Number(formik.values.passiveUsers)
                      : "",
                  invexVersion: process.env.REACT_APP_SITE_TITLE,
                  userType: "",
                  backendApis: backendApis,
                  staffName: user?.Name,

                  coreUsersAmount: formik.values.coreUsersAmount,
                  passiveUsersAmount: formik.values.passiveUsersAmount,
                },
              }),
              headers: {
                Accept: "Application/json",
                "Content-Type": "Application/json",
                ...(token ? { Authorization: `Bearer ${token}` } : {}),
              },
            }
          );

          setIsLoading(false);

          if (!res.ok) {
            toast.error("try again!");

            return;
          }

          await payForRemainingUsers({
            reference,
            email: user?.email,
            amount:
              process.env.REACT_APP_SITE_TITLE === "InvexERP"
                ? Number(formik.values.coreUsersAmount) *
                Number(formik.values.coreUsers) +
                Number(formik.values.passiveUsersAmount) *
                Number(formik.values.passiveUsers)
                : formik.values.billing,
            companyUrl: companyUrl,
          });
        }
      } else if (formik.values.addUsers < formik.values.currentUsers) {
        const confirm = await ConfirmRemoveUserBillingDialog({
          title: `Remove users`,
          description: `Are you sure you want proceed?`,
        });

        if (confirm) {
          setUserStatus("Remove users");
          setRemoveUserModal(true);
          setTotalUsers(formik.values.addUsers);
        }
      } else {
        if (process.env.REACT_APP_SITE_TITLE === "InvexERP") {
          setShowAddUserTypeModal(true);
        } else {
          const reference = new Date().getTime().toString();

          let checkAmount =
            process.env.REACT_APP_SITE_TITLE === "InvexERP"
              ? Number(formik.values.coreUsersAmount) *
              Number(formik.values.coreUsers) +
              Number(formik.values.passiveUsersAmount) *
              Number(formik.values.passiveUsers)
              : formik.values.billing;

          if (!Boolean(checkAmount)) {
            toast.error("invalid number of users entered");

            return;
          }

          setIsLoading(true);

          const res = await fetch(
            `${backendUrl}/api/company/save-company-billing-metadata`,
            {
              method: "POST",
              credentials: "include",
              body: JSON.stringify({
                metadata: {
                  email: user?.email,
                  amount:
                    process.env.REACT_APP_SITE_TITLE === "InvexERP"
                      ? Number(formik.values.coreUsersAmount) *
                      Number(formik.values.coreUsers) +
                      Number(formik.values.passiveUsersAmount) *
                      Number(formik.values.passiveUsers)
                      : formik.values.billing,
                  reference,
                  type: "billing",

                  totalUsers: formik.values.addUsers,
                  addedUsers:
                    formik.values.addUsers - formik.values.currentUsers,
                  removedUsers: 0,
                  userActionType: "expiredAddedUsers",
                  currentUsers: formik.values.currentUsers,
                  planDurationType: formik.values.planDurationType,
                  pricePerUser: formik.values.pricePerUser,
                  coreUsers:
                    process.env.REACT_APP_SITE_TITLE === "InvexERP"
                      ? Number(formik.values.coreUsers)
                      : "",
                  passiveUsers:
                    process.env.REACT_APP_SITE_TITLE === "InvexERP"
                      ? Number(formik.values.passiveUsers)
                      : "",
                  invexVersion: process.env.REACT_APP_SITE_TITLE,
                  userType: "",
                  backendApis: backendApis,
                  staffName: user?.Name,

                  coreUsersAmount: formik.values.coreUsersAmount,
                  passiveUsersAmount: formik.values.passiveUsersAmount,
                },
              }),
              headers: {
                Accept: "Application/json",
                "Content-Type": "Application/json",
                ...(token ? { Authorization: `Bearer ${token}` } : {}),
              },
            }
          );

          setIsLoading(false);

          if (!res.ok) {
            toast.error("try again!");

            return;
          }

          await pay({
            reference,
            email: user?.email,
            amount:
              process.env.REACT_APP_SITE_TITLE === "InvexERP"
                ? Number(formik.values.coreUsersAmount) *
                Number(formik.values.coreUsers) +
                Number(formik.values.passiveUsersAmount) *
                Number(formik.values.passiveUsers)
                : formik.values.billing,
            companyUrl: companyUrl,
          });
        }
      }
    },
  });

  const handlePayBillForRenewingUser = async (userType) => {
    let checkAmount =
      process.env.REACT_APP_SITE_TITLE === "InvexERP"
        ? Number(formik.values.coreUsersAmount) *
        Number(formik.values.coreUsers) +
        Number(formik.values.passiveUsersAmount) *
        Number(formik.values.passiveUsers)
        : formik.values.billing;

    if (!Boolean(checkAmount)) {
      toast.error("invalid number of users entered");

      return;
    }
    const reference = new Date().getTime().toString();

    setIsLoading(true);
    const res = await fetch(
      `${backendUrl}/api/company/save-company-billing-metadata`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          metadata: {
            email: user?.email,
            type: "billing",
            reference,
            amount:
              process.env.REACT_APP_SITE_TITLE === "InvexERP"
                ? Number(formik.values.coreUsersAmount) *
                Number(formik.values.coreUsers) +
                Number(formik.values.passiveUsersAmount) *
                Number(formik.values.passiveUsers)
                : formik.values.billing,
            totalUsers: formik.values.addUsers,
            addedUsers: 0,
            removedUsers: 0,
            userActionType: "expiredRenew",
            currentUsers: formik.values.currentUsers,
            planDurationType: formik.values.planDurationType,
            invexVersion: process.env.REACT_APP_SITE_TITLE,
            pricePerUser: formik.values.pricePerUser,
            coreUsers:
              process.env.REACT_APP_SITE_TITLE === "InvexERP"
                ? Number(formik.values.coreUsers)
                : 0,
            passiveUsers:
              process.env.REACT_APP_SITE_TITLE === "InvexERP"
                ? Number(formik.values.passiveUsers)
                : 0,
            userType: userType,
            backendApis: backendApis,
            staffName: user?.Name,

            coreUsersAmount: formik.values.coreUsersAmount,
            passiveUsersAmount: formik.values.passiveUsersAmount,
          },
        }),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      }
    );

    setIsLoading(false);

    if (!res.ok) {
      toast.error("try again!");

      return;
    }

    await payForRemainingUsers({
      email: user?.email,
      amount:
        process.env.REACT_APP_SITE_TITLE === "InvexERP"
          ? Number(formik.values.coreUsersAmount) *
          Number(formik.values.coreUsers) +
          Number(formik.values.passiveUsersAmount) *
          Number(formik.values.passiveUsers)
          : formik.values.billing,
      reference,

      companyUrl: companyUrl,
    });
  };

  const handlePayBillForRenewingAndAddingUser = async (userType) => {
    let checkAmount =
      process.env.REACT_APP_SITE_TITLE === "InvexERP"
        ? Number(formik.values.coreUsersAmount) *
        Number(formik.values.coreUsers) +
        Number(formik.values.passiveUsersAmount) *
        Number(formik.values.passiveUsers)
        : formik.values.billing;

    if (!Boolean(checkAmount)) {
      toast.error("invalid number of users entered");

      return;
    }
    const reference = new Date().getTime().toString();

    setIsLoading(true);
    const res = await fetch(
      `${backendUrl}/api/company/save-company-billing-metadata`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          metadata: {
            email: user?.email,
            type: "billing",
            amount:
              process.env.REACT_APP_SITE_TITLE === "InvexERP"
                ? Number(formik.values.coreUsersAmount) *
                Number(formik.values.coreUsers) +
                Number(formik.values.passiveUsersAmount) *
                Number(formik.values.passiveUsers)
                : formik.values.billing,
            reference,
            totalUsers: formik.values.addUsers,
            addedUsers: formik.values.addUsers - formik.values.currentUsers,
            removedUsers: 0,
            userActionType: "expiredAddedUsers",
            currentUsers: formik.values.currentUsers,
            planDurationType: formik.values.planDurationType,
            pricePerUser: formik.values.pricePerUser,
            coreUsers:
              process.env.REACT_APP_SITE_TITLE === "InvexERP"
                ? Number(formik.values.coreUsers)
                : "",
            passiveUsers:
              process.env.REACT_APP_SITE_TITLE === "InvexERP"
                ? Number(formik.values.passiveUsers)
                : "",
            invexVersion: process.env.REACT_APP_SITE_TITLE,
            userType: userType,
            backendApis: backendApis,
            staffName: user?.Name,

            coreUsersAmount: formik.values.coreUsersAmount,
            passiveUsersAmount: formik.values.passiveUsersAmount,
            type: "billing",
          },
        }),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      }
    );

    setIsLoading(false);
    if (!res.ok) {
      toast.error("try again!");

      return;
    }

    await pay({
      email: user?.email,
      amount:
        process.env.REACT_APP_SITE_TITLE === "InvexERP"
          ? Number(formik.values.coreUsersAmount) *
          Number(formik.values.coreUsers) +
          Number(formik.values.passiveUsersAmount) *
          Number(formik.values.passiveUsers)
          : formik.values.billing,
      reference,

      companyUrl: companyUrl,
    });
  };

  const fetchcompanyDetails = async (url) => {
    const res = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    });

    const data = await res.json();

    setComapanyStaff(data?.staff);

    let companyData = data?.company;

    companyData = {
      ...companyData,
      planDurationType: data?.company?.planDurationType || "yearly",
    };

    return { data: companyData, pricing: data?.pricing };
  };

  const { data } = useQuery(
    ["GET_COMPANY_DETAILS"],
    () => fetchcompanyDetails(`${backendUrl}/api/company/details`),
    {
      onSuccess: ({ data, pricing }) => {
        formik.setFieldValue("email", data?.Email);
        formik.setFieldValue("currentPlan", "Business plan");
        formik.setFieldValue("currentUsers", Number(data?.Num_Users));
        formik.setFieldValue("addUsers", Number(data?.Num_Users));

        formik.setFieldValue(
          "planDurationType",
          data?.planDurationType || "yearly"
        );

        formik.setFieldValue(
          "billing",
          process.env?.REACT_APP_SITE_TITLE === "InvexERP" &&
            data?.planDurationType === "yearly"
            ? Number(data?.Num_Users) * Number(pricing?.coreUserPerYear)
            : process.env?.REACT_APP_SITE_TITLE !== "InvexERP" &&
              data?.planDurationType === "yearly"
              ? Number(data?.Num_Users) * Number(pricing?.invexOnePricePerYear)
              : process.env?.REACT_APP_SITE_TITLE === "InvexERP" &&
                data?.planDurationType === "monthly"
                ? Number(data?.Num_Users) * Number(pricing?.coreUserPeryMonth)
                : process.env?.REACT_APP_SITE_TITLE !== "InvexERP" &&
                  data?.planDurationType === "monthly"
                  ? Number(data?.Num_Users) * Number(pricing?.invexOnePricePerMonth)
                  : 0
        );

        if (!Boolean(data?.planDurationType)) {
          formik.setFieldValue(
            "billing",
            process.env.REACT_APP_SITE_TITLE === "InvexERP"
              ? Number(pricing?.coreUserPerYear) * Number(data?.Num_Users)
              : Number(pricing?.invexOnePricePerYear) * Number(data?.Num_Users)
          );
        }

        formik.setFieldValue(
          "coreUsersAmount",
          process.env.REACT_APP_SITE_TITLE === "InvexERP" &&
            data?.planDurationType === "yearly"
            ? Number(pricing?.coreUserPerYear)
            : process.env.REACT_APP_SITE_TITLE === "InvexERP" &&
              data?.planDurationType === "monthly"
              ? Number(pricing?.coreUserPeryMonth)
              : 0
        );

        formik.setFieldValue(
          "passiveUsersAmount",
          data?.planDurationType === "yearly"
            ? Number(pricing?.passiveUserPerYear)
            : process.env.REACT_APP_SITE_TITLE === "InvexERP" &&
              data?.planDurationType === "monthly"
              ? Number(pricing?.passiveUserPeryMonth)
              : 0
        );

        const companyHqUrl = backendApis
          ?.map((url) => {
            if (url?.isHq) {
              return url.url;
            }
          })

          .join("");

        setCompanyUrl(companyHqUrl);

        setCurrentUser(Number(data?.Num_Users));

        formik.setFieldValue(
          "coreUsers",
          Number(data?.coreUsers || Number(data?.Num_Users))
        );
        formik.setFieldValue("passiveUsers", Number(data?.passiveUsers || 0));
        formik.setFieldValue(
          "pricePerUser",
          process.env.REACT_APP_SITE_TITLE === "InvexOne" &&
            data?.planDurationType === "yealy"
            ? Number(pricing?.invexOnePricePerYear)
            : process.env.REACT_APP_SITE_TITLE === "InvexOne" &&
              data?.planDurationType === "monthly"
              ? Number(pricing?.invexOnePricePerMonth)
              : process.env.REACT_APP_SITE_TITLE === "InvexERP" &&
                data?.planDurationType === "yearly"
                ? Number(pricing?.coreUserPerYear)
                : process.env.REACT_APP_SITE_TITLE === "InvexERP" &&
                  data?.planDurationType === "monthly"
                  ? Number(pricing?.passiveUserPeryMonth)
                  : Number(pricing?.passiveUserPerYear)
        );
        setActiveBtn(data?.planDurationType || "yearly");
      },

      enabled: true,
    }
  );

  const fetchUsers = async (endpoint) => {
    try {
      const res = await fetch(endpoint);

      const data = await res.json();

      return { ...data, url: endpoint };
    } catch (error) {
      throw new Error(`Failed to fetch ${endpoint}`);
    }
  };

  const queries = useQueries(
    apis
      ?.map((company) =>
        company.map((api) => ({
          queryKey: ["user", api.name],
          queryFn: () => fetchUsers(`${api.url}/api/users`),
        }))
      )
      .flat()
  );

  const userData = useMemo(() => {
    if (queries && queries.length > 0 && data) {
      const companies = queries?.map((query) => query.data);

      const staff = companies;

      const companyStaff = staff
        ?.map((user) => {
          return user?.staff.map((staf) => {
            return { ...staf, url: user?.url };
          });
        })
        .flat();

      const uniqStaff = uniqBy(companyStaff, "username");

      function findUserBranches(username) {
        return companyStaff?.filter((url) => url?.username === username);
      }

      let userBranches = uniqStaff?.map((branch) => {
        const found = findUserBranches(branch?.username);

        const urls = found.map((url) => url?.url);
        return { ...branch, url: urls };
      });

      userBranches = userBranches?.filter(
        (staff) => staff?.username?.toLowerCase() !== "support"
      );

      return userBranches;
    } else {
      return [];
    }
  }, [queries, data]);
  const handleYearlyBtn = () => {
    formik.setFieldValue("planDurationType", "yearly");

    formik.setFieldValue(
      "billing",
      process.env?.REACT_APP_SITE_TITLE === "InvexERP"
        ? Number(data?.pricing?.coreUserPerYear) *
        Number(formik.values.addUsers)
        : Number(data?.pricing?.invexOnePricePerYear) *
        Number(formik.values.addUsers)
    );

    formik.setFieldValue(
      "pricePerUser",
      process.env?.REACT_APP_SITE_TITLE === "InvexERP"
        ? Number(data?.pricing?.coreUserPerYear)
        : Number(data?.pricing?.invexOnePricePerYear)
    );

    formik.setFieldValue(
      "coreUsersAmount",
      process.env?.REACT_APP_SITE_TITLE === "InvexERP"
        ? Number(data?.pricing?.coreUserPerYear)
        : 0
    );
    formik.setFieldValue(
      "passiveUsersAmount",
      process.env?.REACT_APP_SITE_TITLE === "InvexERP"
        ? Number(data?.pricing?.passiveUserPerYear)
        : 0
    );

    setActiveBtn("yearly");
  };

  const handleMonthlyBtn = () => {
    formik.setFieldValue("planDurationType", "monthly");

    formik.setFieldValue(
      "billing",
      process.env?.REACT_APP_SITE_TITLE === "InvexERP"
        ? Number(data?.pricing?.coreUserPeryMonth) *
        Number(formik.values.addUsers)
        : Number(data?.pricing?.invexOnePricePerMonth) *
        Number(formik.values.addUsers)
    );

    formik.setFieldValue(
      "pricePerUser",
      process.env?.REACT_APP_SITE_TITLE === "InvexERP"
        ? Number(data?.pricing?.coreUserPeryMonth)
        : Number(data?.pricing?.invexOnePricePerMonth)
    );

    formik.setFieldValue(
      "coreUsersAmount",
      process.env?.REACT_APP_SITE_TITLE === "InvexERP"
        ? Number(data?.pricing?.coreUserPeryMonth)
        : 0
    );
    formik.setFieldValue(
      "passiveUsersAmount",
      process.env?.REACT_APP_SITE_TITLE === "InvexERP"
        ? Number(data?.pricing?.passiveUserPeryMonth)
        : 0
    );

    setActiveBtn("monthly");
  };

  return (
    <>
      <RemoveUserModal
        handleShow={handleShow}
        show={removeUserModal}
        setShow={setRemoveUserModal}
        handleHide={handleHideRemoveUserModal}
        userStatus={userStatus}
        handleSubmit={handleSubmit}
        totalUsers={totalUsers}
        companyStaff={userData}
        currentUsers={currentUser}
        coreUsers={formik.values.coreUsers}
        passiveUsers={formik.values.passiveUsers}
        isLoading={isLoading}
      />

      <AssignUserTypeModal
        handleShow={setShowUserTypeModal}
        show={showUserTypeModal}
        setShow={setShowUserTypeModal}
        handleHide={setShowUserTypeModal}
        userStatus={userStatus}
        handleSubmit={handlePayBillForRenewingUser}
        totalUsers={totalUsers}
        companyStaff={userData}
        currentUsers={currentUser}
        coreUsers={formik.values.coreUsers}
        passiveUsers={formik.values.passiveUsers}
        isLoading={isLoading}
      />

      <AssignUserTypeModal
        handleShow={setShowAddUserTypeModal}
        show={showAddUserTypeModal}
        setShow={setShowAddUserTypeModal}
        handleHide={setShowAddUserTypeModal}
        userStatus={userStatus}
        handleSubmit={handlePayBillForRenewingAndAddingUser}
        totalUsers={totalUsers}
        companyStaff={userData}
        currentUsers={currentUser}
        coreUsers={formik.values.coreUsers}
        passiveUsers={formik.values.passiveUsers}
        isLoading={isLoading}
      />

      {/* <SkipModal handleShow={handleShow} show={show} /> */}
      <main className="inner-page-outlet">
        <PageHeader
          name="Payment & Billing"
          description="Renew your business plan"
          icon={<PaymentAndBillingIcon />}
        />

        <Form noValidate onSubmit={formik.handleSubmit}>
          <main className=" container mx-auto" style={{ maxWidth: "600px" }}>
            <div>
              <div className=" w-100 p-5 px-3">
                <Card>
                  <Card.Body className="p-4 ">
                    <h6 className="card-header-title  my-3">Billing</h6>
                    <div className=" mb-3">
                      <b>
                        Current Plan:
                        <span className=" fw-normal mx-1"> Business plan</span>
                      </b>
                    </div>

                    <p className=" fw-bold border-bottom my-3 pb-2">
                      Billing Details:
                    </p>

                    <div className="col-md-6 d-flex mb-4 ">
                      <Button
                        style={{
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          border: 0,
                          backgroundColor:
                            activeBtn === "monthly" ? "#5a9ace" : "#D3D3D3",
                          color: activeBtn === "monthly" ? "white" : "#a19f9f",
                        }}
                        onClick={handleMonthlyBtn}
                        className={`w-100  py-3  fw-bolder`}
                      >
                        Monthly
                      </Button>
                      <Button
                        style={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          border: 0,
                          backgroundColor:
                            activeBtn === "yearly" ? "#5a9ace" : "#D3D3D3",
                          color: activeBtn === "yearly" ? "white" : "#a19f9f",
                        }}
                        className={`w-100  py-3 fw-bolder`}
                        onClick={handleYearlyBtn}
                      >
                        Yearly
                      </Button>
                    </div>

                    {process.env?.REACT_APP_SITE_TITLE === "InvexOne" &&
                      formik.values.planDurationType === "yearly" && (
                        <div className=" d-flex align-items-center gap-2">
                          <Form.Group className="mb-3">
                            <Form.Check
                              type="radio"
                              label={`${currency(
                                process.env?.REACT_APP_SITE_TITLE === "InvexERP"
                                  ? Number(data?.pricing?.coreUserPerYear)
                                  : Number(data?.pricing?.invexOnePricePerYear)
                              ).format({
                                symbol: "",
                                precision: 0,
                              })} / user / year ${formik.values.planDurationType === "yearly"
                                ? "( your current plan is billed at yearly)"
                                : ""
                                }`}
                              name="planDurationType"
                              value={"yearly"}
                              checked={
                                formik.values.planDurationType === "yearly"
                              }
                              onChange={() => {
                                formik.setFieldValue(
                                  "planDurationType",
                                  "yearly"
                                );

                                formik.setFieldValue(
                                  "billing",
                                  process.env?.REACT_APP_SITE_TITLE ===
                                    "InvexERP"
                                    ? Number(data?.pricing?.coreUserPerYear) *
                                    Number(formik.values.addUsers)
                                    : Number(
                                      data?.pricing?.invexOnePricePerYear
                                    ) * Number(formik.values.addUsers)
                                );

                                formik.setFieldValue(
                                  "pricePerUser",
                                  process.env?.REACT_APP_SITE_TITLE ===
                                    "InvexERP"
                                    ? Number(data?.pricing?.coreUserPerYear)
                                    : Number(
                                      data?.pricing?.invexOnePricePerYear
                                    )
                                );
                              }}
                            />
                          </Form.Group>
                        </div>
                      )}

                    {process.env?.REACT_APP_SITE_TITLE === "InvexOne" &&
                      formik.values.planDurationType === "monthly" && (
                        <div className=" d-flex align-items-center gap-2">
                          <Form.Group className="mb-3">
                            <Form.Check
                              type="radio"
                              label={`${currency(
                                process.env?.REACT_APP_SITE_TITLE === "InvexERP"
                                  ? Number(data?.pricing?.coreUserPeryMonth)
                                  : Number(data?.pricing?.invexOnePricePerMonth)
                              ).format({
                                symbol: "",
                                precision: 0,
                              })} / user / Month ${formik.values.planDurationType === "monthly"
                                ? "( your current plan is billed at monthly)"
                                : ""
                                }`}
                              name="planDurationType"
                              checked={
                                formik.values.planDurationType === "monthly"
                              }
                              onChange={() => {
                                formik.setFieldValue(
                                  "planDurationType",
                                  "monthly"
                                );

                                formik.setFieldValue(
                                  "billing",
                                  process.env?.REACT_APP_SITE_TITLE ===
                                    "InvexERP"
                                    ? Number(data?.pricing?.coreUserPeryMonth) *
                                    Number(formik.values.addUsers)
                                    : Number(
                                      data?.pricing?.invexOnePricePerMonth
                                    ) * Number(formik.values.addUsers)
                                );

                                formik.setFieldValue(
                                  "pricePerUser",
                                  process.env?.REACT_APP_SITE_TITLE ===
                                    "InvexERP"
                                    ? Number(
                                      data?.pricing?.passiveUserPeryMonth
                                    )
                                    : Number(
                                      data?.pricing?.invexOnePricePerMonth
                                    )
                                );
                              }}
                            />
                          </Form.Group>
                        </div>
                      )}

                    {process.env?.REACT_APP_SITE_TITLE === "InvexERP" &&
                      formik.values.planDurationType === "yearly" && (
                        <div className=" d-flex align-items-center gap-2">
                          <Form.Group className="mb-3">
                            <Form.Check
                              type="radio"
                              label={
                                "Your current plan is billed at yearly / user "
                              }
                              name="planDurationType"
                              value={"yearly"}
                              checked={
                                formik.values.planDurationType === "yearly"
                              }
                            />
                          </Form.Group>
                        </div>
                      )}
                    {process.env?.REACT_APP_SITE_TITLE === "InvexERP" &&
                      formik.values.planDurationType === "monthly" && (
                        <div className=" d-flex align-items-center gap-2">
                          <Form.Group className="mb-3">
                            <Form.Check
                              type="radio"
                              label={
                                "Your current plan is billed at monthly / user "
                              }
                              name="planDurationType"
                              value={"monthly"}
                              checked={
                                formik.values.planDurationType === "monthly"
                              }
                            />
                          </Form.Group>
                        </div>
                      )}

                    <div className="mb-3   col-md-6">
                      <div className="mb-2">Users:</div>
                      <NumberCustomInput
                        initialValues={1}
                        placeholder="0"
                        name="addUsers"
                        value={formik.values.addUsers}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value);

                          formik.setFieldValue(
                            "billing",
                            Boolean(value) &&
                              process.env?.REACT_APP_SITE_TITLE ===
                              "InvexERP" &&
                              formik.values.planDurationType === "yearly"
                              ? value * Number(data?.pricing?.coreUserPerYear)
                              : process.env?.REACT_APP_SITE_TITLE !==
                                "InvexERP" &&
                                formik.values.planDurationType === "yearly"
                                ? value *
                                Number(data?.pricing?.invexOnePricePerYear)
                                : process.env?.REACT_APP_SITE_TITLE ===
                                  "InvexERP" &&
                                  formik.values.planDurationType === "monthly"
                                  ? value * Number(data?.pricing?.coreUserPeryMonth)
                                  : process.env?.REACT_APP_SITE_TITLE !==
                                    "InvexERP" &&
                                    formik.values.planDurationType === "monthly"
                                    ? value *
                                    Number(data?.pricing?.invexOnePricePerMonth)
                                    : 0
                          );

                          formik.setFieldValue("coreUsers", value);
                          formik.setFieldValue("passiveUsers", 0);
                        }}
                        onBlur={() => formik.setFieldTouched("addUsers", true)}
                      />
                    </div>

                    {process.env.REACT_APP_SITE_TITLE === "InvexERP" && (
                      <div className="row my-4">
                        <div className="col-md-6 mb-2 mb-sm-4">
                          <div className=" mb-2">Core users</div>
                          <NumberCustomInputForPayment
                            placeholder="0"
                            name="coreUsers"
                            expectedTotalValue={Number(formik.values.addUsers)}
                            comparedValue={
                              Number(formik.values.coreUsers) +
                              Number(formik.values.passiveUsers)
                            }
                            value={formik.values.coreUsers}
                            onValueChange={(value, name) => {
                              if (value > formik.values.addUsers) {
                                formik.setErrors({
                                  error: "user limit exceeded",
                                });
                                return;
                              }

                              formik.setFieldValue(name, value);
                              formik.setFieldValue(
                                "passiveUsers",
                                formik.values.addUsers - value
                              );
                              formik.setFieldValue(
                                "coreUsersTotalAMount",
                                value * formik.values.coreUsersAmount
                              );
                            }}
                            onBlur={() =>
                              formik.setFieldTouched("addUsers", true)
                            }
                          />
                        </div>
                        <div className="col-md-6 mb-2 ">
                          <div className=" mb-2">Passive users</div>
                          <NumberCustomInputForPayment
                            placeholder="0"
                            name="passiveUsers"
                            expectedTotalValue={Number(formik.values.addUsers)}
                            comparedValue={
                              Number(formik.values.coreUsers) +
                              Number(formik.values.passiveUsers)
                            }
                            value={formik.values.passiveUsers}
                            onValueChange={(value, name) => {
                              if (value > formik.values.addUsers) {
                                formik.setErrors({
                                  error: "user limit exceeded",
                                });
                                return;
                              }

                              formik.setFieldValue(name, value);
                              formik.setFieldValue(
                                "coreUsers",
                                formik.values.addUsers - value
                              );
                            }}
                            onBlur={() =>
                              formik.setFieldTouched("addUsers", true)
                            }
                          />
                        </div>
                        <div className=" text-danger">
                          {formik.touched &&
                            formik.errors &&
                            formik?.errors?.error}
                        </div>
                      </div>
                    )}

                    {process.env.REACT_APP_SITE_TITLE === "InvexERP" && (
                      <div>
                        <Table responsive className="">
                          <tbody>
                            <tr className=" ">
                              <th>Plan</th>
                              <th>No. of users</th>
                              <th>Price</th>
                              <th>Sub total</th>
                            </tr>
                            <tr className=" ">
                              <td> Core Users</td>
                              <td>
                                {Boolean(formik.values.coreUsers)
                                  ? formik.values.coreUsers
                                  : 0}
                              </td>
                              <td>
                                {currency(formik.values.coreUsersAmount).format(
                                  {
                                    symbol: "",
                                  }
                                )}
                                / user
                              </td>
                              <td>
                                {currency(
                                  Boolean(formik.values.coreUsers)
                                    ? formik.values.coreUsers *
                                    formik.values.coreUsersAmount
                                    : 0
                                ).format({
                                  symbol: "",
                                })}
                              </td>
                            </tr>
                            <tr className=" ">
                              <td>Passive Users</td>
                              <td>
                                {Boolean(formik.values.passiveUsers)
                                  ? formik.values.passiveUsers
                                  : 0}
                              </td>
                              <td>
                                {currency(
                                  formik.values.passiveUsersAmount
                                ).format({
                                  symbol: "",
                                })}
                                / user
                              </td>
                              <td>
                                {" "}
                                {currency(
                                  Boolean(formik.values.passiveUsers)
                                    ? formik.values.passiveUsers *
                                    formik.values.passiveUsersAmount
                                    : 0
                                ).format({
                                  symbol: "",
                                })}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    )}

                    <div className=" mb-3">
                      Total Users:
                      <span span className=" mx-2">
                        {Boolean(formik.values.addUsers)
                          ? formik.values.addUsers
                          : 0}{" "}
                      </span>
                    </div>
                    <div className="mb-2">
                      Total Amount:{" "}
                      <span className=" mx-2">
                        {" "}
                        {process.env.REACT_APP_SITE_TITLE === "InvexOne" &&
                          currency(formik.values.billing).format({
                            symbol: "",
                          })}
                        {process.env.REACT_APP_SITE_TITLE === "InvexERP" &&
                          currency(
                            Boolean(formik.values.passiveUsers) ||
                              Boolean(formik.values.coreUsers)
                              ? formik.values.coreUsersAmount *
                              formik.values.coreUsers +
                              formik.values.passiveUsersAmount *
                              formik.values.passiveUsers
                              : 0
                          ).format({
                            symbol: "",
                          })}
                      </span>
                    </div>

                    <div className=" row">
                      <div className=" col-md-8 mb-3">
                        <Button
                          variant="primary"
                          className="  py-3 mt-2 col-md-5"
                          type="submit"
                        >
                          Make Payment
                        </Button>
                        <Button
                          variant=""
                          onClick={() => navigate("/payment-and-billing")}
                          className="btn-lg mt-2 col-md-5 mx-2 py-3    btn-outline-primary"
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className=" col-md-12">
                        <div style={{ maxWidth: "8rem" }}>
                          <img src="/card.png" className=" img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </main>
        </Form>
      </main>
    </>
  );
}
