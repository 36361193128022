import Button from "react-bootstrap/Button";
import { Modal, Form } from "react-bootstrap";
import { useState, useRef, useMemo, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import Select from "react-select";
import { Table } from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import currency from "currency.js";
import CreatableSelect from "react-select/creatable";

import { SearchIcon, NoSelectedItemIcon } from "../Icons";
import { CustomInput } from "../utils/CustomInput";
import NumberCustomInput from "../utils/NumberCustomInput";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
// import ItemsTable from "../utils/ItemsTable";
import { useAuth } from "../../hooks/useAuth";
// import { backendApis } from "../../config";
// import IssueItenModalTable from "../IssueItemModalTable";
import "../../assets/scss/scoped/issueItem.scss";
import ConfirmDialog from "../ConfirmDialogue";
import { fetchActionsUtil } from "../../utils/helpers";
import WarehouseItemTable from "../warehouse/WarehouseItemTable";
import WarehouseIssueItemModalTable from "../warehouse/WareIssueItemModalTable";
import moment from "moment";
import ModalLoader from "../utils/ModalLoader";
import { format } from "date-fns";
import { ItemSelectTable } from "./ItemSelectTable";
import PrintIssueItemModal from "./PrintIssueItemReceipt";
import CachedIcon from "mdi-react/CachedIcon";

export const WarehouseIssueItemModal = (props) => {
  const [carts, setCart] = useState([]);
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [allcarts, setAllCart] = useState({});
  const [cons, setCons] = useState("");
  const [allTotal, setAllTotal] = useState(0);

  const [tableData, setTableData] = useState([]);

  const [showPrintModal, setShowPrintModal] = useState(false);
  const [transid, setTrans_ID] = useState("");

  const warehouseActive = props?.warehouseActive;

  const { backendUrl, token, user } = useAuth();

  const cashInput = useRef(null);

  const wareshouseLocal = JSON.parse(
    window.localStorage.getItem("warehouseName")
  );

  const formik = useFormik({
    initialValues: {
      issueTo: "",
      itemId: "",
      itemName: "",
      qtyinStock: "",
      issueDate: "",
      qty: "",
      costPrice: "",
      receivingofficer: "",
      contracts: "",
      Comment: "",
    },
    validationSchema: yup.object().shape({
      issueTo: yup.string().required("Issue To is required"),
      issueDate: yup.string().required("Issue Date is required"),
      receivingofficer: yup.string().required("Receiving Officer is required"),
      qty: yup.string().required("Quantity is required"),
      itemId: yup.string().required("Item Id is required"),
      // costPrice: yup.string().required("costPrice is required"),
    }),
    onSubmit: async (values) => {
      if (parseFloat(values.qty) > parseFloat(values.qtyinStock)) {
        return toast.error(`Quantity in stock can not be bigger than Quantity`);
      }

      const data = {
        subtotal: parseFloat(values.qty) * parseFloat(values.costPrice),
        ...values,
        ...allcarts,
      };

      //---------- Attach selected items -----------------------------
      data.selectedItems = tableData;
      // -----------------------------------------------------------------
      data.Vendor = user?.company;
      data.WH_Category = wareshouseLocal?.cater;

      const _carts = [...carts];

      const item = _carts.find((d) => d?.Bar_Code === values.itemId);
      const index = _carts.findIndex((d) => d?.Bar_Code === values.itemId);

      // check if the item is already in the cart then replace it else add it new
      if (carts.length > 0 && item?.Bar_Code === values.itemId) {
        const available = {
          ...item,
          qty: values.qty,
          subtotal: parseFloat(values.qty) * parseFloat(values.costPrice),
        };
        carts.splice(index, 1, available);
      } else {
        setCart((prev) => [...prev, { ...data }]);
      }

      formik.resetForm({
        values: {
          ...values,
          itemId: "",
          itemName: "",
          qtyinStock: "",
          issueDate: "",
          qty: "",
          costPrice: "",
          Comment: "",
        },
      });
      setTableData([]);
    },
  });

  const handleSelectedItem = (el) => {
    formik.setValues({
      ...formik.values,
      itemId: el.Bar_Code,
      itemName: el.Item_Name,
      qtyinStock: el.Quantity,
      // costPrice: el.UnitPrice ? el.UnitPrice : 0,
      costPrice: el.UnitCost ? el.UnitCost : 0,
      issueDate: moment(),
    });
    setAllCart(el);
    setShowItemsPopover(false);
    cashInput.current && cashInput.current.focus();
  };

  const updateQuantity = (num, index) => {
    carts
      .filter((p, i) => i === index)
      .map((e) => {
        e.qty = parseFloat(num);
        e.subtotal = parseFloat(e.qty) * parseFloat(e.costPrice);
        return "";
      });
  };

  const deleteItem = async (i) => {
    const reCarts = [...carts];
    const filtered = reCarts.filter((re, inx) => inx !== i);
    // let findSelected = reCarts.find((re, inx) => inx === i);
    // findSelected = findSelected?.selectedItems;
    if (
      await ConfirmDialog({
        title: "Delete Item",
        description: "Are you sure you want to delete this Item from your cart",
      })
    ) {
      setCart(filtered);
    }
  };

  // /storage/issue-items

  const sendStorageItems = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/warehouse/storage/issue-items`,
        "POST",
        token,
        payload
      ),
    {
      onSuccess: ({ message, Trans_ID }) => {
        setCart([]);
        setAllCart({});
        toast.success(message);
        setTrans_ID(Trans_ID);
        setShowPrintModal(true);
        props?.refetchMain && props?.refetchMain();
      },
      onError: ({ message }) => {
        toast.error(message ? message : `Unable to perform action`);
      },
    }
  );

  const sendIssueItemMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/warehouse/issue-items`,
        "POST",
        token,
        payload
      ),
    {
      onSuccess: ({ message, Trans_ID }) => {
        setCart([]);
        setAllCart({});
        toast.success(message);
        setTrans_ID(Trans_ID);
        setShowPrintModal(true);
        props?.refetchMain && props.refetchMain();
      },
      onError: ({ message }) => {
        toast.error(message ? message : `Unable to perform action`);
      },
    }
  );

  const sendPlantsIssuesMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/production/issue-items`,
        "POST",
        token,
        payload
      ),
    {
      onSuccess: ({ message, Trans_ID }) => {
        setCart([]);
        setAllCart({});
        toast.success(message);
        setTrans_ID(Trans_ID);
        setShowPrintModal(true);
        props?.refetchMain && props.refetchMain();
      },
      onError: ({ message }) => {
        toast.error(message ? message : `Unable to perform action`);
      },
    }
  );

  const sendCarts = async () => {
    const cart = carts.map((el) => ({ ...el, contracts: cons }));
    const conStorage = {
      allTotal,
      cons,
      type: wareshouseLocal?.cater,
      Warehouse: wareshouseLocal?.whID,
    };

    console.log({ cart });

    if (
      await ConfirmDialog({
        title: "Issue Item",
        description: `Are you sure you want to issue these Items`,
      })
    ) {
      if (warehouseActive?.Warehouse_Type !== "Storage") {
        const plantCarts = cart.filter(
          (d) => d.issueTo.split("-")[1] === "plant"
        );
        const warehouseCarts = cart.filter(
          (d) => d.issueTo.split("-")[1] !== "plant"
        );

        warehouseCarts.length > 0 &&
          sendIssueItemMutation.mutate({ warehouseCarts });

        plantCarts.length > 0 &&
          sendPlantsIssuesMutation.mutate({ plantCarts });
      } else {
        // console.log({ carts: cart, conStorage });
        sendStorageItems.mutate({ carts: cart, conStorage });
      }
    }
  };

  const issueToData = useMemo(() => {
    return props.warehouses
      ?.concat(props.plants)
      .concat({ label: "Sales", value: "Sales" });
  }, [props.warehouses, props.plants]);
  // console.log(props?.locations, props?.employees);

  useEffect(() => {
    const tol = carts.reduce((a, b) => a + b.subtotal, 0);
    setAllTotal(tol);
  }, [carts]);
  // console.log({ issueToData });

  return (
    <>
      <Modal
        onHide={props.onHide}
        show={props.show}
        backdropClassName={`global-backdrop`}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="my-style-modal"
        animation={false}
        enforceFocus={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div>
              <h1>Issue Item </h1>
            </div>

            <p>Issue item by filling in the following forms</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="container">
              <div className="row">
                <div className="col-4 border p-2 rounded">
                  <form
                    noValidate
                    onSubmit={formik.handleSubmit}
                    className="form-relative"
                  >
                    <div className="mb-3">
                      <label htmlFor="date" className="form-label">
                        Issue to{" "}
                        <span onClick={() => props?.refetchMain()}>
                          <CachedIcon />
                        </span>
                      </label>
                      {warehouseActive?.Warehouse_Type !== "Storage" ? (
                        <Select
                          classNamePrefix={"form-select"}
                          isSearchable={true}
                          isLoading={props?.isLoading}
                          value={props?.warehouses?.find(
                            (el) => el.value === formik.values.issueTo
                          )}
                          onChange={({ value }) => {
                            formik.setFieldValue("issueTo", value);
                          }}
                          options={issueToData}
                        />
                      ) : (
                        <CreatableSelect
                          isClearable
                          onChange={(selected) => {
                            formik.setFieldValue("issueTo", selected?.value);
                          }}
                          options={props?.locations}
                          value={props?.locations?.find(
                            (el) => el.value === formik.values.issueTo
                          )}
                        />
                      )}

                      {formik.touched.issueTo && !!formik.errors.issueTo ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.issueTo}
                        </span>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="date" className="form-label">
                        Receiving Officer{" "}
                        <span onClick={() => props?.refetchMain()}>
                          <CachedIcon />
                        </span>
                      </label>
                      {warehouseActive?.Warehouse_Type !== "Storage" ? (
                        <Select
                          classNamePrefix={"form-select"}
                          isSearchable={true}
                          isLoading={props?.isLoading}
                          value={props.staffs.find(
                            (el) => el.value === formik.values.receivingofficer
                          )}
                          onChange={({ value }) =>
                            formik.setFieldValue("receivingofficer", value)
                          }
                          options={props.staffs}
                        />
                      ) : (
                        <Select
                          classNamePrefix={"form-select"}
                          isSearchable={true}
                          value={props.staffs.find(
                            (el) => el.value === formik.values.receivingofficer
                          )}
                          onChange={({ value }) =>
                            formik.setFieldValue("receivingofficer", value)
                          }
                          options={props.staffs}
                        />
                      )}

                      {formik.touched.receivingofficer &&
                        !!formik.errors.receivingofficer ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.receivingofficer}
                        </span>
                      ) : null}
                    </div>

                    {warehouseActive?.Warehouse_Type === "Storage" && (
                      <div className="mb-3">
                        <label htmlFor="date" className="form-label">
                          Contract/Job
                        </label>
                        <Select
                          classNamePrefix={"form-select"}
                          isSearchable={true}
                          value={props.contracts.find(
                            (el) => el.value === formik.values.contracts
                          )}
                          onChange={({ value }) =>
                            // formik.setFieldValue("contracts", value)
                            setCons(value)
                          }
                          options={props.contracts}
                        />
                      </div>
                    )}

                    {/*  */}

                    {warehouseActive?.Warehouse_Type === "Storage" && (
                      <Form.Group className="">
                        <Form.Label className="mb-1">
                          Comment(Optional)
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Enter Comment"
                          name="Comment"
                          rows={5}
                          value={formik.values.Comment}
                          onChange={formik.handleChange}
                          isInvalid={
                            formik.touched.Comment && !!formik.errors.Comment
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.Comment}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                    {/*  */}

                    <div className="my-3 text-center">
                      <p className="fw-bold">Add Item</p>
                    </div>
                    <div class="mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        class="form-label"
                      >
                        Item Id
                      </label>
                      <div className="input-group mb-3">
                        <Popover
                          isOpen={showItemsPopover}
                          onClickOutside={() => setShowItemsPopover(false)}
                          content={() => (
                            <WarehouseItemTable
                              handleSelectedItem={handleSelectedItem}
                            />
                          )}
                          position="bottom"
                        >
                          <>
                            <CustomInput
                              className="p-cursor form-control"
                              name="itemId"
                              placeholder="Enter Item Id"
                              typeOfInput="text"
                              value={formik.values.itemId}
                              disabled
                              onChange={formik.handleChange}
                              onClick={() =>
                                setShowItemsPopover(!showItemsPopover)
                              }
                            />

                            <span
                              className="input-group-text border p-cursor"
                              id="basic-addon1"
                              onClick={() =>
                                setShowItemsPopover(!showItemsPopover)
                              }
                            >
                              <SearchIcon />
                            </span>
                          </>
                        </Popover>
                      </div>
                      {formik.touched.itemId && !!formik.errors.itemId ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.itemId}
                        </span>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <CustomInput
                        name="itemName"
                        placeholder="Enter Item Name"
                        typeOfInput="text"
                        label="Item Name"
                        disabled
                        value={formik.values.itemName}
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="qty" className="form-label">
                        Quantity to issue
                      </label>
                      <NumberCustomInput
                        placeholder="0"
                        name="qty"
                        value={formik.values.qty}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value, true);
                        }}
                        isInvalid={formik.touched.qty && !!formik.errors.qty}
                        ref={cashInput}
                      />
                      {formik.touched.qty && !!formik.errors.qty ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.qty}
                        </span>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <CustomInput
                        name="qtyinStock"
                        placeholder=""
                        typeOfInput="number"
                        label="QTY in Stock"
                        disabled
                        value={formik.values.qtyinStock}
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="date" className="form-label">
                        Issue Date
                      </label>
                      <Datetime
                        dateFormat="MMM DD, YYYY"
                        timeFormat={false}
                        closeOnSelect={true}
                        closeOnClickOutside={true}
                        name="issueDate"
                        inputProps={{
                          className: `date-input form-control ${formik.touched.issueDate &&
                              !!formik.errors.issueDate
                              ? "is-invalid"
                              : ""
                            }`,

                          placeholder: "Select date",
                          readOnly: true,
                        }}
                        value={formik.values.issueDate}
                        onChange={(date) => {
                          formik.setFieldValue("issueDate", date, true);
                        }}
                        onBlur={() => formik.setFieldTouched("issueDate", true)}
                      />
                      {formik.touched.issueDate && !!formik.errors.issueDate ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.issueDate}
                        </span>
                      ) : null}
                    </div>
                    {warehouseActive?.Warehouse_Type !== "Storage" && (
                      <div className="mb-3">
                        <label htmlFor="qty" className="form-label">
                          Cost Price
                        </label>
                        <CurrencyCustomInput
                          name="costPrice"
                          value={formik.values.costPrice}
                          onValueChange={(value, name) =>
                            formik.setFieldValue(name, value)
                          }
                          placeholder="0.00"
                          decimalsLimit={2}
                        />
                        {formik.touched.costPrice &&
                          !!formik.errors.costPrice ? (
                          <span className="custom-invalid-feedback">
                            {formik.errors.costPrice}
                          </span>
                        ) : null}
                      </div>
                    )}

                    {/* Non Stock Table */}

                    {wareshouseLocal?.cater === "non-stock" && (
                      <div className="my-4">
                        <hr />
                        <label htmlFor="date" className="form-label">
                          Select Items
                        </label>
                        <ItemSelectTable
                          tableData={tableData}
                          setTableData={setTableData}
                          isLoading={
                            sendIssueItemMutation.isLoading ||
                            sendPlantsIssuesMutation.isLoading ||
                            sendStorageItems.isLoading
                          }
                          useType={true}
                          ElementItemTable={WarehouseItemTable}
                        />
                        <hr />
                      </div>
                    )}

                    {/* Non Stock Table */}

                    <div className="add-to-cart-holder d-flex justify-content-end text-primary">
                      <Button
                        type="submit"
                        variant="outline-primary"
                        onClick={formik.handleSubmit}
                      >
                        Add to Cart
                      </Button>
                    </div>
                  </form>
                </div>

                <div className="col-8 pl-4">
                  <div className="px-md-4">
                    <Table
                      responsive
                      borderless
                      striped
                      className="product-table text-nowrap"
                    >
                      <thead>
                        <tr>
                          <th className="p-cursor">Model No.</th>
                          <th className="p-cursor">Item Name</th>
                          <th className="p-cursor">QTY</th>
                          <th className="p-cursor">Cost Price</th>
                          <th className="p-cursor">Sub Total</th>
                        </tr>
                      </thead>
                      {
                        <tbody>
                          {carts.map((el, index) => (
                            <WarehouseIssueItemModalTable
                              index={index}
                              el={el}
                              deleteItem={deleteItem}
                              updateQuantity={updateQuantity}
                              key={index}
                            />
                          ))}
                        </tbody>
                      }
                    </Table>
                    {carts.length < 1 && (
                      <div className="d-flex justify-content-center">
                        <div className="info text-center my-4">
                          <NoSelectedItemIcon />
                          <h5 className="my-2">No Item In Cart</h5>
                          <p>
                            Please add an item from the form beside, to fill in
                            the <br />
                            cart.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>

                  {carts.length > 0 && (
                    <div className="d-flex justify-content-between px-md-4">
                      {warehouseActive?.Warehouse_Type !== "Storage" && (
                        <div className="gridChild gridChildBorderLeftBlue">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(
                                carts.reduce((a, b) => a + b.subtotal, 0),
                                {
                                  symbol: "",
                                }
                              ).format()}
                            </p>
                            <p className="gridChld2">Total</p>
                          </div>
                        </div>
                      )}
                      <div className="mt-3">
                        <Button
                          disabled={
                            sendIssueItemMutation.isLoading ||
                            sendPlantsIssuesMutation.isLoading ||
                            sendStorageItems.isLoading
                          }
                          onClick={sendCarts}
                        >
                          {sendIssueItemMutation.isLoading ||
                            sendPlantsIssuesMutation.isLoading ||
                            sendStorageItems.isLoading
                            ? "Please wait..."
                            : "Issue Item"}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <ModalLoader
                  show={
                    sendIssueItemMutation.isLoading ||
                    sendPlantsIssuesMutation.isLoading ||
                    sendStorageItems.isLoading
                  }
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <PrintIssueItemModal
        showPrintModal={showPrintModal}
        setShowPrintModal={setShowPrintModal}
        Trans_ID={transid}
        warehouse={`${backendUrl}/api/warehouse/pdf/waybill-invoice`}
        waybill={`${backendUrl}/api/warehouse/pdf/waybill-invoice`}
      />
    </>
  );
};
