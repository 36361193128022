import { Modal, Form, Button } from "react-bootstrap";
import * as yup from "yup";
import { services } from "../config";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useState } from "react";
import Select from "react-select";
import { useEffect } from "react";
import { useBackendUrl } from "../utils/hooks";
import NumberCustomInput from "./utils/NumberCustomInput";
import { useStoreState } from "easy-peasy";
import { createConfirmation, confirmable } from "../utils/confirm";

function CreateItemSize({ proceed, show }) {
  const backendUrl = useBackendUrl();
  const generalSettings = useStoreState((state) => state?.generalSettings);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    Size: "",
    Quantity: "",
  };
  const schema = yup.object().shape({
    Size: yup.string().required(),
    Quantity: yup.string().required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        if (generalSettings.convertableUOM === "Tons") {
          values.Size = `${values.Size} mm`;
        }
        setIsLoading(true);
        let response = await fetch(
          `${backendUrl}/api/items/add-item-measurement`,
          {
            method: "POST",
            headers: {
              Accept: "Application/json",
              "Content-Type": "Application/json",
            },
            credentials: "include",
            body: JSON.stringify(values),
          }
        );

        if (!response.ok) {
          response = await response.json();
          toast.error(response.message);
        } else {
          const {
            data: { itemMeasurement },
          } = await response.json();
          proceed(itemMeasurement);
        }
      } catch (err) {
        console.log(err);
        toast.error("Unable to add size");
      } finally {
        setIsLoading(false);
      }
    },
  });

  const canClose = () => {
    proceed();
  };

  return (
    <Modal
      show={show}
      onHide={() => canClose()}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h5">Create Item Measurement</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>
              Size {generalSettings.convertableUOM === "Tons" ? "mm" : ""}
            </Form.Label>
            <Form.Control
              type={
                generalSettings.convertableUOM === "Tons" ? "number" : "text"
              }
              placeholder={`Enter Size ${generalSettings.convertableUOM === "Tons" ? "32 mm" : ""
                }`}
              name="Size"
              value={formik.values.Size}
              onChange={formik.handleChange}
              isInvalid={formik.touched.Size && !!formik.errors.Size}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              Quantity in a {generalSettings.convertableUOM}
            </Form.Label>
            <NumberCustomInput
              type="text"
              placeholder="Enter Quantity"
              name="Quantity"
              value={formik.values.Quantity}
              onValueChange={(value, name) => {
                formik.setFieldValue(name, value);
              }}
              isInvalid={formik.touched.Quantity && !!formik.errors.Quantity}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.Quantity}
            </Form.Control.Feedback>
          </Form.Group>

          <Button
            disabled={isLoading}
            variant="primary"
            className="w-100 p-2"
            type="submit"
          >
            {isLoading ? "Please wait…" : "Save"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default function ItemSizeDialog(props) {
  return createConfirmation(confirmable(CreateItemSize))({ ...props });
}
