import { Dropdown, Table, Form, Button, Badge, Modal } from "react-bootstrap";
import { CSVLink } from "react-csv";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  FilterCollapseIcon,
  ExchangeFunds,
  ExcelIcon,
  PDFIcon,
  DeleteIcon,
  EditIcon,
  RecieptIcon,
  DropdownCloseIcon,
  DropdownEyeIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState, useMemo } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useIsAdmin,
  useLocalStorage,
  useQueryParams,
  useReconcilation,
  useScrollTop,
} from "../../utils/hooks";
import { services } from "../../config";
import { reportActions } from "../../utils/reactQueryActions";
import { useMutation, useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  customerFullName,
  defaultSelectValue,
  formatDate,
  paginationOptions,
  reactSelectTheme,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import { useAuth } from "../../hooks/useAuth";
import { isEmpty, uniqBy } from "lodash";
import NoTableItem from "../utils/NoTableItem";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { toast } from "react-toastify";
import ConfirmDialog from "../ConfirmDialogue";
import ReconcilationCheckbox from "../utils/ReconcilationCheckbox";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik, FieldArray, FormikProvider } from "formik";
import CreatableSelect from "react-select/creatable";
import JobItemsModal from "./JobItems";

function Attachments({ files = [], setFiles, milestone }) {
  //  const [files, setFiles] = useState([]);
  const { backendUrl } = useAuth();
  return (
    <div>
      <div>
        {files?.map((el, index) => (
          <div key={index} className="d-flex align-items-center">
            {el?.fileId ? (
              <a
                href={`${backendUrl}/api/job/view-certificate/${el.systemFileName
                  }`}
                target="_blank"
                rel="noreferrer"
                className="text-primary"
              >
                {el?.name}
              </a>
            ) : (
              <span>{el?.name}</span>
            )}

            <Button
              variant=""
              size="sm"
              onClick={(e) => setFiles(files.filter((el, i) => i !== index))}
            >
              <DropdownCloseIcon width={16} />
            </Button>
            <hr className="m-0" />
          </div>
        ))}
      </div>

      <Form.Label className="btn btn-light-blue">
        <span>Add Files </span>
        <Form.Control
          type="file"
          className="d-none"
          multiple
          onChange={(e) => setFiles([...files, ...e.target.files])}
        />
      </Form.Label>
    </div>
  );
}

export function MileStoneDetails({
  milestone,
  systemMilestones = [],
  selectedJob,
  refetch,
}) {
  const { backendUrl } = useAuth();

  const formik = useFormik({
    initialValues: {
      milestone: milestone.milestone,
      files: milestone.files,
      jobId: selectedJob.id,
      milestoneNumber: milestone.milestoneNumber,
    },
    validationSchema: yup.object().shape({}),
    onSubmit: (values) => {
      //  console.log(values, "jdj");
      uploadCertificateMutation.mutate(values);
    },
  });

  const uploadCertificate = async (payload) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(payload)) {
      if (key === "files") {
        for (const file of value) {
          if (!file?.fileId) {
            formData.append("files", file);
          }
        }
        continue;
      }
      formData.append(key, value);
    }
    formData.append(
      "alreadyUploadedFiles",
      JSON.stringify(payload.files.filter((el) => el.fileId))
    );

    let response = await fetch(`${backendUrl}/api/job/update-milestone`, {
      method: "POST",
      credentials: "include",
      body: formData,
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const uploadCertificateMutation = useMutation(
    (payload) => uploadCertificate(payload),
    {
      onSuccess: (data) => {
        toast.success("Success");
        refetch(); /*
        setSelectedJob(); */
      },
      onError: (err) => {
        toast.error("Unable to Upload");
      },
    }
  );

  const deleteMileStone = async (payload) => {
    let response = await fetch(`${backendUrl}/api/job/delete-milestone`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteMutation = useMutation((payload) => deleteMileStone(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      // refetch();
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const handleDeleteMilestone = async (milestone) => {
    if (
      await ConfirmDialog({
        title: "Delete",
        description: "Are you sure you want to delete",
      })
    ) {
      deleteMutation.mutate({
        jobId: selectedJob.id,
        milestoneNumber: milestone.milestoneNumber,
      });
    }
  };

  return (
    <Form
      noValidate
      onSubmit={formik.handleSubmit}
      autoComplete="off"
      className="border p-2 mb-5"
    >
      <Form.Group className={"mb-3"}>
        <Form.Label>{milestone?.date && formatDate(milestone.date)}</Form.Label>
        {selectedJob.hasMilestone && (
          <CreatableSelect
            classNamePrefix="form-select"
            options={systemMilestones}
            value={systemMilestones.find(
              (el) => el.value === formik.values.milestone
            )}
            onChange={({ value }) => formik.setFieldValue(`milestone`, value)}
            isSearchable
          />
        )}
      </Form.Group>

      <Form.Group>
        <Attachments
          files={formik.values.files}
          setFiles={(files) => formik.setFieldValue(`files`, files)}
        />
      </Form.Group>

      <div className="mt-2">
        <Button
          size="sm"
          type="button"
          variant=""
          className="me-4"
          onClick={() => handleDeleteMilestone(milestone)}
        >
          <DeleteIcon />
        </Button>
        <Button size="sm" type="submit" variant="primary">
          Save Changes
        </Button>
      </div>
    </Form>
  );
}

export default function MileStoneModal({
  selectedJob,
  setSelectedJob,

  systemMilestones = [],
}) {
  const { backendUrl } = useAuth();
  const fetchJobDetails = async () => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/job/job-details/${selectedJob.jobNumber}?jobNumber=${selectedJob.jobNumber
      }`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.milestones = data?.milestones.map((el) => ({
      ...el,
      label: el.milestone,
      value: el.milestone,
    }));
    return data;
  };

  const {
    data = { job: {}, milestones: [] },
    refetch,
    isFetching,
    isSuccess,
    isFetched,
  } = useQuery(["_JOB_DETAILS"], () => fetchJobDetails(), {
    keepPreviousData: false,
  });

  const filesByMileStone = useMemo(() => {
    if (!data.job) return [];
    const files = data.job?.files ? JSON.parse(data.job?.files) : [];
    const milestones = data.job?.milestones
      ? JSON.parse(data.job?.milestones)
      : [];

    return milestones.map((el) => ({
      milestone: el.milestone,
      milestoneNumber: el.milestoneNumber,
      date: el.date,
      files: files.filter(
        (file) => file.milestoneNumber === el.milestoneNumber
      ),
    }));
  }, [data.job]);

  return (
    <>
      <Modal
        show={true}
        animation={false}
        centered
        enforceFocus={false}
        onHide={() => setSelectedJob()}
      >
        <Modal.Header closeButton>
          <h2 className="h5">Milestones</h2>{" "}
        </Modal.Header>

        <div>
          <Modal.Body>
            <div className="mb-4">
              <h3 className="h5">{selectedJob?.title}</h3>
              <p>{selectedJob?.description}</p>
            </div>
            {filesByMileStone &&
              filesByMileStone.map((el, index) => (
                <MileStoneDetails
                  key={index}
                  milestone={el}
                  systemMilestones={systemMilestones}
                  selectedJob={selectedJob}
                  refetch={() => refetch()}
                />
              ))}

            {isEmpty(filesByMileStone) && <NoTableItem />}
          </Modal.Body>
        </div>
      </Modal>
      {/*   <ModalLoader show={uploadCertificateMutation.isLoading} /> */}
    </>
  );
}
