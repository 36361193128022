import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { backendApis } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import eventBus from "../../utils/EventBus";
import {
  formDataForFormBuider,
  formatDate,
  formatNumberSystem,
} from "../../utils/helpers";
import { cacheTimes, queryActions } from "../../utils/reactQueryActions";
import ConfirmDialog from "../ConfirmDialogue";
import { Logo } from "../Icons";
import ModalLoader from "../utils/ModalLoader";
import { useStoreState } from "easy-peasy";
import { isEmpty } from "lodash";

export default function FillFormModal({
  openFilledForm,
  setOpenFilledForm,
  mainFormID,
  name,
  setthefilledForm,
  stageId,
  stageFormId,
}) {
  const { user } = useAuth();
  //  get company from  url,  because in  public  form  the user  is  not logged  in
  const { url: backendUrl } = backendApis.find(
    (el) => el?.name === user?.company
  );
  //   console.log(user);
  // console.log(mainFormID);
  const iframeRef = useRef();
  const currentLoggedInCompany = useStoreState(
    (state) => state.currentLoggedInCompany
  );
  const [isLoadingIframe, setIsLoadingIframe] = useState(true);
  const formik = useFormik({
    initialValues: {
      FormID: mainFormID,
      UserName: user?.Name || "",
      Staff_ID: user?.Staff_ID || "",
      filledFormData: "",
      formName: "",
      poNumber: "",
    },
    validationSchema: yup.object().shape({
      formName: yup.string().required(""),
    }),
    onSubmit: async (values) => {
      if (name === "Purchase Order" && isEmpty(values.poNumber)) {
        return toast.error("Please enter PO Number");
      }

      fillFormMutation.mutate({ ...values });
    },
  });

  const fillForm = async (payload) => {
    //  console.log(payload);
    payload.Template = payload.filledFormData;
    payload.stageId = stageId;
    payload.stageFormId = stageFormId;
    const formData = formDataForFormBuider(payload);

    let response = await fetch(`${backendUrl}/api/forms/fill-form`, {
      method: "POST",
      credentials: "include",
      body: formData,
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const fillFormMutation = useMutation((payload) => fillForm(payload), {
    onSuccess: ({ message, data }) => {
      toast.success(message);
      setthefilledForm(data);
      setOpenFilledForm(false);
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const setformData = async (data) => {
    formik.setFieldValue("filledFormData", data);

    if (
      await ConfirmDialog({
        title: "Submit",
        description: "Are you sure, you want to submit",
      })
    ) {
      formik.submitForm();
    }
  };

  const handleMessage = (message) => {
    if (message.data.type === "CLOSE") {
      //
    } else if (message.data.type === "SAVE") {
      setformData(message.data.value);
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    // cleanup this component
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  //----------------------------------------------------------------------
  // fetch data for form
  const getDepartments = async () => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/users/departments-with-users`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };

  const {
    data = { departments: [], staffs: [], jobGrades: [] },
    isFetching,
  } = useQuery(
    [queryActions.GET_USERS_IN_DEPARTMENTS],
    () => getDepartments(),
    {
      enabled: true,
      keepPreviousData: true,
      cacheTime: cacheTimes.GET_USERS_WITH_DEPARTMENTS,
    }
  );

  //------------------------
  //  GEt Form  for filling
  const getForm = async () => {
    let response = await fetch(
      `${backendUrl}/api/forms/get-form/${mainFormID}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    if (data?.form.Title) {
      formik.setFieldValue("formName", data?.form.Title);
    }

    if (!data?.form?.enabled) {
      setIsLoadingIframe(false);
    }
    return data;
  };
  const getFormQuery = useQuery(["GET_FORM"], () => getForm(), {
    enabled: true,
  });

  const setOrientation = (printOrientation) => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          type: "OREINTATION",
          data: {
            ...printOrientation,
          },
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  const sendSetupData = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          data: {
            ...data,
            template: JSON.parse(getFormQuery.data.form.Template),
            backendUrl,
            companyData: currentLoggedInCompany,
            formInfo: {
              formNumber: formatNumberSystem(
                getFormQuery.data.form.formNumberPrefix,
                getFormQuery.data.form.currentNumber
              ),
              documentNumber: getFormQuery.data.form?.documentNumber,
              revision: getFormQuery.data.form.revision,
              date: formatDate(getFormQuery.data.form.Date_Log, "MMM yyyy"),
            },
          },
          settings: { canFill: true, showTableCheckbox: false },
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  const save = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          type: "SUBMIT",
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  eventBus.useCustomEventListener(
    "SEND_FILE_TO_IFRAME",
    ({ files, elementId, elementType }) => {
      console.log(files, elementId, elementType);
      const iframe = iframeRef.current;
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage(
          {
            data: {
              files,
              elementId,
              elementType,
            },
            type: "FILES_FROM_PARENT",
          },
          process.env.REACT_APP_FORM_BUILDER_URL
        );
      }
    }
  );

  return (
    <div>
      <Modal
        show={openFilledForm}
        onHide={() => setOpenFilledForm(false)}
        centered={true}
        animation={false}
        enforceFocus={false}
        backdrop="static"
        size="xl"
        dialogClassName={`${getFormQuery?.data?.form.orientation === "Landscape"
            ? "modal-90w"
            : ""
          }`}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="h5">
              {" "}
              {stageFormId ? "" : "Create"} {name}
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100 d-flex justify-content-center">
            <Form.Group className="col-5">
              <div class="p-4 py-3  pt-0 text-center mx-auto">
                <label for="formname">
                  <b>Enter Form Name</b> <span className="text-danger">*</span>
                </label>
                <Form.Control
                  name="formName"
                  placeholder="Form Name"
                  required
                  value={formik.values.formName}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.formName && !!formik.errors.formName
                  }
                />{" "}
                <Form.Control.Feedback type="invalid">
                  {formik.errors.formName}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            {name === "Purchase Order" && (
              <Form.Group className="col-4">
                <div class="p-4 py-3  pt-0 text-center  mx-auto">
                  <label for="formname">
                    <b>PO Number</b> <span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    name="poNumber"
                    placeholder="PO Number"
                    required
                    value={formik.values.poNumber}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.poNumber && !!formik.errors.poNumber
                    }
                  />{" "}
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.poNumber}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            )}
          </div>
          {!isFetching && !getFormQuery.isFetching ? (
            getFormQuery?.data?.form?.enabled ? (
              <>
                <iframe
                  ref={iframeRef}
                  id="Frame"
                  onLoad={(e) => {
                    setIsLoadingIframe(false);
                    sendSetupData();
                    setOrientation({
                      orientation: getFormQuery.data.form.orientation,
                      pageSize: getFormQuery.data.form.pageSize,
                    });
                  }}
                  className="w-100"
                  style={{
                    backgroundColor: "#E5E7EB",
                    minHeight: "calc(100vh - 20rem)",
                  }}
                  title="Form Builder"
                  src={`${process.env.REACT_APP_FORM_BUILDER_URL}/form/fill`}
                />
                <Form noValidate onSubmit={formik.handleSubmit}>
                  <input type={"hidden"} value={formik.values.FormID} />
                </Form>
                <div className="action-area w-100 px-3 pt-3 text-center">
                  <Button
                    size="lg"
                    className="px-5"
                    type="button"
                    onClick={() => save()}
                    disabled={!getFormQuery?.data?.form?.enabled}
                  >
                    Submit
                  </Button>
                </div>
              </>
            ) : (
              <div className="vh-100 vw-100 d-flex align-items-center justify-content-center text-center">
                <div>
                  <Logo />
                  <h1 className="display-5">Form Disabled</h1>
                  <p className="">...</p>
                </div>
              </div>
            )
          ) : null}{" "}
        </Modal.Body>
      </Modal>
      <ModalLoader
        show={isLoadingIframe || fillFormMutation.isLoading || isFetching}
      />
    </div>
  );
}
