import { Modal, Form, Button, Table } from "react-bootstrap";
import * as yup from "yup";
import { services } from "../config";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useState } from "react";
import { confirmable, createConfirmation } from "../utils/confirm";
import Select from "react-select";
import { useEffect } from "react";
import { useBackendUrl } from "../utils/hooks";
import CachedIcon from "mdi-react/CachedIcon";
import ConfirmDialog from "./ConfirmDialogue";
import { useQuery } from "react-query";
import { FilesAttachments } from "../utils/Attachments";
import { paymentStatusOptions } from "../utils/helpers";
import currency from "currency.js";

function ChangeStoreOrderStatus({
  proceed,
  show,
  oldPaymentStatus,
  TransactionID,
  refetch,
  customerName,
}) {
  const backendUrl = useBackendUrl();
  const [isLoading, setIsLoading] = useState(false);
  const [proofOfPaymentAttachments, setProofOfPaymentAttachments] = useState(
    []
  );

  const initialValues = {
    paymentStatus: oldPaymentStatus,
    TransactionID,
    bank: "",
  };
  const schema = yup.object().shape({
    paymentStatus: yup.string().required(),
  });

  const changeStatus = async (payload) => {
    try {
      setIsLoading(true);
      let response = await fetch(
        `${backendUrl}/api/permits/change-payment-status-all`,
        {
          method: "POST",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        response = await response.json();
        toast.error(response.message);
      } else {
        response = await response.json();
        toast.success("Status Changed");
        refetch();
        proceed(response);
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to perform action");
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      changeStatus(values);
    },
  });

  const getPendingItems = async () => {
    let response = await fetch(
      `${backendUrl}/api/permits/get-by-transaction/${TransactionID}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    if (data.items) {
      const permit = data.items[0];
      if (permit.proofOfPaymentAttachments) {
        setProofOfPaymentAttachments(
          JSON.parse(permit.proofOfPaymentAttachments)
        );
      }
      formik.setFieldValue("bank", permit.bank);
    }
    return data;
  };

  const { data = { items: [] }, isFetching } = useQuery(
    ["PENDING_ITEMS_FOR_SALE"],
    () => getPendingItems(),
    {
      keepPreviousData: true,
    }
  );

  return (
    <Modal
      show={show}
      onHide={() => proceed(false)}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h5">Change Payment Status</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped>
          <tbody>
            <tr>
              <td>
                <span className="fw-6">Trans ID</span>
              </td>
              <td>{formik.values.TransactionID}</td>
            </tr>
            <tr>
              <td>
                <span className="fw-6">Business Name</span>
              </td>
              <td>{customerName}</td>
            </tr>
            <tr>
              <td>
                <span className="fw-6">Shipping Method</span>
              </td>
              <td>{data?.items[0]?.ShipMethod}</td>
            </tr>
            <tr>
              <td>
                <span className="fw-6">Payment Method</span>
              </td>
              <td>{data?.items[0]?.PayType}</td>
            </tr>
            <tr>
              <td>
                <span className="fw-6">Bank</span>
              </td>
              <td>{formik.values.bank}</td>
            </tr>
            {data?.items[0]?.AmountPaid && (
              <tr>
                <td>
                  <span className="fw-6">Amount Paid</span>
                </td>
                <td>
                  {currency(data?.items[0]?.AmountPaid, {
                    symbol: "",
                  }).format()}
                </td>
              </tr>
            )}
            <tr>
              <td>
                <span className="fw-6">Proof of Payment</span>
              </td>
              <td>
                <FilesAttachments
                  files={proofOfPaymentAttachments}
                  setFiles={(files) => { }}
                  url={`${backendUrl}/api/store/view-file`}
                  viewOnly={true}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Payment Status</Form.Label>
            <Select
              classNamePrefix="form-select"
              placeholder="Select"
              disabled={isLoading}
              isSearchable={false}
              options={paymentStatusOptions}
              value={paymentStatusOptions.find(
                (el) => el.value === formik.values.paymentStatus
              )}
              onChange={({ value }) =>
                formik.setFieldValue("paymentStatus", value)
              }
              onBlur={() => formik.setFieldTouched("paymentStatus", true)}
              className={
                formik.touched.paymentStatus && !!formik.errors.paymentStatus
                  ? "is-invalid"
                  : ""
              }
            />
            {formik.touched.paymentStatus && formik.errors.paymentStatus ? (
              <span className="custom-invalid-feedback">
                {formik.errors.paymentStatus}
              </span>
            ) : null}
          </Form.Group>

          <Button
            disabled={isLoading || isFetching}
            variant="primary"
            className="w-100 p-2"
            type="submit"
          >
            {isLoading ? "Please wait…" : "Save"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default function ChangeStoreOrderStatusDialog(props) {
  return createConfirmation(confirmable(ChangeStoreOrderStatus))({ ...props });
}
