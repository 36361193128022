import { Table, Dropdown, Form, Modal } from "react-bootstrap";
import { useQuery, useMutation, useQueries } from "react-query";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { toast } from "react-toastify";
import { useState, useEffect, useRef, useMemo } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
import { CSVLink } from "react-csv";
import { format } from "date-fns";

import { fetchActionsUtil } from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";
import ConfirmDialog from "../ConfirmDialogue";
import PasswordModal from "../modals/ChangePassword";
import EditUserAccountModal from "../modals/EditUserAccount";
import "../../assets/scss/reports/cashbook.scss";
import { BackIcon, ExchangeFunds, SearchIcon } from "../Icons";
import CreateUserAccount from "../modals/CreateUserAccount";
import EditUserPriviledgeModal from "../modals/EditUserPriviledgeModal";
import { useQueryParams } from "../../utils/hooks";
import useDebounce from "../../utils/hooks";
import { CustomInput } from "../utils/CustomInput";
import NoTableItem from "../utils/NoTableItem";
import { paginationOptions } from "../../utils/helpers";
import TableComponent from "../TableComponent";
import { ExportIcon, ExcelIcon } from "../Icons";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AssignGroupModal from "./AssignCustomerGroupModal";
import { backendApis } from "../../config";
import { isEmpty, uniqBy } from "lodash";
import Select from "react-select";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
const apis = [backendApis];

function OtherBranchUsers({ currentBranchUsers, refetch }) {
  const { token, backendUrl } = useAuth();

  const fetchcompanyDetails = async (url) => {
    const res = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    });

    const data = await res.json();

    let companyData = data?.company;

    return companyData;
  };

  const company = useQuery(
    ["GET_COMPANY_DETAILS"],
    () => fetchcompanyDetails(`${backendUrl}/api/company/details`),
    {}
  );

  // const fetchAllBranchStaff = async (endpoint) => {
  //   try {
  //     const res = await fetch(endpoint);

  //     const data = await res.json();

  //     return { ...data, url: endpoint };
  //   } catch (error) {
  //     throw new Error(`Failed to fetch ${endpoint}`);
  //   }
  // };

  const fetchAllBranchStaff = async ({ endpoint, branch }) => {
    try {
      const res = await fetch(endpoint);

      const data = await res.json();

      return { ...data, url: endpoint, branch: branch };
    } catch (error) {
      throw new Error(`Failed to fetch ${endpoint}`);
    }
  };

  const queries = useQueries(
    apis
      ?.map((company) =>
        company.map((api) => ({
          queryKey: ["user", api.name],
          queryFn: () =>
            fetchAllBranchStaff({
              endpoint: `${api.url}/api/users`,
              branch: api.name,
            }),
        }))
      )
      .flat()
  );

  // const queries = useQueries(
  //   apis
  //     ?.map((company) =>
  //       company.map((api) => ({
  //         queryKey: ["user", api.name],
  //         queryFn: () => fetchAllBranchStaff(`${api.url}/api/users`),
  //       }))
  //     )
  //     .flat()
  // );

  const staffUserDetails = useMemo(() => {
    try {
      let userBranches = [];

      if (
        queries &&
        queries.length > 0 &&
        Boolean(queries[0])
        //&&
        //  process.env.REACT_APP_SITE_TITLE === "InvexERP"
      ) {
        const companies = queries?.filter((query) => query.data);

        const staff = companies;

        // const companyStaff = staff
        //   ?.map((user) => {
        //     return user?.data?.staff?.map((staf) => {
        //       return { ...staf, url: user?.url };
        //     });
        //   })
        //   .flat();

        const companyStaff = staff
          ?.map((user) => {
            const url = user?.data?.url;
            const branch = user?.data?.branch;

            return user?.data?.staff?.map((staf) => {
              return { ...staf, url: url, Branch: branch };
            });
          })
          .flat();

        const uniqStaff = uniqBy(companyStaff, "username");

        function findUserBranches(username) {
          return companyStaff?.filter((url) => url?.username === username);
        }

        userBranches = uniqStaff.map((branch) => {
          const found = findUserBranches(branch?.username);

          const urls = found.map((url) => url?.url);
          return { ...branch, url: urls };
        });
      }

      const remainingOtherBranchUsers = userBranches?.filter(
        (staff) => staff?.username?.toLowerCase() !== "support"
      ).length;

      if (process.env.REACT_APP_SITE_TITLE === "InvexERP") {
        let uniqueSTaffWithStaffType = userBranches?.map((user) => ({
          ...user,
          userType: user?.userType ? user?.userType : "core",
        }));

        uniqueSTaffWithStaffType = uniqueSTaffWithStaffType?.filter(
          (staff) => staff?.username?.toLowerCase() !== "support"
        );

        const totalCompanyCoreUsers =
          company?.data?.coreUsers || company?.data?.Num_Users;
        const totalCompanyPassiveUsers = company?.data?.passiveUsers || 0;

        const currentCoreUsers = uniqueSTaffWithStaffType?.filter(
          (user) => user?.userType === "core"
        ).length;

        const currentPassiveUsers = uniqueSTaffWithStaffType?.filter(
          (user) => user?.userType === "passive"
        ).length;

        return {
          staff: uniqueSTaffWithStaffType,
          activatedCoreUsers: currentCoreUsers,
          activatedPassiveUsers: currentPassiveUsers,
          totalCoreUsers: totalCompanyCoreUsers,
          totalPassiveUsers: totalCompanyPassiveUsers,
          otherBranchTotalStaff: remainingOtherBranchUsers,
          totalUsers: company?.data?.Num_Users,
        };
      } else {
        let uniqueSTaffWithStaffType = userBranches?.filter(
          (staff) => staff?.username?.toLowerCase() !== "support"
        );
        return {
          staff: uniqueSTaffWithStaffType,
          otherBranchTotalStaff: remainingOtherBranchUsers,
          totalUsers: company?.data?.Num_Users,
        };
      }
    } catch (err) {
      console.log(err);
    }
  }, [queries, company?.data, currentBranchUsers]);

  return (
    <div className="d-flex gap-2 align-items-center">
      <span>
        <b> All Branches:</b>
      </span>
      <span>{staffUserDetails?.otherBranchTotalStaff || "...."}</span>
      <span>of</span>
      {/*      <span>Total Users</span>  */}
      <span>{staffUserDetails?.totalUsers || "...."}</span>

      <button
        title="Refresh"
        onClick={() => {
          refetch();
          queries.forEach(({ refetch }) => refetch());
        }}
        className="btn text-primary"
      >
        <CachedIcon />
      </button>
    </div>
  );
}

function DeleteBranch({
  showDeleteBranches,
  setDeleteBranches,
  hideBranches,
  handleContinue,
}) {
  const [branches, setBranch] = useState(
    showDeleteBranches?.user?.Branch || []
  );

  const [selectedBranch, setSelectedBranch] = useState({
    Branch: branches?.map((api) => ({
      ...api,
      value: api?.url,
      label: api?.branch,
    })),
  });

  function continueProcess({ proceed, Staff_ID, name, reqUrl, status }) {
    if (!reqUrl || (reqUrl?.length < 1 && branches?.length > 0)) {
      toast.error("Select at least a branch");
      return;
    }
    reqUrl = reqUrl.map((url) => url.url);
    handleContinue({ proceed, Staff_ID, name, reqUrl, status });
    // handleContinue()
  }

  return (
    <Modal
      show={showDeleteBranches.show}
      onHide={() => hideBranches()}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="h5">Select Branches</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className=" row">
          <Select
            menuPosition="fixed"
            className="mx-3"
            menuPlacement="auto"
            placeholder="Select branch"
            value={selectedBranch?.Branch}
            name="Branch"
            onChange={(selected) => {
              setSelectedBranch({
                Branch: selected,
              });
            }}
            isSearchable={true}
            isMulti
            options={branches?.map((api) => ({
              ...api,
              value: api.url,
              label: api.branch,
            }))}
          />
          {/* {branches?.map((branch) => (
            <div className=" col-md-3 gap-4 ">
              <button className=" text-center   w-100 py-3">
                {branch.branch}
              </button>{" "}
            </div>
          ))} */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex   w-100 justify-content-center">
          <button
            className="btn btn-primary"
            onClick={() =>
              continueProcess({
                proceed: true,
                Staff_ID: showDeleteBranches?.user?.Staff_ID,
                name: showDeleteBranches?.user?.name,
                reqUrl: selectedBranch?.Branch,
                status: showDeleteBranches?.user.status,
              })
            }
          >
            Continue
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

const AllUsers = () => {
  const initialFilterParams = {
    q: "",
  };

  const navigate = useNavigate();
  const { backendUrl, user, token } = useAuth();
  const [passwordModal, setPasswordModal] = useState({
    modal: false,
    name: "",
    userId: "",
    Branch: [],
  });

  const [editUserModal, setEditUserModal] = useState({
    modal: false,
    name: "",
    userId: "",
  });

  const [
    showEditUserPriviledgesModal,
    setShowEditUserPriviledgesModal,
  ] = useState(false);

  const [openCreateUser, setOpenCreateUser] = useState(false);
  const [showAssignGroupModal, setShowAssignGroupModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");
  const [showDeleteBranches, setDeleteBranches] = useState({
    show: false,
    continue: false,
    selectedBranches: [],
    user: {},
  });

  const location = useLocation();
  const createUserParams = new URLSearchParams(location.search);

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const debouncedFilterParams = useDebounce(filterParams, 500);

  const fetchcompanyDetails = async (url) => {
    const res = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    });

    const data = await res.json();

    let companyData = data?.company;

    return companyData;
  };

  const company = useQuery(
    ["GET_COMPANY_DETAILS"],
    () => fetchcompanyDetails(`${backendUrl}/api/company/details`),
    {}
  );

  // const fetchAllBranchStaff = async (endpoint) => {
  //   try {
  //     const res = await fetch(endpoint);

  //     const data = await res.json();

  //     return { ...data, url: endpoint };
  //   } catch (error) {
  //     throw new Error(`Failed to fetch ${endpoint}`);
  //   }
  // };

  const fetchAllBranchStaff = async ({ endpoint, branch }) => {
    try {
      const res = await fetch(endpoint);

      const data = await res.json();

      return { ...data, url: endpoint, branch: branch };
    } catch (error) {
      throw new Error(`Failed to fetch ${endpoint}`);
    }
  };

  // const queries = useQueries(
  //   apis
  //     ?.map((company) =>
  //       company.map((api) => ({
  //         queryKey: ["user", api.name],
  //         queryFn: () => fetchAllBranchStaff(`${api.url}/api/users`),
  //       }))
  //     )
  //     .flat()
  // );

  const queries = useQueries(
    apis
      ?.map((company) =>
        company.map((api) => ({
          queryKey: ["user", api.name],
          queryFn: () =>
            fetchAllBranchStaff({
              endpoint: `${api.url}/api/users`,
              branch: api.name,
            }),
        }))
      )
      .flat()
  );

  const staffUserDetails = useMemo(() => {
    try {
      let userBranches = [];
      if (
        queries &&
        queries.length > 0 &&
        Boolean(queries[0])
        //&&
        //  process.env.REACT_APP_SITE_TITLE === "InvexERP"
      ) {
        const companies = queries?.filter((query) => query.data);

        const staff = companies;

        // console.log(staff);

        // const companyStaff = staff
        //   ?.map((user) => {
        //     const url = user?.data?.url;
        //     return user?.data?.staff?.map((staf) => {
        //       return { ...staf, url: url };
        //     });
        //   })
        //   .flat();

        const companyStaff = staff
          ?.map((user) => {
            const url = user?.data?.url;
            const branch = user?.data?.branch;
            return user?.data?.staff?.map((staf) => {
              return { ...staf, url: url, Branch: branch };
            });
          })
          .flat();

        let uniqStaff = uniqBy(companyStaff, "username");

        function findStaffBranch(username) {
          let findStaffBranch = companyStaff?.filter(
            (staff) => staff.username === username
          );

          findStaffBranch = findStaffBranch?.map((branch) => ({
            branch: branch.Branch,
            url: branch.url,
          }));
          return findStaffBranch;
        }

        uniqStaff = uniqStaff.map((staff) => {
          const staffBranchFound = findStaffBranch(staff?.username);
          return { ...staff, Branch: staffBranchFound };
        });

        uniqStaff = uniqStaff.map((staff) => ({
          ...staff,
          Branch: uniqBy(staff?.Branch, "branch"),
        }));

        uniqStaff = uniqStaff.filter(
          (branch) =>
            branch.username?.toLowerCase().includes(queryParams?.q) ||
            branch.Name?.toLowerCase().includes(queryParams?.q)
        );

        function findUserBranches(username) {
          return companyStaff?.filter((url) => url?.username === username);
        }

        userBranches = uniqStaff.map((branch) => {
          const found = findUserBranches(branch?.username);

          const urls = found.map((url) => url?.url);
          return { ...branch, url: urls };
        });
      }

      function createPagination(items, itemsPerPage) {
        const totalPages = Math.ceil(items.length / itemsPerPage);
        return Array.from({ length: totalPages }, (_, index) => {
          const startIndex = index * itemsPerPage;
          return items.slice(startIndex, startIndex + itemsPerPage);
        });
      }

      // userBranches = userBranches?.filter(
      //   (staff) => staff?.username?.toLowerCase() !== "support"
      // );
      const itemsPerPage = queryParams?.limit;
      const pages = createPagination(userBranches, itemsPerPage);
      const currentPage = pages[queryParams?.page - 1];

      const remainingOtherBranchUsers = userBranches?.filter(
        (staff) => staff?.username?.toLowerCase() !== "support"
      ).length;

      if (process.env.REACT_APP_SITE_TITLE === "InvexERP") {
        let uniqueSTaffWithStaffType = userBranches?.map((user) => ({
          ...user,
          userType: user?.userType ? user?.userType : "core",
        }));

        uniqueSTaffWithStaffType = uniqueSTaffWithStaffType?.filter(
          (staff) => staff?.username?.toLowerCase() !== "support"
        );

        const totalCompanyCoreUsers =
          company?.data?.coreUsers || company?.data?.Num_Users;
        const totalCompanyPassiveUsers = company?.data?.passiveUsers || 0;

        const currentCoreUsers = uniqueSTaffWithStaffType?.filter(
          (user) => user?.userType === "core"
        ).length;

        const currentPassiveUsers = uniqueSTaffWithStaffType?.filter(
          (user) => user?.userType === "passive"
        ).length;

        return {
          staff: uniqueSTaffWithStaffType,
          activatedCoreUsers: currentCoreUsers,
          activatedPassiveUsers: currentPassiveUsers,
          totalCoreUsers: totalCompanyCoreUsers,
          totalPassiveUsers: totalCompanyPassiveUsers,
          otherBranchTotalStaff: remainingOtherBranchUsers,
          totalUsers: company?.data?.Num_Users,
          currentPage: currentPage,
          count: pages?.length,
        };
      } else {
        let uniqueSTaffWithStaffType = userBranches?.filter(
          (staff) => staff?.username?.toLowerCase() !== "support"
        );
        return {
          staff: uniqueSTaffWithStaffType,
          otherBranchTotalStaff: remainingOtherBranchUsers,
          totalUsers: company?.data?.Num_Users,
          currentPage: currentPage,
          count: pages?.length,
        };
      }
    } catch (err) {
      console.log(err);
    }
  }, [queries, queryParams]);

  const handleRefetch = () => {
    queries.forEach(({ refetch }) => refetch());
  };

  useEffect(() => {
    const createUser = createUserParams.get("create-users");
    if (Boolean(createUser)) {
      setOpenCreateUser(true);
    }
  }, []);

  useEffect(() => {
    setQueryParams((qr) => ({ ...qr, ...debouncedFilterParams }));
  }, [debouncedFilterParams, setQueryParams]);

  let { data, refetch } = useQuery(
    ["FETCH STAFF", queryParams],
    () => fetchActionsUtil(`${backendUrl}/api/users`, "GET"),
    {
      onSuccess: () => { },
      keepPreviousData: true,
    }
  );

  // useEffect(() => {}, [data, data?.staff]);

  const staffUsers = useMemo(() => {
    let company = {};

    if (data?.staff?.length) {
      let staff = data?.staff?.filter((staff) => {
        if (user?.username?.toLowerCase() === "support") {
          return staff;
        } else {
          return staff?.username?.toLowerCase() !== "support";
        }
      });
      company.staff = staff;
      return company;
    }
  }, [data]);

  const lockAccount = useMutation(
    (payload) =>
      fetchActionsUtil(`${payload?.reqUrl}/status`, "POST", "", {
        Staff_ID: payload.Staff_ID,
        Status: payload.Status,
      }),
    {
      onSuccess: () => {
        queries.forEach(({ refetch }) => refetch());
        refetch();
        toast.success(`Account ${currentStatus} Successfully`);
      },
      onError: () => {
        toast.error("Unable to lock account");
      },
    }
  );

  const deleteAccount = useMutation(
    (payload) =>
      fetchActionsUtil(`${payload.reqUrl}/${payload.staffID}`, "DELETE"),
    {
      onSuccess: () => {
        queries.forEach(({ refetch }) => refetch());

        refetch();
        toast.success("Account deleted Successfully");
      },
      onError: () => {
        toast.error("Unable to delete");
      },
    }
  );

  const onLockAccount = async (Staff_ID, name, status, reqUrl) => {
    const Status = status === "Lock" ? "Open" : "Lock";
    const Distatus = status === "Lock" ? "Unblock" : "block";
    setCurrentStatus(`${Distatus}ed`);

    if (
      await ConfirmDialog({
        title: `${Status} Account`,
        description: `Are you sure you want to ${Distatus.toLocaleUpperCase()} ${name}'s Account`,
      })
    ) {
      if (reqUrl?.length > 0) {
        reqUrl.map(async (req) => {
          lockAccount.mutate({ Staff_ID, Status, reqUrl: req });
        });
      } else {
        lockAccount.mutate({
          Staff_ID,
          Status,
          reqUrl: `${backendUrl}/api/users`,
        });
      }
    }
  };

  const onDeleteAccount = async (staffID, name, reqUrl) => {
    if (
      await ConfirmDialog({
        type: 'danger',
        title: "Delete Account",
        description: `Are you sure you want to DELETE ${name}'s Account`,
      })
    ) {
      if (reqUrl?.length > 0) {
        reqUrl.map(async (req) =>
          deleteAccount.mutate({ staffID, reqUrl: req })
        );
      } else {
        deleteAccount.mutate({ staffID, reqUrl: `${backendUrl}/api/users` });
      }
    }
  };

  const showBranches = ({ Staff_ID, name, Branch, status }) => {
    console.log(Staff_ID, name, Branch, status);
    setDeleteBranches({
      ...showDeleteBranches,
      show: true,
      user: { Staff_ID, name, Branch, status },
    });
  };
  const hideBranches = () => {
    setDeleteBranches({
      show: false,
      continue: false,
      selectedBranches: [],
      user: {},
    });
  };

  const handleContinue = ({ proceed, Staff_ID, name, reqUrl, status }) => {
    // setDeleteBranches({
    //   ...showBranches,
    //   show: false,
    // });

    if (proceed && Boolean(status)) {
      onLockAccount(Staff_ID, name, status, reqUrl);
    }
    if (proceed && !Boolean(status)) {
      onDeleteAccount(Staff_ID, name, reqUrl);
    }

    setDeleteBranches({
      show: false,
      continue: false,
      selectedBranches: [],
      user: {},
    });
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const displayTableData = (el, index) => {
    return (
      <>
        <td>
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              className="bg-white border-0"
              bsPrefix="print more"
            >
              <DotsVerticalIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu
              popperConfig={{
                strategy: "fixed",
              }}
              renderOnMount
              className="dropdown-with-icons"
            >
              <Dropdown.Item
                as="div"
                onClick={() =>
                  setEditUserModal({
                    userId: el.Staff_ID,
                    modal: true,
                    name: el.Name,
                  })
                }
                className="p-cursor"
              >
                {`Edit Account`}
              </Dropdown.Item>
              <Dropdown.Item
                as="div"
                onClick={() =>
                  showBranches({
                    Staff_ID: el.Staff_ID,
                    name: el.Name,
                    Branch: el.Branch,
                  })
                }
                className="p-cursor"
              >
                {`Delete Account `}
              </Dropdown.Item>

              <Dropdown.Item
                as="div"
                onClick={() => {
                  setEditUserModal({
                    userId: el.Staff_ID,
                    modal: false,
                    name: el.Name,
                  });
                  setShowEditUserPriviledgesModal(true);
                }}
                className="p-cursor"
              >
                {`Edit Privileges`}
              </Dropdown.Item>

              {/* {el?.Department?.toLowerCase() === "admin" && (
                <Dropdown.Item
                  as="div"
                  onClick={() => {
                    setEditUserModal({
                      userId: el.Staff_ID,
                      modal: false,
                      name: el.Name,
                    });
                    setShowEditUserPriviledgesModal(true);
                  }}
                  className="p-cursor"
                >
                  {`Edit Privileges`}
                </Dropdown.Item>
              )} */}

              <Dropdown.Item
                as="div"
                onClick={() =>
                  setPasswordModal({
                    userId: el.Staff_ID,
                    modal: true,
                    name: el.Name,
                    Branch: el?.Branch,
                  })
                }
                className="p-cursor"
              >
                {`Change Password`}
              </Dropdown.Item>
              <Dropdown.Item
                as="div"
                onClick={() =>
                  showBranches({
                    Staff_ID: el?.Staff_ID,
                    name: el?.Name,
                    Branch: el?.Branch,
                    status: el?.Status,
                  })
                }
                className="p-cursor"
              >
                {`${el.Status === "Lock" ? "Unblock" : "Block"} Account`}
              </Dropdown.Item>
              <Dropdown.Item
                as="div"
                onClick={() => navigate(`/audit?&userName=${el.Name}`)}
                className="p-cursor"
              >
                {`Activity Log `}
              </Dropdown.Item>
              {[
                "Hospital Management Board",
                "Primary Healthcare Center",
                "Ministry of Health",
              ].includes(el?.Department) && (
                  <Dropdown.Item
                    as="div"
                    onClick={() => setShowAssignGroupModal(el)}
                    className="p-cursor"
                  >
                    Assign Customer Group
                  </Dropdown.Item>
                )}
            </Dropdown.Menu>
          </Dropdown>
        </td>
        <td className="">{el.Staff_ID}</td>
        <td className=" " style={{ width: "13%" }}>
          {el.Title}
        </td>
        <td>{el.Name}</td>
        <td>{el.username}</td>
        <td>{el.email}</td>
        <td>{el.Department}</td>
        <td>{el?.Branch?.map((branch) => branch?.branch)?.join(" , ")}</td>
        <td>{el.AccessLavel}</td>
        <td
          className={` ${el.Status === "Lock" ? "text-danger" : "text-success"
            } fw-bold`}
        >
          {el.Status === "Lock" ? "Blocked" : "Active"}
        </td>
      </>
    );
  };

  const tableHead = () => {
    return (
      <thead>
        <tr>
          <th />
          <th>Staff ID</th>
          <th>Title</th>
          <th>Full Name</th>
          <th>User Name</th>
          <th>Email</th>
          <th>Department</th>
          <th>Branch</th>
          <th>Access Level</th>
          <th>Status</th>
        </tr>
      </thead>
    );
  };

  const onDownloadExcelData = async () => {
    const { limit, page, ...rest } = queryParams;
    // let exData = await fetchExcelData(
    //   `${backendUrl}/api/users?${queryString.stringify(rest)}`,
    //   "GET"
    // );

    let exData = staffUserDetails?.staff?.map((d) => {
      let branch = d?.Branch;
      branch = !isEmpty(branch)
        ? branch.map((b) => b.branch)?.join(" , ")
        : d.Branch;
      return [
        d.Staff_ID,
        d.Title,
        d.Name,
        d.username,
        d.email,
        d.Department,
        branch,
        d.AccessLavel,
        d.Status === "Lock" ? "Blocked" : "Active",
      ];
    });
    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
          new Date(rest.startDate),
          "E MMM d yyyy k:mm:ss z"
        )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      [""],
      ["All Users"],
      [date],
      [""],
      [
        "Staff ID",
        "Title",
        "Full Name",
        "User Name",
        "Email",
        "Department",
        "Branch",
        "Access Level",
        "Status",
      ],
      ...exData,
      [""],
    ];
    // console.log(exData);
    setExcelData(exData);
  };

  return (
    <div className="dashboard-content">
      <div className=" pt-2 bg-white">
        <main className="table-sticky-header">
          <div className="">
            <header className="container px-4 my-3 d-flex justify-content-between">
              <div className="d-flex align-items-center  gap-4">
                <button
                  onClick={() => navigate(-1)}
                  className="btn back-btn   "
                >
                  <BackIcon />
                </button>

                {/* <h6>User List</h6>
                <p className="mx-3">{data?.count}</p> */}

                <OtherBranchUsers
                  currentBranchUsers={data?.count}
                  refetch={refetch}
                />
              </div>

              <div className="actions mr-5 d-flex align-items-center gap-2">
                <CSVLink
                  className="btn print d-none"
                  filename={`Users (${format(
                    new Date(),
                    "dd-MMM-yyyy hh:mm:ss a"
                  )}).csv`}
                  data={excelData}
                  ref={CSVLinkRef}
                />

                <Dropdown>
                  <Dropdown.Toggle
                    variant=""
                    className="btn print"
                    disabled={isfetchingExcel}
                    bsPrefix=""
                  >
                    Export
                    <ExportIcon color="#008000" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    popperConfig={{
                      strategy: "fixed",
                    }}
                    renderOnMount
                    className="text-center"
                  >
                    <Dropdown.Item
                      as="div"
                      onClick={onDownloadExcelData}
                      className="p-cursor"
                    >
                      Excel <ExcelIcon color="#008000" />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Form.Group>
                  <div className="input-group">
                    <>
                      <CustomInput
                        className="p-cursor"
                        name="q"
                        placeholder="Search name..."
                        typeOfInput="text"
                        value={filterParams?.q}
                        onChange={(e) => handleFilterParamsChange(e)}
                      />

                      <span
                        className="input-group-text border p-cursor"
                        id="basic-addon1"
                      >
                        <SearchIcon />
                      </span>
                    </>
                  </div>
                </Form.Group>

                <button
                  onClick={() => setOpenCreateUser(true)}
                  className="btn bg print text-black"
                >
                  Create New <ExchangeFunds color="black" />
                </button>

                <Link
                  className="btn bg print text-black"
                  to="/dashboard/user-managemnt/departments"
                >
                  Departments
                  {/* <ExchangeFunds color="black" /> */}
                </Link>
                <button className="btn btn-primary">
                  <Link className="text-white" to="/audit">
                    Activity Log
                  </Link>
                </button>
              </div>
            </header>

            <div className="px-md-4">
              {staffUserDetails?.staff?.length > 0 && (
                <TableComponent
                  responsive
                  borderless
                  striped
                  tableHeadsFunction={tableHead}
                  mainDataArray={staffUserDetails?.currentPage}
                  tableDataRowFunction={displayTableData}
                  className="product-table text-break"
                />
              )}
              {staffUserDetails?.staff?.length === 0 && <NoTableItem />}
            </div>
          </div>
        </main>
        <div className="d-flex justify-content-between px-3 align-items-center pagination py-4">
          <div className="pagination_left">
            {/* <span className="m-0 p-0">Show</span> */}
            <select
              value={queryParams.limit}
              name="limit"
              className="form-select "
              onChange={(e) => handleSearchQueryChange(e)}
            >
              <option value="10">10 rows</option>
              <option value="20">20 rows</option>
              <option value="30">30 rows</option>
              <option value="40">40 rows</option>
              <option value="50">50 rows</option>
              <option value="100">100 rows</option>
            </select>
          </div>

          <ReactPaginate
            {...paginationOptions}
            pageCount={Math.ceil(staffUserDetails?.count)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={0}
            onPageChange={({ selected }) => {
              document.body.scrollTop = document.documentElement.scrollTop = 0;
              setQueryParams({
                ...queryParams,
                page: selected + 1,
              });
            }}
            forcePage={parseInt(queryParams.page) - 1}
          />
        </div>
      </div>

      {passwordModal.modal && (
        <PasswordModal
          onHide={() => setPasswordModal({ ...passwordModal, modal: false })}
          show={passwordModal.modal}
          modalName={passwordModal.name}
          userId={passwordModal.userId}
          Branch={passwordModal.Branch}
        />
      )}

      {showEditUserPriviledgesModal && (
        <EditUserPriviledgeModal
          showEditUserPriviledgesModal={showEditUserPriviledgesModal}
          setShowEditUserPriviledgesModal={setShowEditUserPriviledgesModal}
          userId={editUserModal.userId}
          userName={editUserModal.name}
        />
      )}

      {editUserModal.modal && (
        <EditUserAccountModal
          onHide={() => setEditUserModal({ ...editUserModal, modal: false })}
          show={editUserModal.modal}
          modalName={editUserModal.name}
          userId={editUserModal.userId}
          refetch={handleRefetch}
        />
      )}
      {openCreateUser && (
        <CreateUserAccount
          onHide={() => setOpenCreateUser(false)}
          show={openCreateUser}
          refetch={handleRefetch}
          setShowEditUserPriviledgesModal={setShowEditUserPriviledgesModal}
        />
      )}
      {showAssignGroupModal && (
        <AssignGroupModal
          selectedStaffToAssign={showAssignGroupModal}
          showAssignGroupModal={showAssignGroupModal}
          setShowAssignGroupModal={setShowAssignGroupModal}
        />
      )}

      {showDeleteBranches.show && (
        <DeleteBranch
          showDeleteBranches={showDeleteBranches}
          setDeleteBranches={setDeleteBranches}
          hideBranches={hideBranches}
          handleContinue={handleContinue}
        />
      )}
    </div>
  );
};

export default AllUsers;
