import CloseIcon from 'mdi-react/CloseIcon';
import HelpIcon from 'mdi-react/HelpIcon';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Badge, Button, Card, Form, InputGroup } from 'react-bootstrap';
import MicrophoneIcon from 'mdi-react/MicrophoneIcon';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import quickLinksData from '../QuickLinks.json';
import Fuse from 'fuse.js';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from "../../hooks/useAuth";
import { useStoreState } from 'easy-peasy';
import { useEffectOnce } from '../../utils/hooks';
import { isEmpty } from 'lodash';


const MAX_MESSAGES = 50;

const ChatbotPopup = () => {
  const audioRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);

  const playNotification = () => {
    if (audioRef.current) {
      audioRef.current.play().catch(error => {
        console.error("Playback failed", error);
      });
    }
  };

  const playMutedNotification = () => {
    if (audioRef.current) {
      audioRef.current.muted = false;
      audioRef.current.play().catch(error => {
        console.error("Playback failed", error);
      });
    }
  };

  const handleInteraction = () => {
    setIsMuted(false);
    playNotification();
  };

  const generalSettings = useStoreState((state) => state.generalSettings);
  const [isOpen, setIsOpen] = useState(false);
  const toggleChat = () => setIsOpen(!isOpen);
  const { user: authUser } = useAuth()
  const navigate = useNavigate()

  const chatRef = useRef(null);
  const messageIdCounter = useRef(0);

  const [messages, setMessages] = useState([{ id: messageIdCounter.current++, text: `Hi ${authUser.Name}, my name is Ngozi. \nHow can I help you today?`, sender: 'bot', },
  { id: messageIdCounter.current++, text: `“Say e.g “Make Invoice”  to find the Make Invoice quick link”`, sender: 'bot', }]);
  const [inputText, setInputText] = useState('');
  const [keywordLinks, setKeywordLinks] = useState([]);

  const { finalTranscript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition({
    transcribing: true,
  });

  const messagesContainerRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);


  const fuseRef = useRef(null);
  const chatScrollRef = useRef(null);

  useEffect(() => {
    const mappedLinks = []
    quickLinksData.forEach((parent) => {
      parent.child.forEach((child) => {
        child.routes.forEach((route) => {
          mappedLinks.push({
            name: route.name,
            link: route.link,
          });
        });
      });
    });
    setKeywordLinks(mappedLinks);

    fuseRef.current = new Fuse(mappedLinks, {
      keys: ['name'],
      threshold: 0.6,
      distance: 100,
      minMatchCharLength: 2,
    });
  }, []);

  useEffect(() => {
    if (chatScrollRef.current) {
      chatScrollRef.current.scrollTop = chatScrollRef.current.scrollHeight;
    }
  }, [messages]);

  const startListening = () => {
    resetTranscript();
    SpeechRecognition.startListening({
      continuous: true,
      language: 'en-US',
    });
  };

  const stopListeningAndSend = () => {
    SpeechRecognition.stopListening();
  };

  const addMessage = (text, sender, results, highlightedText) => {
    setMessages((prevMessages) => {
      const newMessage = { id: messageIdCounter.current++, text, sender, results, highlightedText };
      const updatedMessages = [...prevMessages, newMessage];
      return updatedMessages.slice(-MAX_MESSAGES);
    });
  };

  const highlightKeywords = (text, keywords) => {
    let highlightedText = text;
    keywords.forEach((keyword) => {
      const regex = new RegExp(`\\b${keyword}\\b`, 'gi');
      highlightedText = highlightedText.replace(regex, (match) => `<span class="bg-yellow-200">${match}</span>`);
    });
    return highlightedText;
  };

  const processInput = useCallback((input) => {
    if (fuseRef.current) {
      const results = fuseRef.current.search(input);
      const matches = results.map((result) => result.item);

      const matchedKeywords = matches.map((match) => match.name);

      const highlightedText = highlightKeywords(input, matchedKeywords);

      addMessage(input, 'user', undefined, highlightedText);

      const foundOnetoOne = matches.find(el => el.name.toLowerCase() === input.toLowerCase())
      if (foundOnetoOne) {
        navigate(foundOnetoOne.link)
      }

      if (matches.length > 0) {
        addMessage(`I found ${matches.length} matching result${matches.length > 1 ? 's' : ''}:`, 'bot', matches,);
      } else {
        addMessage(
          "I couldn't find any matches. \nYou can try rephrasing your request or using one of the preset keywords.",
          'bot',
        );
      }
    }
    setInputText('');
    handleInteraction()
  }, []);



  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputText.trim()) {
      processInput(inputText);
    }
  };

  useEffect(() => {
    console.log('finalTranscript:', finalTranscript);
    if (finalTranscript) {
      setInputText(finalTranscript);
      processInput(finalTranscript);
      resetTranscript();
    }
  }, [finalTranscript, listening, processInput, resetTranscript]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (chatRef.current && !chatRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <>
      <div ref={chatRef} className="position-fixed bottom-0 end-0 m-4" style={{ width: isOpen ? '350px' : '50px', zIndex: 1000 }}>
        {isOpen && (
          <Card style={{ height: '450px', marginBottom: '10px' }} className='shadow'>
            <Card.Header><div className='d-flex justify-content-between align-items-center'> <span>Ngozi A.I. <Badge bg='danger'>BETA</Badge> </span>
              <Button
                size="icon"
                variant={listening ? 'light' : 'secondary'}
                onMouseDown={startListening}
                onMouseUp={stopListeningAndSend}
                onTouchStart={startListening}
                onTouchEnd={stopListeningAndSend}
                disabled={!browserSupportsSpeechRecognition}
                aria-label="Push to talk"
              >
                <MicrophoneIcon className="h-4 w-4" />
              </Button></div></Card.Header>
            <Card.Body ref={messagesContainerRef} style={{ overflowY: 'auto', display: 'flex', flexDirection: 'column', whiteSpace: 'pre-line', scrollBehavior: 'smooth' }}>
              {messages.map((message, index) => (
                <div key={index} className={`mb-3 ${message.sender === 'user' ? 'text-end' : ''}`}>
                  <span className={`p-2 rounded d-inline-block ${message.sender === 'user' ? 'border' : 'bg-light-blue'}`}>
                    {message.highlightedText ? <span dangerouslySetInnerHTML={{ __html: message.highlightedText }} /> : message.text}
                  </span>

                  {message.results && (
                    <div className="d-flex gap-2 my-2 flex-wrap">
                      {message.results.map((result, index) => (
                        <Badge key={index} bg="light-blue" className="p-2" style={{ fontSize: '0.875rem', fontWeight: 'normal' }}>
                          <Link to={result.link} rel="noopener noreferrer">
                            {result.name}
                          </Link>
                        </Badge>
                      ))}
                    </div>)}
                </div>
              ))}


            </Card.Body>
            <Card.Footer>
              <Form onSubmit={handleSubmit}>
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    placeholder="Type your message or use push-to-talk..."
                  />
                  <Button type="submit" variant="primary">Send</Button>
                </InputGroup>
              </Form>
            </Card.Footer>
          </Card>
        )
        }
        {generalSettings?.aiAssistant ? <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            onClick={() => { if (messages?.length === 2) { handleInteraction(); } toggleChat(); }}
            variant="primary"

            className='shadow border'
            style={{ width: '50px', height: '50px', padding: 0, borderRadius: '50%', }}
          >
            {isOpen ? <CloseIcon /> : <span>Ngozi</span>}
          </Button>
        </div> : null}
      </div>
      <audio ref={audioRef} muted={isMuted}>
        <source src="/sound.wav" type="audio/wav" />
        Your browser does not support the audio element.
      </audio></>
  );
};

export default ChatbotPopup;
