import {
  Table,
  InputGroup,
  Button,
  Form,
  Dropdown,
  Modal,
} from "react-bootstrap";
import CachedIcon from "mdi-react/CachedIcon";
import {
  DeleteIcon,
  FilterCollapseIcon,
  FilterTwoIcon,
  PageHeaderListIcon,
  TagSimpleIcon,
  // PrintIcon,
} from "../Icons";
import "../../assets/scss/reports/cashbook.scss";
import { useEffect, useState } from "react";
// import DateRangePicker from "../../utils/DateRangePicker";
import queryString from "query-string";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
// import Select from "react-select";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
// import { services } from "../../../config";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  formatDate,
  // defaultSelectValue,
  paginationOptions,
  scrollToTop,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { useAuth } from "../../hooks/useAuth";
// import BatchesModal from ".././BatchesModal";
import ConfirmDialog from "../ConfirmDialogue";
import NoTableItem from "../utils/NoTableItem";
import { fetchActionsUtil } from "../../utils/helpers";
import PageHeader from "../PageHeader";
import { CreateProductionPlanModal } from "./CreateProductPlan";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
export default function PlantPerformance() {
  //
  const { backendUrl } = useAuth();
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showCreateNew, setShowCreateNew] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [lgShow, setLgShow] = useState(false);

  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  useScrollTop();

  const initialFilterParams = {
    page: 1,
    limit: 40,
    barcode: "",
    itemName: "",
    // startDate: format(new Date(), "yyyy-MM-dd"),
    // endDate: format(new Date(), "yyyy-MM-dd"),
    startDate: "",
    endDate: "",
  };
  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...queryParams,
    ...initialFilterParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const plantLocal = JSON.parse(window.localStorage.getItem("plantDetails"));

  const {
    data = { count: 0, productionLogs: [] },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    ["GET_PRODUCT_PLAN_DATA_1", { queryParams, plantLocal }],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/production/plant-performance/${plantLocal.PLID
        }?&${queryString.stringify(queryParams)}`,
        "GET"
      ),
    {
      keepPreviousData: true,
    }
  );

  const deleteProductPlan = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/production/${plantLocal.PLID}`,
        "DELETE",
        "",
        payload
      ),
    {
      onSuccess: ({ message }) => {
        toast.success("Successfully Delete");

        refetch();
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  // console.log(data);

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams({
      ...initialFilterParams,
    });
  };

  const deletePlan = async (planId) => {
    if (
      await ConfirmDialog({
        title: "Delete Production Plan",
        description: "Are you sure you want to delete this production plan",
      })
    ) {
      deleteProductPlan.mutate({ planId });
    }
  };

  return (
    <section className="cashbook-wrapped">
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className={`filter-area ${showFilter ? "open" : "close"}`}>
              <div className="filter-header">
                <h2>
                  <FilterTwoIcon /> Filters
                </h2>

                <Button
                  variant="white"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <FilterCollapseIcon />
                </Button>
              </div>
              <div className="filter-body">
                <Form>
                  <div className="body">
                    <Form.Group className="mb-2-5">
                      <Form.Label>Item Code</Form.Label>

                      <InputGroup>
                        <Form.Control
                          name="barcode"
                          placeholder="Enter Item Code"
                          value={filterParams?.barcode}
                          onChange={(e) => handleFilterParamsChange(e)}
                        />

                        <Popover
                          isOpen={showItemsPopover}
                          onClickOutside={() => setShowItemsPopover(false)}
                          //   content={() => (
                          //     <WarehouseItemTable
                          //       handleSelectedItem={handleSelectedItem}
                          //     />
                          //   )}
                          position="bottom"
                        >
                          <InputGroup.Text
                            onClick={() =>
                              setShowItemsPopover(!showItemsPopover)
                            }
                          >
                            <MagnifyIcon />
                          </InputGroup.Text>
                        </Popover>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-2-5">
                      <Form.Label>Item Name</Form.Label>
                      <Form.Control
                        name="itemName"
                        value={filterParams?.itemName}
                        placeholder="Enter Item Name"
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                    </Form.Group>

                    <hr className="mt-3 mb-4" />
                    <Form.Group className="">
                      <Form.Label>Date Range</Form.Label>
                      <div className="position-relative">
                        <RsDateRangePicker
                          placement="topStart"
                          value={
                            filterParams.startDate && filterParams.endDate
                              ? [
                                parse(
                                  filterParams.startDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                                parse(
                                  filterParams.endDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                              ]
                              : []
                          }
                          onClean={() => clearDateRange()}
                          onOk={(date) => filterByDateRange(date)}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="buttons rounded">
                    <Button onClick={() => reset()} variant="white">
                      Reset
                    </Button>
                    <Button onClick={() => search()} variant="primary">
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            <div className="content-body">
              <header>
                <h1>
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  Plant Performance
                  <button
                    onClick={() => refetch()}
                    className="btn text-primary"
                    title="Refresh"
                  >
                    <CachedIcon />
                  </button>
                </h1>
                <div className="actions mr-5">
                  <button
                    onClick={() => setShowCreateNew(true)}
                    className="btn bg-primary text-white"
                  >
                    Enter New Plan
                  </button>

                  {/*  <button className="btn print">
                Print <PrintIcon />
              </button>
              <button className="btn print">
                Print <ExportIcon />
              </button> */}
                </div>
              </header>

              <div className="px-md-4">
                <div className="mb-5">
                  <Table
                    responsive
                    borderless
                    striped
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        <th />
                        <th>S/N</th>
                        {/*   <th>Plan ID</th> */}
                        <th>Title</th>
                        <th>Item Name</th>
                        <th>Plan Qty</th>
                        <th>Produced Qty</th>
                        <th>Waste</th>
                        {/*  <th>Estimated Cost</th> */}
                        <th>Estimated Cost</th>
                        {/*   <th>Actual Cost</th> */}
                        <th>Actual Cost</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data?.productionLogs?.map((el, index) => (
                        <tr key={index}>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant=""
                                className="bg-white border-0"
                                bsPrefix="print more"
                                style={{ height: "3px" }}
                              >
                                <DotsHorizontalIcon />
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                popperConfig={{
                                  strategy: "fixed",
                                }}
                                renderOnMount
                                className="dropdown-with-icons"
                              >
                                <Dropdown.Item
                                  as="button"
                                  onClick={() => {
                                    setLgShow(el);
                                  }}
                                >
                                  <TagSimpleIcon className="text-light" />
                                  Bill of Materials
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                          <td>{index + 1}</td>
                          {/*   <td>{el?.Plan_Id}</td> */}
                          <td>{el?.plan?.Item_Name}</td>
                          <td>{el?.plan?.Plan_Name}</td>
                          <td>{el?.plan?.Quantity}</td>
                          <td>{el?.Quantity}</td>
                          <td>
                            {el?.items
                              .map((el) => el?.DamageQty)
                              .reduce((a, b) => currency(a).add(b).value, 0)}
                          </td>
                          <td>
                            {currency(el?.UnitCost, {
                              symbol: "",
                            }).format()}
                          </td>
                          {/*   <td>
                            {currency(el?.UnitCost, {
                              symbol: "",
                            })
                              .multiply(el.Quantity)
                              .format()}
                          </td> */}
                          <td>
                            {currency(el?.plan?.UnitCost, {
                              symbol: "",
                            }).format()}
                          </td>
                          {/*   <td>
                            {currency(el?.plan?.UnitCost, {
                              symbol: "",
                            })
                              .multiply(el?.plan?.Quantity)
                              .format()}
                          </td> */}
                          <td className="text-nowrap">
                            {formatDate(el.Date_Log)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>

              {data?.ProductionLog?.length < 1 ? (
                <div>
                  <NoTableItem queryParams={queryParams} />
                  <p className="text-center">{queryParams?.customerName}</p>
                </div>
              ) : (
                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data.count / queryParams.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      scrollToTop();
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>
              )}
            </div>
          </div>
          {showCreateNew && (
            <CreateProductionPlanModal
              onHide={() => setShowCreateNew(false)}
              show={showCreateNew}
              refetchMain={refetch}
            />
          )}
          {showEdit && (
            <CreateProductionPlanModal
              onHide={() => setShowEdit(false)}
              show={showEdit}
              refetchMain={refetch}
              choosedItem={selectedItem}
            />
          )}

          {lgShow && (
            <BillOfMaterials
              lgShow={lgShow}
              setLgShow={setLgShow}
              items={lgShow.items}
            />
          )}
        </div>
      </main>
    </section>
  );
}

function BillOfMaterials({ lgShow, setLgShow, items }) {
  return (
    <>
      <Button onClick={() => setLgShow(true)}>Large modal</Button>

      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5" id="example-modal-sizes-title-lg">
            {lgShow?.plan?.Item_Name} - Bill of Materials
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped responsive className="product-table" size="sm">
            <thead>
              <th />
              <th>Item Code</th>
              <th>Item Name</th>
              <th>Unit</th>
              <th>Estimated Qty</th>
              <th>Actual Qty</th>
              <th>Waste</th>
              <th>Estimated Cost</th>
              <th>Actual Cost</th>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.Bar_Code}</td>
                  <td>{item.Item_Name}</td>
                  <td>{item.Unit}</td>
                  <td>{item.estimateQty || 0}</td>
                  <td>{item.ActualQty || 0}</td>
                  <td>{item?.DamageQty || 0}</td>
                  <td>
                    {currency(item?.proCost, {
                      symbol: "",
                    }).format()}
                  </td>
                  <td>
                    {currency(item.AmountDue, {
                      symbol: "",
                    }).format()}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
}
