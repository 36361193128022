import { Modal, Form, Button } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import { useBackendUrl, useEffectOnce } from "../../utils/hooks";

import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { useStoreActions, useStoreState } from "easy-peasy";
import Select from "react-select";
import { useEffect } from "react";
import currency from "currency.js";
import CreatableSelect from "react-select/creatable";
import { useQuery, useQueryClient } from "react-query";
import { queryActions } from "../../utils/reactQueryActions";
import { padStart } from "lodash";

export default function UpdateNumberSystemModal({
  selectedNumberSystem,
  setSelectedNumberSystem,
  refetch,
  type,
}) {
  const backendUrl = useBackendUrl();

  const initialValues = {
    documentType: selectedNumberSystem.documentType,
    numberPrefix: selectedNumberSystem.numberPrefix,
    startNumber: selectedNumberSystem?.startNumber
      ? padStart(selectedNumberSystem?.startNumber, 4, 0)
      : "",
    endNumber: selectedNumberSystem.endNumber
      ? padStart(selectedNumberSystem?.endNumber, 4, 0)
      : "",
    currentNumber: selectedNumberSystem.currentNumber
      ? padStart(selectedNumberSystem?.currentNumber, 4, 0)
      : "",
    disabled: Boolean(selectedNumberSystem.disabled),
  };
  const schema = yup.object().shape({
    documentType: yup.string().required("required"),
    //  numberPrefix: yup.string().required("required"),
    startNumber: yup.string().required("required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      updateNumberSystemMutation.mutate({
        ...values,
        formId: selectedNumberSystem.formId,
      });
    },
  });

  const updateNumberSystem = async (payload) => {
    let url = `${backendUrl}/api/numbersystem/update`;

    if (type === "form") {
      url = `${backendUrl}/api/numbersystem/update-form-number`;
    }
    let response = await fetch(url, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const updateNumberSystemMutation = useMutation(
    (payload) => updateNumberSystem(payload),
    {
      onSuccess: ({ message, data }) => {
        refetch();
        toast.success(message);
        setSelectedNumberSystem(null);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  return (
    <Modal
      show={selectedNumberSystem}
      onHide={() => {
        setSelectedNumberSystem(null);
      }}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h5">
            Edit{" "}
            {selectedNumberSystem?.documentType === "Inventory"
              ? "Sales Inventory Request"
              : selectedNumberSystem?.documentType}{" "}
            Numbering
          </h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          onSubmit={formik.handleSubmit}
        // style={isFetching ? { pointerEvents: "none", opacity: 0.5 } : {}}
        >
          <Form.Group className=" mb-3 pb-2">
            <Form.Label className="mb-1">Number Prefix</Form.Label>
            <Form.Control
              className=""
              type="text"
              placeholder="eg AB-CD-EF"
              name="numberPrefix"
              value={formik.values.numberPrefix}
              onChange={formik.handleChange}
              isInvalid={
                formik.touched.numberPrefix && !!formik.errors.numberPrefix
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.numberPrefix}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Start Number</Form.Label>

            <Form.Control
              className=""
              type="number"
              name="startNumber"
              value={formik.values.startNumber}
              onChange={formik.handleChange}
              isInvalid={
                formik.touched.startNumber && !!formik.errors.startNumber
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.startNumber}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>End Number</Form.Label>

            <Form.Control
              className=""
              type="number"
              name="endNumber"
              value={formik.values.endNumber}
              onChange={formik.handleChange}
              isInvalid={formik.touched.endNumber && !!formik.errors.endNumber}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.startNumber}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Current Number</Form.Label>

            <Form.Control
              className=""
              type="number"
              name="currentNumber"
              value={formik.values.currentNumber}
              onChange={formik.handleChange}
              isInvalid={
                formik.touched.currentNumber && !!formik.errors.currentNumber
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.currentNumber}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className=" mb-3 pb-2">
            <Form.Label className="d-flex gap-3 fw-6">
              <Form.Switch
                label="Disable"
                checked={formik.values.disabled}
                onChange={() =>
                  formik.setFieldValue("disabled", !formik.values.disabled)
                }
              />
            </Form.Label>
          </Form.Group>

          <Button
            disabled={updateNumberSystemMutation.isLoading}
            variant="primary"
            className="w-100 p-3"
            type="submit"
          >
            {updateNumberSystemMutation.isLoading ? "Please wait…" : "Save"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

/* export default function UpdateItemNumberSystemModal(props) {
  return createConfirmation(confirmable(UpdateNumberSystem))({ ...props });
}
 */
