import { Modal, Form, Button, InputGroup } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import { useBackendUrl, useEffectOnce } from "../utils/hooks";
import NumberCustomInput from "./utils/NumberCustomInput";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import {
  customerFullName,
  // vendorFullName,
  qtyFormat,
  tonsToPcs,
  Units,
  unitsResolver,
} from "../utils/helpers";
import { appSettings } from "../config";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { useStoreActions, useStoreState } from "easy-peasy";
import Select from "react-select";
import ItemSizeDialog from "./ItemSizeDialog";
import { useEffect } from "react";
import currency from "currency.js";
import { Popover } from "react-tiny-popover";
import ExpensesSelector from "./utils/ExpensesSelector";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import eventBus from "../utils/EventBus";
import { useMemo } from "react";
import Datetime from "react-datetime";
import moment from "moment";
import { lowerCase, isEmpty } from "lodash";
import ConvertQuantity from "./utils/ConvertQuantity";
import ConfirmDialog from "./ConfirmDialogue";
import VendorSelector from "./Vendor/VendorSelector";
// import VendorSearchableSelect from "./utils/VendorSearchableSelect";

const adjustmentTypeOptions = [
  {
    label: "Shortage",
    value: "shortage",
  },
  {
    label: "Excess",
    value: "excess",
  },
];

const adjustmentToOptions = [
  {
    label: "Vendor",
    value: "Vendor",
  },
  {
    label: "Account",
    value: "Account",
  },
];

export default function OutsourceBatchToVendorModal({
  showOutsourceBatchToVendorModal = true,
  selectedBatchItem,
  selectedItem,
  setSelectedBatchItem,
  setShowOutsourceBatchToVendorModal,
  refetch,
}) {
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const backendUrl = useBackendUrl();
  const [showJournalPopover, setShowJournalPopover] = useState(false);
  const [showVendorPopover, setShowVendorPopover] = useState(false);

  const initialValues = {
    Amount: 0,
    AccountID: "",
    Description: "",
    Vendor_ID: "",
    vendorName: "",
    salesDate: moment(),
    Remark: "",
  };
  const schema = yup.object().shape({
    Amount: yup.string().required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      if (isEmpty(values.AccountID)) {
        formik.setFieldError("Description", "Please select an Account");
        return;
      }
      if (isEmpty(values.Vendor_ID)) {
        formik.setFieldError("Vendor_ID", "Please select a Vendor");
        return;
      }

      if (
        await ConfirmDialog({
          title: "Post Transaction",
          description: "Are you sure, you want to make this transaction",
        })
      ) {
        outsourceMutation.mutate({
          ...values,
        });
      }
    },
  });

  const outsource = async (payload) => {
    let response = await fetch(`${backendUrl}/api/invexcloud/outsource`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const outsourceMutation = useMutation((payload) => outsource(payload), {
    onSuccess: ({ message, data }) => {
      refetch();
      toast.success(message);
      setSelectedBatchItem(null);
      setShowOutsourceBatchToVendorModal(false);
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const handleSelectedExpense = (account) => {
    formik.setFieldValue("AccountID", account?.AccountID);
    formik.setFieldValue("Description", account?.Description);
    setShowJournalPopover(false);
  };

  eventBus.useCustomEventListener("JOURNAL_ACCOUNT_CREATED", (account) => {
    handleSelectedExpense(account);
  });

  eventBus.useCustomEventListener("CUSTOMER_CREATED", (vendor) => {
    handleSelectedVendor(vendor);
  });

  const handleSelectedVendor = (vendor) => {
    formik.setFieldValue("Vendor_ID", vendor.Vendor_ID);
    formik.setFieldValue("vendorName", vendor.CompanyName);
    setShowVendorPopover(false);
  };

  return (
    <Modal
      show={showOutsourceBatchToVendorModal}
      onHide={() => {
        setSelectedBatchItem(null);
        setShowOutsourceBatchToVendorModal(false);
      }}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h5">
            Outsource Batch - {selectedBatchItem.ItemName}/
            {selectedBatchItem?.Bar_Code}
          </h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Vendor</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                placeholder="Select Vendor"
                name="vendorName"
                value={formik.values.vendorName}
                onChange={() => { }}
                readOnly
                isInvalid={
                  formik.touched.vendorName && !!formik.errors.vendorName
                }
              />
              <Popover
                isOpen={showVendorPopover}
                onClickOutside={() => setShowVendorPopover(false)}
                content={() => (
                  <VendorSelector
                    handleSelectedCustomer={handleSelectedVendor}
                    setShowCustomerSelector={showVendorPopover}
                  />
                )}
                position="bottom"
              >
                <InputGroup.Text
                  onClick={() => setShowVendorPopover(!showVendorPopover)}
                >
                  <MagnifyIcon />
                </InputGroup.Text>
              </Popover>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Amount</Form.Label>

            <CurrencyCustomInput
              name="Amount"
              value={formik.values.Amount}
              onValueChange={(value, name) => {
                formik.setFieldValue(name, value);
              }}
              isInvalid={formik.touched.Amount && !!formik.errors.Amount}
            />
            {formik.touched.Amount && !!formik.errors.Amount ? (
              <span className="custom-invalid-feedback">
                {formik.errors.Amount}
              </span>
            ) : null}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>GL Account</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                name="Description"
                placeholder="Select Account"
                value={formik.values.Description}
                onChange={() => { }}
                readOnly
                isInvalid={
                  formik.touched.Description && !!formik.errors.Description
                }
              />
              <Popover
                isOpen={showJournalPopover}
                onClickOutside={() => setShowJournalPopover(false)}
                content={() => (
                  <ExpensesSelector
                    handleSelectedExpense={handleSelectedExpense}
                    usage={""}
                  // onHide={() => setShowJournalPopover(false)}
                  />
                )}
                position="bottom"
              >
                <InputGroup.Text onClick={() => setShowJournalPopover(true)}>
                  <MagnifyIcon />
                </InputGroup.Text>
              </Popover>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Remark</Form.Label>
            <Form.Control
              as={"textarea"}
              name="Remark"
              placeholder="Enter Remark"
              value={formik.values.Remark}
              onChange={formik.handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Date</Form.Label>
            <Datetime
              timeFormat={false}
              closeOnSelect={true}
              closeOnClickOutside={true}
              dateFormat="MMM DD, YYYY"
              name="salesDate"
              inputProps={{
                className: `date-input form-control ${formik.touched.salesDate && !!formik.errors.salesDate
                    ? "is-invalid"
                    : ""
                  }`,
                placeholder: "Select date",
                readOnly: true,
              }}
              value={formik.values.salesDate}
              onChange={(date) => {
                formik.setFieldValue("salesDate", date, true);
              }}
              onBlur={() => formik.setFieldTouched("salesDate", true)}
            />
          </Form.Group>

          <Button
            disabled={outsourceMutation.isLoading}
            variant="primary"
            className="w-100 p-2"
            type="submit"
          >
            {outsourceMutation.isLoading ? "Please wait…" : "Post"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
