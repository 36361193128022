import { Modal, Form } from "react-bootstrap";
import "./../assets/scss/new-entity-modal.scss";
import { useMutation } from "react-query";
import { useState, useEffect } from "react";
import { format } from "date-fns";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { paginationOptions } from "../utils/helpers";

import { useAuth } from "../hooks/useAuth";
import { fetchActionsUtil } from "../utils/helpers";
import NoTableItem from "./utils/NoTableItem";
import PreviewFormOnlyComponent from "./Requisition/PreviewFormOnlyComponent";
import ConfirmDialog from "./ConfirmDialogue";
import FillFormModal from "./Requisition/FillFormModal";
import { ApproveCheckIcon } from "./Icons";
import NewCustomRequisitionModal from "./Requisition/NewCustomRequisitionModal";

export default function VendorRFQChildFormsModal({
  childForms,
  showchildForm,
  setShowChildForm,
  selectedVendors,
  setShowForms,
  name,
  mainFormID,
  showSend,
  getselectedForm,
  purchaseOrder,
  childCount,
  motherForm,
  showReuisition,
  okay,
}) {
  const { backendUrl, token } = useAuth();
  const [selectedFilledForm, setSelectedFilledForm] = useState({});
  const [fetchingIframe, setFetchingIframe] = useState(false);
  const [openFillForm, setOpenFillForm] = useState(false);
  // const [CHforms, setCHforms] = useState(childForms);
  const [CHforms, setCHforms] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [searchData, setSearchData] = useState("");
  const [queryParams, setQueryParams] = useState({
    limit: 12,
    page: 1,
    count: childCount ? childCount : 0,
  });
  const [openRequisition, setOpenRequisition] = useState(false);
  const [sendType, setSendType] = useState("");

  const createRFQMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/vendors/send-RFQ`,
        "POST",
        token,
        payload
      ),
    {
      onSuccess: ({ message }) => {
        toast.success("Sent Successfully");
        setShowForms(false);
        setShowChildForm(false);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const createPOMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/vendors/send-direct-po`,
        "POST",
        token,
        payload
      ),
    {
      onSuccess: ({ message }) => {
        toast.success("Sent Successfully");
        setShowForms(false);
        setShowChildForm(false);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const payload = {
    FormID: selectedFilledForm.FormID,
    childFormId: selectedFilledForm.id,
    formName: selectedFilledForm.formName,
    selectedVendors,
  };

  const sendRFQ = async () => {
    if (motherForm?.approvalType === "Required") {
      setSendType("RFQ");
      setOpenRequisition(true);
    } else {
      if (
        await ConfirmDialog({
          title: `Send ${name}`,
          description: `Are you sure you want to send this ${name}`,
        })
      ) {
        payload.approvalType = motherForm?.approvalType;
        createRFQMutation.mutate(payload);
      }
    }
  };

  const sendPO = async () => {
    if (motherForm?.approvalType === "Required") {
      setSendType("PO");
      setOpenRequisition(true);
    } else {
      if (
        await ConfirmDialog({
          title: `Send ${name}`,
          description: `Are you sure you want to send this ${name}`,
        })
      ) {
        payload.approvalType = motherForm?.approvalType;
        createPOMutation.mutate(payload);
      }
    }
  };

  const sendAfterRequisition = async (returnData) => {
    if (sendType !== "") {
      payload.requestid = returnData.requestid;
      payload.requestStatus = "Open";
      payload.approvalType = "Required";
      sendType === "PO" && createPOMutation.mutate(payload);
      sendType === "RFQ" && createRFQMutation.mutate(payload);
    } else if (showReuisition) {
      selectedFilledForm.requestid = returnData.requestid;
      selectedFilledForm.requestStatus = "Open";
      selectedFilledForm.approvalType = "Required";
      getselectedForm &&
        getselectedForm({
          ...selectedFilledForm /*
          approvalType:
            motherForm?.approvalType === "Required" ? "Required" : "None", */,
        });
      getselectedForm && setOpenFillForm(false);
      getselectedForm && setShowChildForm(false);
    }
  };

  const setthefilledForm = (el) => {
    setCHforms((d) => d.concat(el));
    setSelectedFilledForm(el);
    if (motherForm?.approvalType !== "Required") {
      getselectedForm &&
        getselectedForm({
          ...el,
        });
    }
  };

  useEffect(() => {
    const timer = setTimeout(async () => {
      const children = await fetchActionsUtil(
        `${backendUrl}/api/forms/filled-forms?FormID=${mainFormID}&searchData=${searchData}&limit=${queryParams.limit
        }&page=${queryParams.page}`,
        "GET"
      );
      setCHforms(children.data.forms);
      setQueryParams({ ...queryParams, count: children.data.count });
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData, queryParams.limit, queryParams.page]);

  return (
    <>
      <Modal
        show={showchildForm}
        onHide={() => setShowChildForm(false)}
        centered={true}
        animation={false}
        enforceFocus={false}
        size="xl"
      >
        <Modal.Header
          className="d-flex justify-content-between gap-3"
          closeButton
        >
          <Modal.Title className="mx-4">
            <h5>Select {name} Forms</h5>
          </Modal.Title>

          {Object.entries(selectedFilledForm).length > 0 && showReuisition && (
            <button
              onClick={() => setOpenRequisition(true)}
              className="btn btn-outline-primary text-center"
            >
              Request Approval
            </button>
          )}

          {Object.entries(selectedFilledForm).length > 0 && showSend && (
            <button
              onClick={sendRFQ}
              className="btn btn-outline-primary text-center"
            >
              Send {name}
            </button>
          )}

          {Object.entries(selectedFilledForm).length > 0 && purchaseOrder && (
            <button
              onClick={sendPO}
              className="btn btn-outline-primary text-center"
            >
              Send {name}
            </button>
          )}

          <div>
            <button
              onClick={() => setOpenFillForm(true)}
              className="btn btn-primary text-center"
            >
              Create {name}
            </button>
          </div>

          <div>
            <Form.Control
              id="queryParams-q"
              className=""
              name="q"
              value={searchData}
              onChange={(e) => setSearchData(e.target.value)}
              placeholder="Search by name"
            />
          </div>

          {okay && (
            <button
              onClick={() => okay(selectedFilledForm)}
              className="btn btn-outline-primary text-center "
            >
              Select {name}
            </button>
          )}
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="filter-body row">
              {fetchingIframe ? (
                <div className="col-6">
                  <div className="row">
                    {CHforms?.length > 0 ? (
                      CHforms?.map((el) => (
                        <div className="col-md-4 mb-3" key={el.id}>
                          <div
                            className="card p-cursor h-100"
                            onClick={() => {
                              setSelectedIndex(el.id);
                              setSelectedFilledForm(el);
                              if (motherForm?.approvalType !== "Required") {
                                getselectedForm &&
                                  getselectedForm({
                                    ...el,
                                    approvalType:
                                      motherForm?.approvalType === "Required"
                                        ? "Required"
                                        : "None",
                                  });
                                getselectedForm && setOpenFillForm(false);
                                getselectedForm && setShowChildForm(false);
                              }
                            }}
                          >
                            <div className="bg-white border-0 p-2 pb-0 d-flex justify-content-between align-items-top card-header position-relative">
                              <small className="text-light">
                                Created:{" "}
                                {el?.Date_Log
                                  ? format(new Date(el.Date_Log), "dd MMM, yyyy")
                                  : "..."}
                              </small>
                              <div className="d-flex align-items-center p-2 position-absolute top-0 end-0">
                                {selectedIndex === el.id && (
                                  <ApproveCheckIcon />
                                )}
                              </div>
                            </div>

                            <div className="card-body p-2 pt-2">
                              <div className="card-subtitle mb-2 text-muted fw-normal">
                                {el.UserName || "..."}
                              </div>
                              <div className="fw-bolder card-title h5">
                                {el.formName || "..."}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <NoTableItem />
                    )}
                  </div>
                </div>
              ) : (
                <div className="text-center text-primary">
                  <b>Loading...</b>
                </div>
              )}
              <div className="col-6">
                <PreviewFormOnlyComponent
                  selectedFilledForm={selectedFilledForm}
                  template={selectedFilledForm?.filledFormData}
                  setFetchingIframe={setFetchingIframe}
                  size="30rem"
                  canFill={false}
                  showTableCheckbox={false}
                />
              </div>
            </div>
          </div>
        </Modal.Body>

        {queryParams.count > 0 && (
          <div className="d-flex justify-content-between px-4 align-items-center pagination pb-4 ">
            <div className="pagination_left d-flex gap-3 align-items-center">
              <p className="m-0 p-0">Show</p>
              <select
                value={queryParams.limit}
                name="limit"
                className="form-select "
                onChange={(e) =>
                  setQueryParams({ ...queryParams, limit: e.target.value })
                }
              >
                <option value="10">10 rows</option>
                <option value="20">20 rows</option>
                <option value="30">30 rows</option>
                <option value="40">40 rows</option>
              </select>
            </div>

            <ReactPaginate
              {...paginationOptions}
              pageCount={Math.ceil(queryParams.count / queryParams.limit)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={0}
              onPageChange={({ selected }) => {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                setQueryParams({
                  ...queryParams,
                  page: selected + 1,
                });
              }}
              forcePage={queryParams.page - 1}
            />
          </div>
        )}
      </Modal>

      {openFillForm && (
        <FillFormModal
          openFilledForm={openFillForm}
          setOpenFilledForm={setOpenFillForm}
          id={childForms[0]?.FormID}
          name={name}
          setthefilledForm={setthefilledForm}
          mainFormID={mainFormID}
        />
      )}
      {openRequisition && (
        <NewCustomRequisitionModal
          template={selectedFilledForm.filledFormData}
          setShowNewCustomRequisitionModal={setOpenRequisition}
          sendAfterRequisition={sendAfterRequisition}
          FormID={mainFormID}
          childFormId={selectedFilledForm.id}
        />
      )}
    </>
  );
}
