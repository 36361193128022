import {
  Table,
  InputGroup,
  Button,
  Dropdown,
  Form,
  Badge,
} from "react-bootstrap";
import {
  AddCircleIcon,
  CalendarIcon,
  CubeIcon,
  DeleteIcon,
  EditIcon,
  ExportIcon,
  FIleUploadLineIcon,
  FilterCollapseIcon,
  PrintIcon,
} from "./../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import DateRangePicker from "./../utils/DateRangePicker";
import CachedIcon from "mdi-react/CachedIcon";
import {
  FilterTwoIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
} from "./../Icons";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import { queryActions, reportActions } from "./../../utils/reactQueryActions";
import useDebounce, {
  useEffectOnce,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "./../../utils/hooks";
import queryString from "query-string";
import { services } from "./../../config";
import { useMutation, useQuery, useQueryClient } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  damageStatusOptions,
  defaultSelectValue,
  formatForQty,
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
  scrollToTop,
  tonsToPcs,
} from "./../../utils/helpers";
import RsDateRangePicker from "./../utils/RsDateRangePicker";
import { useAuth } from "./../../hooks/useAuth";
import UpdateItemModal from "./../UpdateItemModal";
import NewItemModal from "./../NewItemModal";
import PurchaseDialog from "./../PurchaseDialog";
import NewItemServiceModal from "./../NewItemServiceModal";
import NewItemModalWrapper from "./../NewItemModalWrapper";
import NewVendorModal from "./../NewVendorModal";
import BatchesModal from "./BatchesModal";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import LoginDialog from "./../LoginDialog";
import ConfirmDialog from "./../ConfirmDialogue";
import { toast } from "react-toastify";
import CubeOutline from "mdi-react/CubeOutlineIcon";
import { Link } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import Select from "react-select";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import ItemsTable from "./../utils/ItemsTable";
import DamageItemModal from "./DamageItemModal";
import UpdateDamageItemModal from "./UpdateDamagedItemModal";
import NumberCustomInput from "../utils/NumberCustomInput";
import { forwardRef } from "react";
import { useFormik } from "formik";
import { useImperativeHandle } from "react";
import { useMemo } from "react";
import { createRef } from "react";
import { useIsAdmin, useIsCashier } from "../../utils/hooks";
import DamagesAccountModal from "../DamageAccountModal";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import { ExcelIcon } from "./../Icons";
import { CSVLink } from "react-csv";
import ModalLoader from "../utils/ModalLoader";
import { cloneDeep } from "lodash";
import { isEmpty } from "lodash";
import { uniqBy } from "lodash";

const UpdateBatchTonsInline = forwardRef(
  (
    { getDataForEdit, desc, itemMeasurements, item, handleChangeQuantity },
    _ref
  ) => {
    const initialValues = {
      Tons: "",
      Pieces: "",
      formatType: "",
    };

    const formik = useFormik({
      initialValues,

      onSubmit: async (values) => {
        try {
          const tons =
            values?.Tons && values.formatType !== "default"
              ? tonsToPcs(values?.Tons, desc, itemMeasurements)
              : 0;

          let Quantity = Number(
            currency(tons, { symbol: "", separator: "" })
              .add(values.Pieces)
              .format()
          );
          handleChangeQuantity(item, Quantity);
        } catch (err) {
          console.log(err);
        }
      },
    });

    const quantityToPcs = (values) => {
      const tons =
        values?.Tons && values.formatType !== "default"
          ? tonsToPcs(values?.Tons, desc, itemMeasurements)
          : 0;

      let Quantity = Number(
        currency(tons, { symbol: "", separator: "" })
          .add(values.Pieces)
          .format()
      );
      return Quantity;
    };

    const setUp = () => {
      const { Tons, Pieces, formatType } = getDataForEdit();
      //console.log(Tons, Pieces, formatType);

      formik.setValues({
        Tons,
        Pieces,
        formatType,
      });
    };

    useEffectOnce(() => {
      setUp();
    });

    useImperativeHandle(_ref, () => ({
      getQuantity: () => quantityToPcs(formik.values),
    }));

    return (
      <div
        id={item.ID}
        /* noValidate */
        className="d-flex gap-2"
      /*  onSubmit={formik.handleSubmit} */
      >
        {formik.values.formatType !== "normal" ? (
          <>
            <Form.Group className="mb-3">
              <Form.Label>Tons</Form.Label>
              <NumberCustomInput
                type="text"
                placeholder=""
                name="Tons"
                id={`${item.ID}-Tons`}
                value={formik.values.Tons}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                onKeyDown={(e) => e.keyCode === 13 && formik.submitForm()}
                style={{ width: "5rem" }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Pieces</Form.Label>
              <NumberCustomInput
                type="text"
                placeholder=""
                name="Pieces"
                id={`${item.ID}-Pieces`}
                value={formik.values.Pieces}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                onKeyDown={(e) => e.keyCode === 13 && formik.submitForm()}
                style={{ width: "5rem" }}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.Pieces}
              </Form.Control.Feedback>
            </Form.Group>
          </>
        ) : (
          <Form.Group className="mb-3">
            <Form.Label>Quantity</Form.Label>
            <NumberCustomInput
              type="text"
              placeholder=""
              name="Pieces"
              value={formik.values.Pieces}
              onValueChange={(value, name) => {
                formik.setFieldValue(name, value);
              }}
              onKeyDown={(e) => e.keyCode === 13 && formik.submitForm()}
              isInvalid={formik.touched.Pieces && !!formik.errors.Pieces}
              style={{ width: "5rem" }}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.Pieces}
            </Form.Control.Feedback>
          </Form.Group>
        )}
      </div>
    );
  }
);

export default function DamagesReturnRecorder() {
  const isAdmin = useIsAdmin();
  const isCasher = useIsCashier();
  const queryClient = useQueryClient();
  const { backendUrl, isIronRod, isCement } = useAuth();
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showUpdateDamageItemModal, setShowUpdateDamageItemModal] = useState(
    false
  );
  const [showDamagesAccount, setShowDamagesAccount] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const generalSettings = useStoreState((state) => state?.generalSettings);
  useScrollTop();

  const [showDamageItemModal, setShowDamageItemModal] = useState(false);

  const initialFilterParams = {
    page: 1,
    limit: 40,
    barcode: "",
    itemName: "",
    withItemData: true,
  };
  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...queryParams,
    ...initialFilterParams,
  });
  const [selectedFaultItem, setSelectedFaultItem] = useState(false);

  // Excel Things
  const [excelData, setExcelData] = useState([]);
  const CSVLinkRef = useRef(null);
  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const fetchItems = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/items/damages?&${queryString.stringify(queryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.items = uniqBy(data.items, "FaultID");
    return data;
  };

  const { data = { count: 0, items: [] }, refetch } = useQuery(
    [queryActions.GET_DAMAGES, queryParams],
    () => fetchItems(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const handleItemToEdit = (item) => {
    setSelectedFaultItem({ ...item });
  };

  //-----------------------------
  useEffect(() => {
    if (selectedFaultItem) setShowUpdateDamageItemModal(true);
  }, [selectedFaultItem]);

  const deleteItem = async (payload) => {
    let response = await fetch(`${backendUrl}/api/items/delete`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteMutation = useMutation((payload) => deleteItem(payload), {
    onSuccess: ({ message, data: { item } }) => {
      toast.success(message);

      queryClient.setQueryData([queryActions.ITEMS, queryParams], (data) => {
        data.items = data.items.filter((el) => el.Bar_Code !== item.Bar_Code);
        return data;
      });
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams({
      ...initialFilterParams,
    });
  };

  const handleSelectedItem = (item) => {
    setFilterParams({
      ...filterParams,
      barcode: item.Bar_Code,
      itemName: item.Item_Name,
    });
    setShowItemsPopover(false);
  };

  const refsById = useMemo(() => {
    const refs = {};
    for (let el of data?.items) {
      refs[el.FaultID] = createRef(null);
    }

    return refs;
  }, [data?.items]);

  const getDataForEdit = ({ qty, desc, itemMeasurements = [] }) => {
    try {
      qty = qty <= 0 ? 0 : qty;
      const tonsAndPcs = qtyFormat(qty, desc, itemMeasurements);
      const Tons = tonsAndPcs.split("-")[0];
      const Pieces = tonsAndPcs.split("-")[1];
      const formatType = tonsAndPcs.split("-")[2];

      // console.log(tonsAndPcs, desc, qty);

      return {
        Tons,
        Pieces,
        formatType,
      };
    } catch (err) {
      console.log(err);
      return {};
    }
  };

  const handleChangeQuantity = async (damage, Quantity) => {
    Quantity = currency(Quantity, { symbol: "", separator: "" }).format();

    if (Number(Quantity) <= 0) {
      return toast.error("Please enter a valid balance to return");
    }

    const balanceLeftToReturn = currency(damage.Quantity, {
      symbol: "",
      separator: "",
      precision: 2,
      format: formatForQty,
    })
      .subtract(damage.QuantityReplaced)
      .format();

    if (Number(Quantity) > Number(balanceLeftToReturn)) {
      return toast.error("Quantity to return cannot be higher than balance");
    }

    if (
      await ConfirmDialog({
        title: "Replaced by vendor",
        description: "Are you sure you want to return this item ?",
      })
    ) {
      //
    }
  };

  const onDownloadExcelData = async () => {
    const { limit, page, ...rest } = queryParams;
    let exData = await fetchExcelData(
      `${backendUrl}/api/items/damages?${queryString.stringify(rest)}`,
      "GET"
    );

    exData = exData?.data?.items?.map((d, i) => [
      data?.startIndex + i + 1,
      d.FaultID,
      d.Barcode,
      d.Item_Name,
      isIronRod && d.itemData && d.Quantity
        ? qtyFormatToString(
          qtyFormat(d.Quantity, d.itemData.Item_Desc, itemMeasurements)
        )
        : currency(d.Quantity, {
          symbol: "",
          precision: 2,
          format: formatForQty,
        }).format(),
      isIronRod && d.itemData && d.QuantityReplaced
        ? qtyFormatToString(
          qtyFormat(
            d.QuantityReplaced,
            d.itemData.Item_Desc,
            itemMeasurements
          )
        )
        : currency(d.QuantityReplaced, {
          symbol: "",
          precision: 2,
          format: formatForQty,
        }).format(),
      d?.Vendor,
      d?.DamageStatus,
      d?.Faults,
      d?.EntryDate ? format(new Date(d.EntryDate), "dd MMM, yyyy") : "...",
    ]);

    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
          new Date(rest.startDate),
          "E MMM d yyyy k:mm:ss z"
        )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      ["Damage Log Report"],
      [date],
      [""],
      [
        "S/N",
        "Fault ID",
        "Item Code",
        "Item Name",
        "QTY",
        "QTY Replaced",
        "Vendor",
        "Status",
        "Faults",
        "Entry Date",
      ],
      ...exData,
      [""],
    ];
    console.log(exData);
    setExcelData(exData);
  };

  return (
    <section className="cashbook-wrapped">
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className={`filter-area ${showFilter ? "open" : "close"}`}>
              <div className="filter-header">
                <h2>
                  <FilterTwoIcon /> Filters
                </h2>

                <Button
                  variant="white"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <FilterCollapseIcon />
                </Button>
              </div>
              <div className="filter-body">
                <Form>
                  <div className="body">
                    <Form.Group className="mb-2-5">
                      <Form.Label>Item Code</Form.Label>

                      <InputGroup>
                        <Form.Control
                          name="barcode"
                          placeholder="Enter Item Code"
                          value={filterParams?.barcode}
                          onChange={(e) => handleFilterParamsChange(e)}
                        />

                        <Popover
                          isOpen={showItemsPopover}
                          onClickOutside={() => setShowItemsPopover(false)}
                          content={() => (
                            <ItemsTable
                              handleSelectedItem={handleSelectedItem}
                            />
                          )}
                          position="bottom"
                        >
                          <InputGroup.Text
                            onClick={() =>
                              setShowItemsPopover(!showItemsPopover)
                            }
                          >
                            <MagnifyIcon />
                          </InputGroup.Text>
                        </Popover>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-2-5">
                      <Form.Label>Item Name</Form.Label>
                      <Form.Control
                        name="itemName"
                        value={filterParams?.itemName}
                        placeholder="Enter Item Name"
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Status</Form.Label>
                      <Select
                        classNamePrefix={"form-select"}
                        options={damageStatusOptions}
                        value={damageStatusOptions.find(
                          (el) => el.value === filterParams.DamageStatus
                        )}
                        onChange={({ value }) =>
                          setFilterParams({
                            ...filterParams,
                            DamageStatus: value,
                          })
                        }
                        isSearchable={false}
                        menuPosition="fixed"
                      />
                    </Form.Group>

                    <hr className="mt-3 mb-4" />
                    <Form.Group className="">
                      <Form.Label>Date Range</Form.Label>
                      <div className="position-relative">
                        <RsDateRangePicker
                          placement="topStart"
                          value={
                            filterParams.startDate && filterParams.endDate
                              ? [
                                parse(
                                  filterParams.startDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                                parse(
                                  filterParams.endDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                              ]
                              : []
                          }
                          onClean={() => clearDateRange()}
                          onOk={(date) => filterByDateRange(date)}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="buttons rounded">
                    <Button onClick={() => reset()} variant="white">
                      Reset
                    </Button>
                    <Button onClick={() => search()} variant="primary">
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            <div className="content-body">
              <header>
                <h1>
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  All Damages / Return
                  <button
                    onClick={() => refetch()}
                    className="btn text-primary"
                  >
                    <CachedIcon />
                  </button>
                </h1>
                <div className="actions mr-5">
                  {/*  {isAdmin || isCasher ? (
                    <button
                      className="btn btn-primary mx-3"
                      onClick={() => setShowDamagesAccount(true)}
                    >
                      Map damages to account
                    </button>
                  ) : null} */}
                  <CSVLink
                    className="btn print d-none"
                    filename={`Damage Log Report(${format(
                      new Date(),
                      "dd-MMM-yyyy hh:mm:ss a"
                    )}).csv`}
                    data={excelData}
                    ref={CSVLinkRef}
                  />

                  {!isEmpty(selectedList) && (
                    <a
                      className="btn btn-light-blue"
                      target="_blank"
                      href={`${backendUrl}/api/items/pdf/damage-report?faultIds=${selectedList.join(
                        ","
                      )}`}
                    >
                      <PrintIcon /> Generate Damage Report{" "}
                      <Badge>{selectedList?.length}</Badge>
                    </a>
                  )}

                  <button
                    onClick={() => setShowDamageItemModal(true)}
                    className="btn sendSms"
                  >
                    Add Damaged / Returned Items <AddCircleIcon />
                  </button>

                  <button
                    onClick={() => setShowUpdateDamageItemModal(true)}
                    className="btn btn-outline-primary"
                  >
                    Update Damaged / Returned Items
                  </button>

                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      className="btn print"
                      disabled={isfetchingExcel}
                      bsPrefix=""
                    >

                      <ExportIcon color="#008000" />Export
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      popperConfig={{
                        strategy: "fixed",
                      }}
                      renderOnMount
                      className="text-center"
                    >
                      <Dropdown.Item
                        as="div"
                        onClick={onDownloadExcelData}
                        className="p-cursor"
                      >
                        Excel <ExcelIcon color="#008000" />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </header>

              <div className="px-md-4">
                <Table responsive borderless striped className="product-table">
                  <thead>
                    <tr>
                      <th />
                      <th />
                      <th>Fault ID</th>
                      {generalSettings?.itemsRequiresSerialNumber ? (
                        <th>Serial Number</th>
                      ) : null}
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>QTY</th>
                      <th>QTY Replaced</th>

                      <th>Vendor</th>
                      <th>Status</th>
                      <th>Reasons for Return</th>
                      <th>Entry Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.items.map((el, index) => (
                      <tr
                        key={el?.FaultID}
                        style={
                          !["Replaced By Vendor", "Active"].includes(
                            el.DamageStatus
                          ) ||
                            Number(el.Quantity) === Number(el.QuantityReplaced)
                            ? {
                              pointerEvents: "none",
                              opacity: 0.5,
                            }
                            : {}
                        }
                      >
                        <td style={{ pointerEvents: "unset", opacity: 1 }}>
                          <Form.Check
                            checked={selectedList.includes(el?.FaultID)}
                            onChange={(e) => {
                              // console.log(e.target.checked);
                              if (!e.target.checked) {
                                setSelectedList(
                                  cloneDeep(
                                    selectedList.filter(
                                      (selected) => selected !== el?.FaultID
                                    )
                                  )
                                );
                              } else {
                                setSelectedList((oldData) =>
                                  cloneDeep([...oldData, el?.FaultID])
                                );
                              }
                            }}
                          />
                        </td>
                        <td>
                          <Button
                            variant="white"
                            onClick={() => handleItemToEdit(el)}
                          >
                            <EditIcon />
                          </Button>
                        </td>
                        <td>{el?.FaultID}</td>
                        {generalSettings?.itemsRequiresSerialNumber ? (
                          <td>{el?.serialNum || "..."}</td>
                        ) : null}
                        <td>{el?.Barcode}</td>
                        <td>{el?.Item_Name}</td>

                        <td className="p-cursor" title={el.Quantity}>
                          {isIronRod && el.itemData && el.Quantity
                            ? qtyFormatToString(
                              qtyFormat(
                                el.Quantity,
                                el.itemData.Item_Desc,
                                itemMeasurements
                              )
                            )
                            : currency(el.Quantity, {
                              symbol: "",
                              precision: 2,
                              format: formatForQty,
                            }).format()}
                        </td>
                        <td
                          className="p-cursor text-nowrap"
                          title={el.QuantityReplaced}
                        >
                          {isIronRod && el.itemData && el.QuantityReplaced
                            ? qtyFormatToString(
                              qtyFormat(
                                el.QuantityReplaced,
                                el.itemData.Item_Desc,
                                itemMeasurements
                              )
                            )
                            : currency(el.QuantityReplaced, {
                              symbol: "",
                              precision: 2,
                              format: formatForQty,
                            }).format()}
                        </td>
                        {/*  <td>
                          <UpdateBatchTonsInline
                            ref={refsById[el.FaultID]}
                            key={el.FaultID}
                            getDataForEdit={() =>
                              getDataForEdit({
                                qty: currency(el?.Quantity, {
                                  symbol: "",
                                  separator: "",
                                precision: 2,
                                      format: formatForQty,
                                })
                                  .subtract(el?.QuantityReplaced)
                                  .format(),
                                desc: el.itemData.Item_Desc,
                                itemMeasurements,
                              })
                            }
                            item={el}
                            desc={el.itemData.Item_Desc}
                            itemMeasurements={itemMeasurements}
                            handleChangeQuantity={handleChangeQuantity}
                          />
                        </td> */}
                        <td className="text-nowrap">{el?.Vendor}</td>
                        <td className="text-nowrap">{el?.DamageStatus}</td>
                        <td>{el.Faults}</td>
                        <td className="text-nowrap">
                          {el?.EntryDate
                            ? format(new Date(el.EntryDate), "dd MMM, yyyy")
                            : "..."}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              <div className="d-flex justify-content-between px-3 align-items-center pagination">
                {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                <div className="pagination_left">
                  <p className="m-0 p-0">Show</p>
                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={(e) => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                    <option value="50">50 rows</option>
                    <option value="100">100 rows</option>
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={Math.ceil(data.count / queryParams.limit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    scrollToTop();
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                  }}
                  forcePage={queryParams.page - 1}
                />
              </div>
            </div>
          </div>
        </div>

        <ModalLoader title="Downloading Excel..." show={isfetchingExcel} />

        {showDamageItemModal && (
          <DamageItemModal
            showDamageItemModal={showDamageItemModal}
            setShowDamageItemModal={setShowDamageItemModal}
            refetch={refetch}
          />
        )}

        {showUpdateDamageItemModal && (
          <UpdateDamageItemModal
            showUpdateDamageItemModal={showUpdateDamageItemModal}
            setShowUpdateDamageItemModal={setShowUpdateDamageItemModal}
            setSelectedFaultItem={setSelectedFaultItem}
            selectedFaultItem={selectedFaultItem}
            refetch={refetch}
          />
        )}
        {showDamagesAccount && (
          <DamagesAccountModal
            showGeneralSettingsModal={showDamagesAccount}
            setShowGeneralSettingsModal={setShowDamagesAccount}
          />
        )}
      </main>
    </section>
  );
}
