import { Modal, Form, Button, Row, Table } from "react-bootstrap";
import * as yup from "yup";
import { useFormik, FormikProvider, FieldArray, ErrorMessage } from "formik";
import { Fragment, useMemo, useState } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import { useBackendUrl, useEffectOnce, useUnits } from "../utils/hooks";
import NumberCustomInput from "./utils/NumberCustomInput";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import {
  amountTypes,
  calculateMarkUp_Incentive_Margin,
  customerTypeOptions,
  fetchActionsUtil,
  qtyFormat,
  Units,
} from "../utils/helpers";
import { appSettings } from "../config";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { useStoreActions, useStoreState } from "easy-peasy";
import Select from "react-select";
import ItemSizeDialog from "./ItemSizeDialog";
import { useEffect } from "react";
import currency from "currency.js";
import CreatableSelect from "react-select/creatable";
import { useQuery, useQueryClient } from "react-query";
import { queryActions } from "../utils/reactQueryActions";
import { isEmpty, uniqBy } from "lodash";
import ConfirmDialog from "./ConfirmDialogue";

const itemsStatus = [
  {
    label: "Unprocessed",
    value: "Unprocessed",
  },
  {
    label: "Processed",
    value: "Processed",
  },
];

const onlineDiscountTypes = [
  {
    label: "Select",
    value: "",
  },
  {
    label: "Percentage",
    value: "Percentage",
  },
  {
    label: "Fixed",
    value: "Fixed",
  },
];

export default function ItemSaleAnalysisModal({
  showItemSaleAnalysisModal,
  selectedSale,
  setSelectedSale,
  setShowItemSaleAnalysisModal,
  refetch,
}) {
  const backendUrl = useBackendUrl();
  const { Units, getUnitLabelByValue } = useUnits();
  const itemMeasurements = useStoreState((state) =>
    state.itemMeasurements.map((el) => ({ label: el.Size, value: el.Size }))
  );

  const setItemMeasurements = useStoreActions(
    (actions) => actions.setItemMeasurements
  );

  const generalSettings = useStoreState((state) => state?.generalSettings);

  const setUpUI = async (department) => {
    let response = await fetch(
      `${backendUrl}/api/items/set-up?withVendorBalance=false&Bar_Code=${selectedSale.Bar_Code
      }`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    /*   const { storage } = await fetchActionsUtil(
      `${backendUrl}/api/items/storage-location`,
      "GET"
    );

    const cat = await fetchActionsUtil(
      `${backendUrl}/api/items/category?`,
      "GET"
    );

    const pro = await fetchActionsUtil(
      `${backendUrl}/api/items/product-name`,
      "GET"
    ); */

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    /*  data.vendors = data?.vendors
      .filter((el) => el.Vendor_ID)
      .map((el) => ({
        ...el,
        label: el.CompanyName,
        value: el.Vendor_ID,
      }));
    data.itemMeasurement = data?.itemMeasurement.map((el) => ({
      label: el.Size,
      value: el.Size,
    }));

    data.categories = cat.items.map((el) => ({
      value: el.Cat_Name,
      label: el.Cat_Name,
    }));

    data.products = pro.items.map((el) => ({
      value: el.Product_Name,
      label: el.Product_Name,
    }));

    data.customerTypes = uniqBy(
      [
        ...data.customerTypes
          .filter(
            (el) =>
              !customerTypeOptions.find(
                (defaultType) => defaultType.value === el.TransType
              )
          )
          .filter((el) => el.TransType)
          .map((el) => ({
            value: el.TransType,
            label: el.TransType,
          })),
      ],
      "value"
    );

    if (!isEmpty(data?.priceByCustomerTypeForItem)) {
      formik.setFieldValue(
        "priceByCustomerType",
        data?.priceByCustomerTypeForItem
      );
    }

    data.storage = storage?.map((el) => ({
      value: el.storageID,
      label: el.name,
    })); */

    // console.log(data);

    setUp({
      item: data.item,
    });
    return data;
  };
  const {
    data = {
      vendors: [],
      itemMeasurement: [],
      customerTypes: [],
    },
    refetch: refetchSetup,
    isFetching,
  } = useQuery([queryActions.ITEMS_SETUP], () => setUpUI(), {
    enabled: true,
    cacheTime: 1000 * 60 * 60,
  });

  const initialValues = {
    UnitPrice: 0, // walk / retail customer
    Vat_5: 0, // distributor
    Tax: 0, // unit price in pieces in gigc, wholeselectedSale in normal invex
    Unit: "Each",
    Size: "",

    //--------------
    Margin: 0,
    Incentive: 0,
    UnitCost: 0,

    //-------------
    pcsPriceDistributor: 0,

    //------
    Item_Name: "",
    Cat_Name: "",
    Product_Name: "",
    Reorder_Level: 5,
    Discount: 0,
    discountType: "Fixed",
    discountPercentage: 0,
    Reorder_Level: 0,
    marginType: "",
    marginPercentage: "",
    incentiveType: "",
    incentivePercentage: "",
    priceByCustomerType: [
      {
        TransType: "",
        UnitPrice: 0,
        UnitPricePcs: 0,
        Bar_Code: selectedSale.Bar_Code,
      },
    ],
    ItemStatus: "",
    MaximumLevel: 0,

    // --------
    showInStore: false,
    addDiscount: false,
    onlineDiscountType: "",
    onlineDiscountTypeInput: 0,
    onlineDiscountTotalAmount: 0,
    StorageLocation: "",
    markUp: 0,
    markUpType: "Fixed",
    markUpPercentage: "",
    Pack_Size: "",
    Generic_Configuration: "",
    Tier: "",
    Branded_Generic: "",
    currentMarketPrice: 0,
  };
  const schema = yup.object().shape({
    UnitPrice: yup.string().required("required"),
    Tax: yup.string().required("required"),
    Vat_5: yup.string().required("required"),
    Item_Name: yup.string().required("required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      /*  if (formik.values.addDiscount) {
        if (formik.values.onlineDiscountType === "") {
          return formik.setFieldError(
            "onlineDiscountType",
            "Discount Type is required"
          );
        }
        if (formik.values.onlineDiscountTypeInput <= 0) {
          return formik.setFieldError(
            "onlineDiscountTypeInput",
            "Percentage/Amount is required"
          );
        }
      }

      if (
        await ConfirmDialog({
          title: "Update Item",
          description: "Are you sure you want to update this item",
        })
      ) {
        updatePriceMutation.mutate({
          ...values,
          Bar_Code: selectedSale.Bar_Code,
        });
      } */
    },
  });

  useEffect(() => {
    let cost = formik.values.onlineDiscountTypeInput;
    if (formik.values.onlineDiscountType === "Percentage") {
      cost = currency(formik.values.onlineDiscountTypeInput)
        .divide(100)
        .multiply(formik.values.UnitPrice).value;

      formik.setFieldValue("onlineDiscountTotalAmount", cost);
    } else if (formik.values.onlineDiscountType === "Fixed") {
      formik.setFieldValue("onlineDiscountTotalAmount", cost);
    } else {
      formik.setFieldValue("onlineDiscountTotalAmount", 0);
      formik.setFieldValue("onlineDiscountTypeInput", 0);
    }
  }, [formik.values.onlineDiscountType, formik.values.onlineDiscountTypeInput]);

  const setUp = ({ item }) => {
    // console.log(selectedSale);
    formik.setValues({
      UnitPrice: item.UnitPrice,
      Tax: item.Tax,
      Vat_5: item.Vat_5,
      Unit: item.Product_Model,
      Size: item.Item_Desc,

      //----------------
      Margin: item.Margin,
      Incentive: item.Incentive,
      UnitCost: item.UnitCost,
      //-----------------
      pcsPriceDistributor: item?.pcsPriceDistributor || 0,
      //------------------=
      Item_Name: item.Item_Name,
      Product_Name: item.Product_Name,
      Cat_Name: item.Cat_Name,
      Reorder_Level: item.Reorder_Level,
      Discount: item.Discount,
      discountType: item.discountType,
      discountPercentage: item.discountPercentage,
      MaximumLevel: item.MaximumLevel,
      //---
      marginType: selectedSale?.marginType
        ? selectedSale?.marginType
        : item?.marginType,

      marginPercentage: selectedSale?.marginPercentage
        ? selectedSale?.marginPercentage
        : item?.marginPercentage,

      incentiveType: selectedSale?.incentiveType
        ? selectedSale?.incentiveType
        : item?.incentiveType,

      incentivePercentage: selectedSale?.incentivePercentage
        ? selectedSale?.incentivePercentage
        : item.incentivePercentage,

      priceByCustomerType: formik.values.priceByCustomerType,
      ItemStatus: item?.ItemStatus,
      // StorageLocation: selectedSale?.StorageLocation,

      // -------

      showInStore: Boolean(item?.showInStore),
      addDiscount: Boolean(item?.addDiscount),
      onlineDiscountType: item?.onlineDiscountType
        ? item?.onlineDiscountType
        : "",
      onlineDiscountTypeInput: item?.onlineDiscountTypeInput
        ? item?.onlineDiscountTypeInput
        : 0,
      onlineDiscountTotalAmount: item?.onlineDiscountTotalAmount
        ? item.onlineDiscountTotalAmount
        : 0,
      markUp: item?.markUp,
      markUpType: item?.markUpType,
      markUpPercentage: item?.markUpPercentage,
      Pack_Size: item?.Pack_Size,
      Generic_Configuration: item?.Generic_Configuration,
      Tier: item?.Tier,
      Branded_Generic: item?.Branded_Generic,
      currentMarketPrice: item?.currentMarketPrice,
      // -----------------
    });
  };

  const updatePrice = async (payload) => {
    let response = await fetch(`${backendUrl}/api/items/update-price`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const updatePriceMutation = useMutation((payload) => updatePrice(payload), {
    onSuccess: ({ message, data }) => {
      refetch();
      toast.success(message);
      setSelectedSale(null);
      setShowItemSaleAnalysisModal(false);
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const addItemMeasurement = async () => {
    const newSize = await ItemSizeDialog();
    if (newSize) {
      formik.setFieldValue("Size", newSize.Size);
      setItemMeasurements([...itemMeasurements, newSize]);
    }
  };

  //Incentive & Margin
  useEffect(() => {
    if (formik.values.markUpType === "Percentage") {
      formik.setFieldValue(
        "markUp",
        currency(formik.values.markUpPercentage)
          .divide(100)
          .multiply(formik.values.UnitCost).value
      );
    }

    if (formik.values.incentiveType === "Percentage") {
      formik.setFieldValue(
        "Incentive",
        currency(formik.values.incentivePercentage)
          .divide(100)
          .multiply(formik.values.markUp).value
      );
    }

    if (formik.values.marginType === "Percentage") {
      formik.setFieldValue(
        "Margin",
        currency(formik.values.marginPercentage)
          .divide(100)
          .multiply(formik.values.markUp).value
      );
    }
  }, [
    formik.values.UnitCost,
    formik.values.marginPercentage,
    formik.values.incentivePercentage,
    formik.values.marginType,
    formik.values.incentiveType,
    formik.values.markUp,
    formik.values.markUpType,
    formik.values.markUpPercentage,
  ]);

  useEffect(() => {
    if (appSettings.hasIncentive) {
      const UnitPrice = currency(formik.values.UnitCost)
        .add(formik.values.Incentive)
        .add(formik.values.Margin).value;

      formik.setFieldValue("UnitPrice", UnitPrice);
      formik.setFieldValue("Tax", UnitPrice);
      formik.setFieldValue("Vat_5", UnitPrice);
    }
  }, [formik.values.UnitCost, formik.values.Margin, formik.values.Incentive]);

  const batchesUsed = useMemo(() => {
    // console.log(formik.values.);

    return selectedSale?.batchesUsed
      ? JSON.parse(selectedSale?.batchesUsed).map((el) => {
        const {
          calculatedMarkUp,
          calculatedIncentive,
          calculatedMargin,
        } = calculateMarkUp_Incentive_Margin({
          UnitCost: el.UnitCost,
          PriceSold: selectedSale.PriceSold,
          Margin: formik.values.Margin,
          marginType: formik.values.marginType,
          marginPercentage: formik.values.marginPercentage,
          Incentive: formik.values.Incentive,
          incentiveType: formik.values.incentiveType,
          incentivePercentage: formik.values.incentivePercentage,
        });

        return {
          ...el,
          calculatedMarkUp,
          calculatedIncentive,
          calculatedMargin,
          unitCost: el.UnitCost,
          PriceSold: selectedSale.PriceSold,
          quantity: el.quantityUsed,
          totalUnitCost: currency(el.UnitCost).multiply(el.quantityUsed)
            .value,
          totalUnitPrice: currency(el.PriceSold).multiply(el.quantityUsed)
            .value,
          totalMargin: currency(calculatedMargin || 0).multiply(
            el.quantityUsed
          ).value,
          totalIncentive: currency(calculatedIncentive || 0).multiply(
            el.quantityUsed
          ).value,
          totalMarkUp: currency(calculatedMarkUp || 0).multiply(
            el.quantityUsed
          ).value,
          totalProfit: currency(selectedSale.PriceSold, { symbol: "" })
            .subtract(el.UnitCost)
            .subtract(calculatedIncentive)
            .multiply(el.quantityUsed).value,
        };
      })
      : [];
  }, [
    selectedSale?.batchesUsed,
    formik.values.Margin,
    formik.values.marginType,
    formik.values.marginPercentage,
    formik.values.Incentive,
    formik.values.incentiveType,
    formik.values.incentivePercentage,
  ]);

  const grandTotalMarkUp = useMemo(() => {
    return batchesUsed
      .map((el) => el.totalMarkUp)
      .reduce((a, b) => currency(a).add(b).value);
  }, [batchesUsed]);

  const grandTotalProfit = useMemo(() => {
    return batchesUsed
      .map((el) => el.totalProfit)
      .reduce((a, b) => currency(a).add(b).value);
  }, [batchesUsed]);

  const grandTotalIncentive = useMemo(() => {
    return batchesUsed
      .map((el) => el.totalIncentive)
      .reduce((a, b) => currency(a).add(b).value);
  }, [batchesUsed]);

  return (
    <Modal
      show={showItemSaleAnalysisModal}
      onHide={() => {
        setSelectedSale(null);
        setShowItemSaleAnalysisModal(false);
      }}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h5">Price Analysis - {selectedSale?.Bar_Code}</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          {" "}
          <div className="col-4">
            <FormikProvider value={formik}>
              <Form
                noValidate
                onSubmit={formik.handleSubmit}
                style={
                  isFetching ? { pointerEvents: "none", opacity: 0.5 } : {}
                }
              >
                <Form.Group className=" mb-3 pb-2">
                  <Form.Label className="mb-1">Price Setting</Form.Label> <br />
                  <Form.Label className="mb-1">Item Name</Form.Label>
                  <Form.Control
                    className=""
                    type="text"
                    placeholder="Enter Item Name"
                    name="Item_Name"
                    value={formik.values.Item_Name}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.Item_Name && !!formik.errors.Item_Name
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.Item_Name}
                  </Form.Control.Feedback>
                </Form.Group>

                {appSettings.hasIncentive && (
                  <>
                    <Form.Group className="mb-3 d-none">
                      <Form.Label>UnitCost</Form.Label>
                      <CurrencyCustomInput
                        type="text"
                        placeholder="Enter Unit Cost"
                        name="UnitCost"
                        value={formik.values.UnitCost}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value);
                        }}
                        isInvalid={
                          formik.touched.UnitCost && !!formik.errors.UnitCost
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.UnitCost}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {appSettings.hasIncentive ? (
                      <>
                        <Row className="d-none">
                          <Form.Group className="col-md-4 mb-3 pb-2">
                            <Form.Label className="mb-1">
                              Mark Up Type
                            </Form.Label>
                            <Select
                              classNamePrefix={"form-select"}
                              options={amountTypes}
                              value={amountTypes.find(
                                (el) => el.value === formik.values.markUpType
                              )}
                              onChange={(selected) =>
                                formik.setFieldValue(
                                  "markUpType",
                                  selected.value
                                )
                              }
                            />
                          </Form.Group>

                          {formik.values.markUpType === "Percentage" && (
                            <Form.Group className="col-md-3 mb-3 pb-2">
                              <Form.Label className="mb-1">
                                Mark Up (%)
                              </Form.Label>
                              <Form.Control
                                type="number"
                                name="markUpPercentage"
                                value={formik.values.markUpPercentage}
                                onChange={formik.handleChange}
                              />
                            </Form.Group>
                          )}

                          <Form.Group className="col-md-5 mb-3 pb-2">
                            <Form.Label className="mb-1">Mark Up</Form.Label>
                            <CurrencyCustomInput
                              name="markUp"
                              placeholder="0.00"
                              value={formik.values.markUp}
                              onValueChange={(value, name) => {
                                formik.setFieldValue(name, value);
                              }}
                              isInvalid={
                                formik.touched.markUp && !!formik.errors.markUp
                              }
                              onBlur={() =>
                                formik.setFieldTouched("markUp", true)
                              }
                              disabled={
                                formik.values.markUpType === "Percentage"
                              }
                            />
                            {formik.touched.markUp && !!formik.errors.markUp ? (
                              <span className="custom-invalid-feedback">
                                {formik.errors.markUp}
                              </span>
                            ) : null}
                          </Form.Group>
                        </Row>

                        <Row>
                          <Form.Group className="col-md-4 mb-3 pb-2">
                            <Form.Label className="mb-1">
                              Incentive Type
                            </Form.Label>
                            <Select
                              classNamePrefix={"form-select"}
                              options={amountTypes}
                              value={amountTypes.find(
                                (el) => el.value === formik.values.incentiveType
                              )}
                              onChange={(selected) =>
                                formik.setFieldValue(
                                  "incentiveType",
                                  selected.value
                                )
                              }
                            />
                          </Form.Group>

                          {formik.values.incentiveType === "Percentage" ? (
                            <Form.Group className="col-md-3 mb-3 pb-2">
                              <Form.Label className="mb-1 text-nowrap">
                                Incentive (%)
                              </Form.Label>
                              <Form.Control
                                type="number"
                                name="incentivePercentage"
                                value={formik.values.incentivePercentage}
                                onChange={formik.handleChange}
                              />
                            </Form.Group>
                          ) : (
                            <Form.Group className="col-md-5 mb-3 pb-2">
                              <Form.Label className="mb-1">
                                Incentive
                              </Form.Label>
                              <CurrencyCustomInput
                                name="Incentive"
                                placeholder="0.00"
                                value={formik.values.Incentive}
                                onValueChange={(value, name) => {
                                  formik.setFieldValue(name, value);
                                }}
                                isInvalid={
                                  formik.touched.Incentive &&
                                  !!formik.errors.Incentive
                                }
                                onBlur={() =>
                                  formik.setFieldTouched("Incentive", true)
                                }
                                disabled={
                                  formik.values.incentiveType === "Percentage"
                                }
                              />
                            </Form.Group>
                          )}
                        </Row>

                        <Row>
                          <Form.Group className="col-md-4 mb-3 pb-2">
                            <Form.Label className="mb-1">
                              Profit Type
                            </Form.Label>
                            <Select
                              classNamePrefix={"form-select"}
                              options={amountTypes}
                              value={amountTypes.find(
                                (el) => el.value === formik.values.marginType
                              )}
                              onChange={(selected) =>
                                formik.setFieldValue(
                                  "marginType",
                                  selected.value
                                )
                              }
                            />
                          </Form.Group>

                          {formik.values.marginType === "Percentage" ? (
                            <Form.Group className="col-md-3 mb-3 pb-2">
                              <Form.Label className="mb-1">
                                Profit (%)
                              </Form.Label>
                              <Form.Control
                                type="number"
                                name="marginPercentage"
                                value={formik.values.marginPercentage}
                                onChange={formik.handleChange}
                              />
                            </Form.Group>
                          ) : (
                            <Form.Group className="col-md-5 mb-3 pb-2">
                              {/*  <Form.Label className="mb-1">Profit</Form.Label>
                              <CurrencyCustomInput
                                name="Margin"
                                placeholder="0.00"
                                value={formik.values.Margin}
                                onValueChange={(value, name) => {
                                  formik.setFieldValue(name, value);
                                }}
                                isInvalid={
                                  formik.touched.Margin &&
                                  !!formik.errors.Margin
                                }
                                onBlur={() =>
                                  formik.setFieldTouched("Margin", true)
                                }
                                disabled={
                                  formik.values.marginType === "Percentage"
                                }
                              /> */}
                            </Form.Group>
                          )}
                        </Row>
                      </>
                    ) : null}
                  </>
                )}

                {formik.values.marginType === "Percentage" &&
                  formik.values.incentiveType === "Percentage" &&
                  Number(formik.values.marginPercentage) +
                  Number(formik.values.incentivePercentage) !=
                  100 ? (
                  <span className="text-danger">
                    Percentage not equal to 100{" "}
                  </span>
                ) : null}

                <Form.Group className="mb-3 d-none">
                  <Form.Label>
                    {selectedSale.Product_Model === "Tons"
                      ? `${getUnitLabelByValue(`Tonage`)} Price`
                      : `UnitPrice (Retail / Walk In)`}
                  </Form.Label>

                  <CurrencyCustomInput
                    type="text"
                    name="UnitPrice"
                    value={formik.values.UnitPrice}
                    onValueChange={(value, name) => {
                      formik.setFieldValue(name, value);
                    }}
                    isInvalid={
                      formik.touched.UnitPrice && !!formik.errors.UnitPrice
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.UnitPrice}
                  </Form.Control.Feedback>
                </Form.Group>

                {/*  <Form.Group className="col-md-5 mb-3 pb-2">
                  <Form.Label className="mb-1">Discount</Form.Label>
                  <CurrencyCustomInput
                    name="Discount"
                    placeholder="0.00"
                    value={formik.values.Discount}
                    onValueChange={(value, name) => {
                      formik.setFieldValue(name, value);
                    }}
                    isInvalid={
                      formik.touched.Discount && !!formik.errors.Discount
                    }
                    onBlur={() => formik.setFieldTouched("Discount", true)}
                  />
                </Form.Group> */}

                {/* <Button
              disabled={updatePriceMutation.isLoading}
              variant="primary"
              className="w-100 p-3"
              type="submit"
            >
              {updatePriceMutation.isLoading ? "Please wait…" : "Save Changes"}
            </Button> */}
              </Form>{" "}
            </FormikProvider>{" "}
          </div>
          <div className="col-8">
            {/*  {JSON.stringify(batchesUsed)} */}
            {/*  {batchesUsed.map((batchUsed, i) => (
              <Fragment key={i}>
                <Form.Group className=" mb-3 pb-2">
                  <Form.Label className="mb-1">Batch Sold</Form.Label>
                  <Form.Control
                    value={
                      batchUsed.Bar_Code
                        ? batchUsed.Bar_Code.split("-").pop()
                        : "..."
                    }
                    readOnly
                  />
                </Form.Group>

                <Form.Group className=" mb-3 pb-2">
                  <Form.Label className="mb-1">
                    {generalSettings.Batch_Name}
                  </Form.Label>
                  <Form.Control value={batchUsed.Batch_Name} readOnly />
                </Form.Group>

                <Form.Group className=" mb-3 pb-2">
                  <Form.Label className="mb-1">Quantity Sold</Form.Label>
                  <Form.Control value={batchUsed.quantityUsed} readOnly />
                </Form.Group>

                <>
                  <Form.Group className="mb-3">
                    <Form.Label>UnitCost</Form.Label>
                    <CurrencyCustomInput value={batchUsed.UnitCost} readOnly />
                  </Form.Group>

                  {appSettings.hasIncentive ? (
                    <>
                      <Row>
                        <Form.Group className="col-md-3 mb-3 pb-2">
                          <Form.Label className="mb-1">Mark Up Type</Form.Label>
                          <Select
                            classNamePrefix={"form-select"}
                            options={amountTypes}
                            value={amountTypes.find(
                              (el) => el.value === formik.values.markUpType
                            )}
                            onChange={(selected) =>
                              formik.setFieldValue("markUpType", selected.value)
                            }
                            isDisabled={true}
                          />
                        </Form.Group>

                        {formik.values.markUpType === "Percentage" && (
                          <Form.Group className="col-md-2 mb-3 pb-2">
                            <Form.Label className="mb-1">
                              Mark Up (%)
                            </Form.Label>
                            <Form.Control
                              type="number"
                              name="markUpPercentage"
                              value={formik.values.markUpPercentage}
                              onChange={formik.handleChange}
                            />
                          </Form.Group>
                        )}

                        <Form.Group className="col-md-3 mb-3 pb-2">
                          <Form.Label className="mb-1">Mark Up</Form.Label>
                          <CurrencyCustomInput
                            name="markUp"
                            placeholder="0.00"
                            value={selectedSale.markUp}
                            onValueChange={(value, name) => {
                              formik.setFieldValue(name, value);
                            }}
                            isInvalid={
                              formik.touched.markUp && !!formik.errors.markUp
                            }
                            onBlur={() =>
                              formik.setFieldTouched("markUp", true)
                            }
                            disabled={formik.values.markUpType === "Percentage"}
                          />
                          {formik.touched.markUp && !!formik.errors.markUp ? (
                            <span className="custom-invalid-feedback">
                              {formik.errors.markUp}
                            </span>
                          ) : null}
                        </Form.Group>

                        <Form.Group className="col-1 mb-3 pb-2">
                          <Form.Label className="mb-1">Qty</Form.Label>
                          <Form.Control
                            value={batchUsed.quantityUsed}
                            readOnly
                          />
                        </Form.Group>

                        <Form.Group className="col-3 mb-3 pb-2">
                          <Form.Label className="mb-1">
                            Total Mark Up
                          </Form.Label>
                          <CurrencyCustomInput
                            value={selectedSale.totalMarkUp}
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group className="col-md-3 mb-3 pb-2">
                          <Form.Label className="mb-1">Profit Type</Form.Label>
                          <Select
                            classNamePrefix={"form-select"}
                            options={amountTypes}
                            value={amountTypes.find(
                              (el) => el.value === formik.values.marginType
                            )}
                            onChange={(selected) =>
                              formik.setFieldValue("marginType", selected.value)
                            }
                            isDisabled={true}
                          />
                        </Form.Group>

                        {formik.values.marginType === "Percentage" && (
                          <Form.Group className="col-md-3 mb-3 pb-2">
                            <Form.Label className="mb-1">Profit (%)</Form.Label>
                            <Form.Control
                              type="number"
                              name="marginPercentage"
                              value={formik.values.marginPercentage}
                              onChange={formik.handleChange}
                            />
                          </Form.Group>
                        )}

                        <Form.Group className="col-md-4 mb-3 pb-2">
                          <Form.Label className="mb-1">Profit</Form.Label>
                          <CurrencyCustomInput
                            name="Margin"
                            placeholder="0.00"
                            value={formik.values.Margin}
                            onValueChange={(value, name) => {
                              formik.setFieldValue(name, value);
                            }}
                            isInvalid={
                              formik.touched.Margin && !!formik.errors.Margin
                            }
                            onBlur={() =>
                              formik.setFieldTouched("Margin", true)
                            }
                            disabled={formik.values.marginType === "Percentage"}
                          />
                          {formik.touched.Margin && !!formik.errors.Margin ? (
                            <span className="custom-invalid-feedback">
                              {formik.errors.Margin}
                            </span>
                          ) : null}
                        </Form.Group>

                        <Form.Group className="col mb-3 pb-2">
                          <Form.Label className="mb-1">
                            Quantity Sold
                          </Form.Label>
                          <Form.Control
                            value={batchUsed.quantityUsed}
                            readOnly
                          />
                        </Form.Group>
                      </Row>

                      <Row>
                        <Form.Group className="col-md-3 mb-3 pb-2">
                          <Form.Label className="mb-1">
                            Incentive Type
                          </Form.Label>
                          <Select
                            classNamePrefix={"form-select"}
                            options={amountTypes}
                            value={amountTypes.find(
                              (el) => el.value === formik.values.incentiveType
                            )}
                            onChange={(selected) =>
                              formik.setFieldValue(
                                "incentiveType",
                                selected.value
                              )
                            }
                            isDisabled={true}
                          />
                        </Form.Group>

                        {formik.values.incentiveType === "Percentage" && (
                          <Form.Group className="col-md-3 mb-3 pb-2">
                            <Form.Label className="mb-1 text-nowrap">
                              Incentive (%)
                            </Form.Label>
                            <Form.Control
                              type="number"
                              name="incentivePercentage"
                              value={formik.values.incentivePercentage}
                              onChange={formik.handleChange}
                            />
                          </Form.Group>
                        )}

                        <Form.Group className="col-md-4 mb-3 pb-2">
                          <Form.Label className="mb-1">Incentive</Form.Label>
                          <CurrencyCustomInput
                            name="Incentive"
                            placeholder="0.00"
                            value={formik.values.Incentive}
                            onValueChange={(value, name) => {
                              formik.setFieldValue(name, value);
                            }}
                            isInvalid={
                              formik.touched.Incentive &&
                              !!formik.errors.Incentive
                            }
                            onBlur={() =>
                              formik.setFieldTouched("Incentive", true)
                            }
                            disabled={
                              formik.values.incentiveType === "Percentage"
                            }
                          />
                          {formik.touched.Incentive &&
                          !!formik.errors.Incentive ? (
                            <span className="custom-invalid-feedback">
                              {formik.errors.Incentive}
                            </span>
                          ) : null}
                        </Form.Group>

                        <Form.Group className="col mb-3 pb-2">
                          <Form.Label className="mb-1">
                            Quantity Sold
                          </Form.Label>
                          <Form.Control
                            value={batchUsed.quantityUsed}
                            readOnly
                          />
                        </Form.Group>
                      </Row>
                    </>
                  ) : null}
                </>

                <Form.Group className="mb-3">
                  <Form.Label>UnitPrice</Form.Label>
                  <CurrencyCustomInput
                    value={selectedSale.Unit_Price}
                    readOnly
                  />
                </Form.Group>

                <Form.Group className="col-md-5 mb-3 pb-2">
                  <Form.Label className="mb-1">Discount</Form.Label>
                  <CurrencyCustomInput value={selectedSale.Discount} readOnly />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Price Sold</Form.Label>
                  <CurrencyCustomInput
                    value={selectedSale.PriceSold}
                    readOnly
                  />
                </Form.Group>

                <Form.Group className=" mb-3 pb-2">
                  <Form.Label className="mb-1">Storage Location</Form.Label>
                  <Form.Control value={batchUsed.storage_location} readOnly />
                </Form.Group>
              </Fragment>
            ))} */}

            <Table className="product-table" responsive striped borderless>
              <thead>
                <th>Batch Sold</th>
                <th>{generalSettings.Batch_Name}</th>
                <th>Quantity Sold</th>
                <th>Price Sold</th>
                <th>UnitCost</th>
                <th>Mark up</th>
                <th>Profit</th>
                <th>Incentive</th>
                <th>Unit Price</th>
                <th>Discount</th>
                <th>Price Sold</th> <th />
              </thead>
              {batchesUsed.map((batchUsed, i) => (
                <Fragment key={i}>
                  <tbody>
                    <tr>
                      <td>
                        {batchUsed.Bar_Code
                          ? batchUsed.Bar_Code.split("-").pop()
                          : "..."}
                      </td>
                      <td>{batchUsed.Batch_Name || "..."}</td>
                      <td>{batchUsed.quantityUsed}</td>{" "}
                      <td>
                        {currency(batchUsed.PriceSold, { symbol: "" }).format()}
                      </td>
                      <td>
                        {currency(batchUsed.UnitCost, {
                          symbol: "",
                        }).format()}
                      </td>
                      <td>
                        {currency(batchUsed.calculatedMarkUp, {
                          symbol: "",
                        }).format()}
                      </td>
                      <td>
                        {currency(batchUsed.PriceSold, { symbol: "" })
                          .subtract(batchUsed.UnitCost)
                          .subtract(batchUsed.calculatedIncentive)
                          .format()}
                      </td>
                      <td>
                        {currency(batchUsed.calculatedIncentive, {
                          symbol: "",
                        }).format()}
                      </td>
                      <td>
                        {currency(selectedSale.Unit_Price, {
                          symbol: "",
                        }).format()}
                      </td>
                      <td>
                        {currency(selectedSale.Discount, {
                          symbol: "",
                        }).format()}
                      </td>
                      <td>
                        {currency(batchUsed.PriceSold, { symbol: "" }).format()}
                      </td>
                      <td />
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td />
                      <td />
                      <td /> <td />
                      <td>
                        {currency(batchUsed.calculatedMarkUp, {
                          symbol: "",
                        })
                          .multiply(batchUsed.quantityUsed)
                          .format()}
                      </td>
                      <td>
                        {currency(batchUsed.PriceSold, { symbol: "" })
                          .subtract(batchUsed.UnitCost)
                          .subtract(batchUsed.calculatedIncentive)
                          .multiply(batchUsed.quantityUsed)
                          .format()}
                      </td>
                      <td>
                        {currency(batchUsed.calculatedIncentive, {
                          symbol: "",
                        })
                          .multiply(batchUsed.quantityUsed)
                          .format()}
                      </td>
                      <td />
                      <td />
                      <td /> <td />
                    </tr>
                  </tbody>
                </Fragment>
              ))}{" "}
              <tfoot className="text-nowrap">
                <tr className="border">
                  <td>
                    <b> Grand Total</b>
                  </td>
                  <td /> <td />
                  <td />
                  <td />
                  <td>
                    {currency(grandTotalMarkUp, {
                      symbol: "",
                    }).format()}
                  </td>
                  <td>
                    {currency(grandTotalProfit, {
                      symbol: "",
                    }).format()}
                  </td>
                  <td>
                    {currency(grandTotalIncentive, {
                      symbol: "",
                    }).format()}
                  </td>
                  <td />
                  <td />
                  <td />
                </tr>

                <tr>
                  <td colSpan={10}>&nbsp;</td>
                </tr>
                <tr className="border">
                  <td>
                    <b>Sale</b>
                  </td>{" "}
                  <td />
                  <td>{selectedSale.QTY}</td>
                  <td /> <td />
                  <td>
                    {currency(selectedSale.totalMarkUp, {
                      symbol: "",
                    }).format()}
                  </td>
                  <td>
                    {currency(selectedSale.Profit, {
                      symbol: "",
                    }).format()}
                  </td>
                  <td>
                    {currency(selectedSale.Total_Incentive, {
                      symbol: "",
                    }).format()}
                  </td>
                  <td />
                  <td />
                  <td />
                </tr>
              </tfoot>
            </Table>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
