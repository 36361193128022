import { Modal, Form, Button } from "react-bootstrap";
import * as yup from "yup";
import { services } from "../config";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useState } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import Select from "react-select";
import { useEffect } from "react";
import { useBackendUrl } from "../utils/hooks";
import { customerFullName, employeeFullName } from "../utils/helpers";
import queryString from "query-string";
import { LoaderIcon } from "./Icons";
import CachedIcon from "mdi-react/CachedIcon";
import { isEmpty } from "lodash";

function SelectSaleRep({ proceed, show, selectedCustomer, invoiceCat }) {
  const backendUrl = useBackendUrl();
  const [isLoading, setIsLoading] = useState(false);
  const [saleReps, setSaleReps] = useState([]);

  const initialValues = {
    saleRep: "",
  };
  const schema = yup.object().shape({
    saleRep: yup.string().required(),
  });

  const getSaleReps = async () => {
    try {
      setIsLoading(true);
      let response = await fetch(`${backendUrl}/api/customers/employees`, {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        response = await response.json();
        toast.error(response.message);
      } else {
        const {
          data: { employees = [] },
        } = await response.json();
        if (isEmpty(employees)) {
          return toast.error("No Sales Rep found");
        }
        setSaleReps(
          employees.map((el) => ({
            ...el,
            label: employeeFullName(el),
            value: el.EmployeeID,
          }))
        );
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to get saleReps");
    } finally {
      setIsLoading(false);
    }
  };

  const createCustomerSaleRep = async (payload) => {
    try {
      setIsLoading(true);
      let response = await fetch(
        `${backendUrl}/api/customers/create-sale-rep`,
        {
          method: "POST",
          credentials: "include",
          body: JSON.stringify(payload),
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
        }
      );
      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      }
      const res = await response.json();
      // console.log(saleReps, payload);
      const rep = saleReps.find((el) => el.EmployeeID === payload.Rep_ID);
      toast.success("Success");
      proceed({
        Sale_Rep_Name: employeeFullName(rep),
        Sale_Rep: rep.EmployeeID,
      });
    } catch (err) {
      console.log(err);
      toast.error("Unable to Save");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSaleReps();
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      // save to backend
      createCustomerSaleRep({
        Cust_ID: selectedCustomer.Cust_ID,
        Rep_ID: values.saleRep,
        Product: invoiceCat,
      });
    },
  });

  return (
    <Modal
      show={show}
      onHide={() => proceed(false)}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h5">
            Select Sales Rep{" "}
            <Button
              variant="white"
              className="text-primary"
              onClick={() => getSaleReps()}
            >
              <CachedIcon />
            </Button>{" "}
          </h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p className="fs-6">
            <span className="fw-6">Customer ID:</span>
            {"  "}
            {selectedCustomer.Cust_ID}
          </p>
          <p className="mb-3 fs-6">
            <span className="fw-6"> Business Name:</span>
            {"  "}
            {customerFullName(selectedCustomer)}
          </p>

          <p className="mb-3 fs-6">
            <span className="fw-6"> Product/Manufaturer:</span>
            {"  "}
            {invoiceCat}
          </p>
          <hr />
        </div>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Sales Rep</Form.Label>
            <Select
              classNamePrefix="form-select"
              placeholder="Select"
              disabled={isLoading}
              isSearchable={false}
              options={saleReps}
              value={saleReps.find((el) => el.value === formik.values.saleRep)}
              onChange={({ value }) => formik.setFieldValue("saleRep", value)}
              onBlur={() => formik.setFieldTouched("saleRep", true)}
              className={
                formik.touched.saleRep && !!formik.errors.saleRep
                  ? "is-invalid"
                  : ""
              }
            />
            {formik.touched.saleRep && formik.errors.saleRep ? (
              <span className="custom-invalid-feedback">
                {formik.errors.saleRep}
              </span>
            ) : null}
          </Form.Group>

          <Button
            disabled={isLoading}
            variant="primary"
            className="w-100 p-2"
            type="submit"
          >
            {isLoading ? "Please wait…" : "Continue"}
          </Button>
        </Form>
      </Modal.Body>

      {isLoading ? (
        <div className="global-spinner">
          <LoaderIcon className="spin text-primary" />
        </div>
      ) : (
        <></>
      )}
    </Modal>
  );
}

export default function SelectSaleRepDialog(props) {
  return createConfirmation(confirmable(SelectSaleRep))({ ...props });
}
