import { Table, InputGroup, Button, Dropdown, Form } from "react-bootstrap";
import { CSVLink } from "react-csv";
import printJS from "print-js";
import {
  CalendarIcon,
  CubeIcon,
  DeleteIcon,
  EditIcon,
  ExportIcon,
  FIleUploadLineIcon,
  FilterCollapseIcon,
  PrintIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import DateRangePicker from "../utils/DateRangePicker";
import CachedIcon from "mdi-react/CachedIcon";
import { FilterTwoIcon, PDFIcon, ExcelIcon } from "../Icons";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import useDebounce, {
  useIsAdmin,
  useIsStore,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import queryString from "query-string";
import { appSettings, services } from "../../config";
import { useMutation, useQuery, useQueryClient } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  defaultSelectValue,
  paginationOptions,
  pcsToTons,
  qtyFormat,
  qtyFormatToString,
  scrollToTop,
  tonsToPcs,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { useAuth } from "../../hooks/useAuth";
import UpdateItemModal from "../UpdateItemModal";
import NewItemModal from "../NewItemModal";
import PurchaseDialog from "../PurchaseDialog";
import NewItemServiceModal from "../NewItemServiceModal";
import NewItemModalWrapper from "../NewItemModalWrapper";
import NewVendorModal from "../NewVendorModal";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import LoginDialog from "../LoginDialog";
import ConfirmDialog from "../ConfirmDialogue";
import { toast } from "react-toastify";
import CubeOutline from "mdi-react/CubeOutlineIcon";
import { Link } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import Select from "react-select";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import ItemsTable from "../utils/ItemsTable";
import TableComponent from "../TableComponent";
import ConvertQuantity from "../utils/ConvertQuantity";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import UpdateItemPriceModal from "../UpdateItemPriceModal";
import QuantityConversionModal from "../QuantityConversionModal";
import { cloneDeep, isEmpty } from "lodash";
import ConversionDialog from "../ConversionDialog";
import NoTableItem from "../utils/NoTableItem";
import ItemConversionHistoryModal from "./ItemConversionHistoryModal";
import { IsPrivileged } from "../DisplayChildElement";

export default function ItemConversion() {
  const queryClient = useQueryClient();
  const { backendUrl, isIronRod, isCement } = useAuth();
  const isAdmin = useIsAdmin();
  const isStore = useIsStore();
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showUpdatePriceModal, setShowUpdatePriceModal] = useState(false);
  useScrollTop();

  const [showConversionHistoryModal, setShowConversionHistoryModal] = useState(
    false
  );
  const [showItemConversionModal, setShowItemConversionModal] = useState(false);
  const [showNewItemConversionModal, setShowNewItemConversionModal] = useState(
    false
  );
  const generalSettings = useStoreState((state) => state?.generalSettings);

  const [rowToEdit, setRowToEdit] = useState();

  const initialFilterParams = {
    page: 1,
    limit: 40,
    barcode: "",
    itemName: "",
    product: "",
    category: "",
    branch: "",
    withProduct: true,
    withCategory: true,
  };
  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...queryParams,
    ...initialFilterParams,
  });

  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const fetchItems = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/items/conversions?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };

  const { data = { count: 0, items: [] }, refetch, isFetching } = useQuery(
    ["ITEMS_CONVERSIONS", queryParams],
    () => fetchItems(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const deleteItem = async (payload) => {
    let response = await fetch(`${backendUrl}/api/items/delete-conversion`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteMutation = useMutation((payload) => deleteItem(payload), {
    onSuccess: ({ message, data: { item } }) => {
      toast.success(message);

      queryClient.setQueryData(["ITEMS_CONVERSIONS", queryParams], (data) => {
        data.items = data.items.filter((el) => el.id !== item.id);
        return data;
      });
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const handleDeleteItem = async (item) => {
    if (await LoginDialog()) {
      if (
        await ConfirmDialog({
          title: "Delete Item",
          description: "Are you sure you want to delete this Item Conversion",
        })
      ) {
        deleteMutation.mutate(item);
      }
    }
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams({
      ...initialFilterParams,
    });
  };

  const handleSelectedItem = (item) => {
    setFilterParams({
      ...filterParams,
      barcode: item.Bar_Code,
      itemName: item.Item_Name,
    });
    setShowItemsPopover(false);
  };

  const editConversion = async (conversion) => {
    setRowToEdit(null);
    if (await LoginDialog()) {
      const el = { ...conversion };
      let rawMaterial = el.rawMaterialItemData;
      let finishedItem = el.finishedItemData;

      //
      el.RawMaterialQuantity =
        el.RawMaterialSize === "Tons"
          ? pcsToTons(
            el.RawMaterialQuantity,
            rawMaterial.Item_Desc,
            itemMeasurements
          )
          : el.RawMaterialQuantity;

      //
      el.FinishedItemQuantity =
        el.FinishedItemSize === "Tons"
          ? pcsToTons(
            el.FinishedItemQuantity,
            finishedItem.Item_Desc,
            itemMeasurements
          )
          : el.FinishedItemQuantity;

      setRowToEdit({ ...el });
      setShowItemConversionModal(true);
    }
  };

  const convert = async (el) => {
    // ----------------------
    let values = cloneDeep({ ...el });
    values.convert = true;
    let rawMaterial = el.rawMaterialItemData;
    let finishedItem = el.finishedItemData;

    if (values.RawMaterialSize === "Tons") {
      values.RawMaterialQuantity = pcsToTons(
        values.RawMaterialQuantity,
        rawMaterial.Item_Desc,
        itemMeasurements
      );
    }

    if (values.FinishedItemSize === "Tons") {
      values.FinishedItemQuantity = pcsToTons(
        values.FinishedItemQuantity,
        finishedItem.Item_Desc,
        itemMeasurements
      );
    }

    if (values.convert) {
      const conversionValues = await ConversionDialog({
        rawMaterial,
        finishedItem,
        ...values,
        itemMeasurements,
        isIronRod,
        generalSettings,
      });
      if (!conversionValues) return;
      values.conversionValues = conversionValues;
    }
    conversionMutation.mutate({ ...values, conversionOnly: true });
  };

  const convertData = async (payload) => {
    let response = await fetch(`${backendUrl}/api/items/save-conversion`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const conversionMutation = useMutation((payload) => convertData(payload), {
    onSuccess: ({ message, data }) => {
      toast.success(message);
      setRowToEdit(null);
      refetch();
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  // The main table data
  const tableBodyData = (el, index) => {
    return (
      <>
        <td>
          {" "}
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              className="bg-white border-0"
              bsPrefix="print more"
            >
              <DotsVerticalIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu
              popperConfig={{
                strategy: "fixed",
              }}
              renderOnMount
              className="dropdown-with-icons"
            >
              {isAdmin && (
                <Dropdown.Item as="button" onClick={() => editConversion(el)}>
                  <EditIcon />
                  Edit
                </Dropdown.Item>
              )}

              <Dropdown.Item as="button" onClick={() => convert(el)}>
                <CubeOutline className="text-light" />
                Convert Now
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                onClick={() => {
                  setSelectedItem(el);
                  setShowConversionHistoryModal(true);
                }}
              >
                <CubeOutline className="text-light" />
                Conversion History
              </Dropdown.Item>

              {isAdmin && (
                <Dropdown.Item as="button" onClick={() => handleDeleteItem(el)}>
                  <DeleteIcon />
                  Delete
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </td>
        <td>{data?.startIndex + index + 1}</td>
        <td>{el?.RawMaterialBarcode}</td>
        <td>{el?.RawMaterialItemName}</td>
        <td>
          <ConvertQuantity
            quantity={el?.RawMaterialQuantity}
            desc={el?.rawMaterialItemData?.Item_Desc}
          />
        </td>
        <td>{el?.RawMaterialSize}</td>
        <td>{el?.FinishedItemBarcode}</td>
        <td>{el?.FinishedItemName}</td>
        <td>
          <ConvertQuantity
            quantity={el?.FinishedItemQuantity}
            desc={el?.finishedItemData?.Item_Desc}
          />
        </td>
        <td>{el?.FinishedItemSize}</td>
        <td>{el?.Date ? format(new Date(el?.Date), "dd MMM, yyyy") : ""}</td>
      </>
    );
  };

  const tableHead = () => {
    return (
      <thead>
        <tr>
          <th />
          <th />
          <th>Raw Material Barcode</th>
          <th>Raw Material Item Name</th>
          <th>Raw Material Quantity</th>
          <th>Raw Material Size</th>

          <th>Finished Item Barcode</th>
          <th>Finished Item Name</th>
          <th>Finished Item Quantity</th>
          <th>Finished Item Size</th>
          <th>Date</th>
        </tr>
      </thead>
    );
  };

  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    const { limit, page, ...rest } = queryParams;
    let exData = await fetchExcelData(
      `${backendUrl}/api/items?${queryString.stringify(rest)}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;

    // console.log(exData.data);
    exData = exData.data.items.map((row) => ({
      ...row,
      Date_Log: format(new Date(row.Date_Log), "dd-MMM-yyyy"),
    }));

    exData = exData.map((d, i) => [
      data?.startIndex + i + 1,
      d.Bar_Code,
      d.Item_Name,
      d.Item_Desc,
      isIronRod
        ? qtyFormatToString(
          qtyFormat(d.Quantity, d.Item_Desc, itemMeasurements)
        )
        : d.Quantity,
      currency(d?.UnitCost, {
        symbol: "",
      }).format(),
      currency(d?.UnitPrice, {
        symbol: "",
      }).format(),
      d.Date_Log,
    ]);
    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
          new Date(rest.startDate),
          "E MMM d yyyy k:mm:ss z"
        )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      [company],
      ["Inventory Entry Report"],
      [date],
      [""],
      [
        "S/N",
        "Item Code",
        "Item Name",
        "Item Desc",
        "QTY",
        "Unit Cost",
        "Unit Price",
        "Post time",
      ],
      ...exData,
      [""],
    ];
    console.log(exData);
    setExcelData(exData);
  };

  return (
    <IsPrivileged roleName="Item Conversion">
      <section className="cashbook-wrapped">
        <main className="cash-book">
          <div className="content">
            <div className="content-main">
              <div className={`filter-area ${showFilter ? "open" : "close"}`}>
                <div className="filter-header">
                  <h2>
                    <FilterTwoIcon /> Filters
                  </h2>

                  <Button
                    variant="white"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <FilterCollapseIcon />
                  </Button>
                </div>
                <div className="filter-body">
                  <Form>
                    <div className="body">
                      <Form.Group className="mb-2-5">
                        <Form.Label>Raw Material Barcode</Form.Label>

                        <InputGroup>
                          <Form.Control
                            name="barcode"
                            placeholder="Enter Item Code"
                            value={filterParams?.barcode}
                            onChange={(e) => handleFilterParamsChange(e)}
                          />

                          <Popover
                            isOpen={showItemsPopover}
                            onClickOutside={() => setShowItemsPopover(false)}
                            content={() => (
                              <ItemsTable
                                handleSelectedItem={handleSelectedItem}
                              />
                            )}
                            position="bottom"
                          >
                            <InputGroup.Text
                              onClick={() =>
                                setShowItemsPopover(!showItemsPopover)
                              }
                            >
                              <MagnifyIcon />
                            </InputGroup.Text>
                          </Popover>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group className="mb-2-5">
                        <Form.Label> Raw Material Item Name</Form.Label>
                        <Form.Control
                          name="itemName"
                          value={filterParams?.itemName}
                          placeholder="Enter Item Name"
                          onChange={(e) => handleFilterParamsChange(e)}
                        />
                      </Form.Group>

                      <hr className="mt-3 mb-4" />
                      <Form.Group className="">
                        <Form.Label>Date Range</Form.Label>
                        <div className="position-relative">
                          <RsDateRangePicker
                            placement="topStart"
                            value={
                              filterParams.startDate && filterParams.endDate
                                ? [
                                  parse(
                                    filterParams.startDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                  parse(
                                    filterParams.endDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                ]
                                : []
                            }
                            onClean={() => clearDateRange()}
                            onOk={(date) => filterByDateRange(date)}
                          />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="buttons rounded">
                      <Button onClick={() => reset()} variant="white">
                        Reset
                      </Button>
                      <Button onClick={() => search()} variant="primary">
                        Search
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="content-body">
                <header>
                  <h1>
                    {!showFilter && (
                      <button
                        onClick={() => setShowFilter(!showFilter)}
                        className="btn filter"
                      >
                        <FilterTwoIcon />
                      </button>
                    )}
                    Inventory Conversion Template
                    <button
                      onClick={() => refetch()}
                      className="btn text-primary"
                      title="Refresh"
                    >
                      <CachedIcon />
                    </button>
                  </h1>
                  <div className="actions mr-5">
                    {isAdmin && (
                      <Button
                        variant="primary"
                        onClick={() => setShowNewItemConversionModal(true)}
                      >
                        + Create New Template
                      </Button>
                    )}

                    {/* <CSVLink
                    className="btn print d-none"
                    filename={`inventory Entry Report(${format(
                      new Date(),
                      "dd-MMM-yyyy hh:mm:ss a"
                    )}).csv`}
                    data={excelData}
                    ref={CSVLinkRef}
                  />
                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      className="btn print"
                      disabled={isfetchingExcel}
                      bsPrefix=""
                    >

                      <ExportIcon color="#008000" />Export
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      popperConfig={{
                        strategy: "fixed",
                      }}
                      renderOnMount
                      className="text-center"
                    >
                      <Dropdown.Item
                        as="div"
                        onClick={onDownloadExcelData}
                        className="p-cursor"
                      >
                        Excel <ExcelIcon color="#008000" />
                      </Dropdown.Item>
                      <Dropdown.Item as="div">
                        <a
                          href={`${backendUrl}/api/items/pdf/inventory-entry?${queryString.stringify(
                            rest
                          )}`}
                          target="blank"
                        >
                          PDF <PDFIcon color="#ff0000" />
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  </div>
                </header>

                <div className="px-md-4">
                  <TableComponent
                    responsive
                    borderless
                    striped
                    tableHeadsFunction={tableHead}
                    mainDataArray={data?.items}
                    tableDataRowFunction={tableBodyData}
                    className="product-table text-nowrap"
                  />
                  {isEmpty(data.items) && !isFetching ? (
                    <div className="d-flex justify-content-center text-center w-100 my-4">
                      <NoTableItem queryParams={queryParams} />
                    </div>
                  ) : null}
                </div>

                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data.count / queryParams.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      scrollToTop();
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>
              </div>
            </div>
          </div>

          {showItemConversionModal && !isEmpty(rowToEdit) ? (
            <QuantityConversionModal
              setShowQuantityConversionModal={setShowItemConversionModal}
              showQuantityConversionModal={showItemConversionModal}
              refetch={refetch}
              rowToEdit={rowToEdit}
              setRowToEdit={setRowToEdit}
              key={rowToEdit}
            />
          ) : null}

          {showNewItemConversionModal ? (
            <QuantityConversionModal
              setShowQuantityConversionModal={setShowNewItemConversionModal}
              showQuantityConversionModal={showNewItemConversionModal}
              refetch={refetch}
              setRowToEdit={setRowToEdit}
            />
          ) : null}

          {showConversionHistoryModal && selectedItem ? (
            <ItemConversionHistoryModal
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              setShowConversionHistoryModal={setShowConversionHistoryModal}
            />
          ) : null}

          <ModalLoader show={isfetchingExcel || conversionMutation.isLoading} />
        </main>
      </section>
    </IsPrivileged>
  );
}
