import { Modal, Form, Button } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { confirmable, createConfirmation } from "react-confirm";
import NumberCustomInput from "./utils/NumberCustomInput";
import Datetime from "react-datetime";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import Select from "react-select";
import { useEffectOnce } from "../utils/hooks";

function Purchase({ proceed, show, item = {}, payment }) {
  const PurchaseID = String(Date.now());

  const initialValues = {
    Size: "",
    Bar_Code: "",
    UnitPrice: "",
    Quantity: "",
    Vendor_ID: "",
    AmountDue: "",
    AmountPaid: "",
    Balance: "",
    PurchaseID,
    PurchaseDate: "",
    Username: "",
    InvoiceNo: "",
    ItemName: "",
    Branch: "",

    //
    // Vat: "",
    Payment: payment?.Payment,
  };

  const schema = yup.object().shape({
    /*  Size: yup.string().required(),
    Quantity: yup.string().required(), */
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      proceed(values);
    },
  });

  useEffectOnce(() => {
    formik.setValues({
      ...formik.values,
      Size: item.Size,
      Bar_Code: item.Bar_Code,
      UnitPrice: item.PurchasePrice,
      Quantity: item.Quantity,
      Vendor_ID: item.Vendor,
      AmountDue: item.InvoiceAmount,
      PurchaseID,
      PurchaseDate: item.Date_Log,
      InvoiceNo: item.InvoiceNo,
      ItemName: item.Item_Name,
      Branch: item.Branch,
    });
  });

  const canClose = () => {
    proceed();
  };

  return (
    <Modal
      show={show}
      onHide={() => canClose()}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          <h1 className="h5">Purchase Recording</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate className="w-100 px-2" onSubmit={formik.handleSubmit}>
          <div className="row">
            <Form.Group className="col-6 mb-3">
              <Form.Label>Item Code</Form.Label>
              <Form.Control value={item?.Bar_Code} readonly />
            </Form.Group>
            <Form.Group className="col-6 mb-3">
              <Form.Label>Item Name</Form.Label>
              <Form.Control value={item?.Item_Name} readonly />
            </Form.Group>
          </div>

          <div className="row">
            <Form.Group className="col-6 mb-3">
              <Form.Label>Unit Price</Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                name="UnitPrice"
                value={formik.values.UnitPrice}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.UnitPrice && !!formik.errors.UnitPrice
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.UnitPrice}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className=" col-6 mb-3">
              <Form.Label>Quantity</Form.Label>
              <NumberCustomInput
                type="text"
                placeholder=""
                name="Quantity"
                value={formik.values.Quantity}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                isInvalid={formik.touched.Quantity && !!formik.errors.Quantity}
                readOnly
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.Quantity}
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <div className="row">
            <Form.Group className="col-6 mb-3">
              <Form.Label>Purchase ID</Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                name="Purchase ID"
                value={formik.values.PurchaseID}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.PurchaseID && !!formik.errors.PurchaseID
                }
              />
            </Form.Group>
            <Form.Group className="col-6 mb-3">
              <Form.Label>Purchase Date</Form.Label>
              <Datetime
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside={true}
                dateFormat="MMM DD, YYYY"
                name="PurchaseDate"
                inputProps={{
                  className: `date-input form-control ${formik.touched.PurchaseDate && !!formik.errors.PurchaseDate
                      ? "is-invalid"
                      : ""
                    }`,
                  placeholder: "Select date",
                  readOnly: true,
                }}
                value={formik.values.PurchaseDate}
                onChange={(date) => {
                  formik.setFieldValue("PurchaseDate", date, true);
                }}
                onBlur={() => formik.setFieldTouched("PurchaseDate", true)}
              />
            </Form.Group>
          </div>

          <div className="row">
            <Form.Group className="col-6 mb-3">
              <Form.Label>Amount Due</Form.Label>
              <CurrencyCustomInput
                currencySymbol=""
                nama="AmountDue"
                value={formik.values.AmountDue}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                isInvalid={
                  formik.touched.AmountDue && !!formik.errors.AmountDue
                }
                readOnly
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.AmountDue}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col-6 mb-3">
              <Form.Label>Invoice No</Form.Label>
              <Form.Control
                // type="number"
                placeholder=""
                name="InvoiceNo"
                value={formik.values.InvoiceNo}
                readOnly
              />
            </Form.Group>
          </div>

          <div className="row">
            <Form.Group className="col-6 mb-3">
              <Form.Label>Vendor ID</Form.Label>
              <Form.Control value={item.Vendor} readOnly />
            </Form.Group>
            <Form.Group className="col-6 mb-3">
              <Form.Label>Branch</Form.Label>
              <Form.Control value={item.Branch} readOnly />
            </Form.Group>
          </div>

          <div className="row">
            <Form.Group className="col-6 mb-3">
              <Form.Label>Payment</Form.Label>
              <Select
                placeholder={payment?.Payment}
                options={[{ label: payment?.Payment, value: payment?.Payment }]}
                isDisabled
              />
            </Form.Group>
            <Form.Group className="col-6 mb-3">
              <Form.Label>Freight</Form.Label>
              <Form.Control value={item?.Freight} readonly />
            </Form.Group>
          </div>

          {/* <div className="row">
            <Form.Group className="col-6 mb-3">
              <Form.Label>Vat</Form.Label>
              <CurrencyCustomInput
                nama="VAT"
                value={formik.values.VAT}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                isInvalid={formik.touched.VAT && !!formik.errors.VAT}
              />
            </Form.Group>
          </div> */}
        </Form>
      </Modal.Body>
      <Modal.Footer className="gap-3">
        <Button
          onClick={() => formik.submitForm()}
          variant="primary"
          className=" p-2 px-4"
          type="submit"
        >
          Save
        </Button>
        <Button
          onClick={() => proceed()}
          variant="outline-primary"
          className="p-2 px-4"
          type="submit"
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function PurchaseDialog(props) {
  return createConfirmation(confirmable(Purchase))({ ...props });
}
