import CachedIcon from "mdi-react/CachedIcon";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Dropdown } from "react-bootstrap";
import TableComponent from "../TableComponent";
import { useFormik } from "formik";
import * as yup from "yup";
import { fetchActionsUtil, paginationOptions } from "../../utils/helpers";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import useDebounce, { useQueryParams } from "../../utils/hooks";
import { useQuery } from "react-query";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
import { DeleteIcon, EditIcon } from "../Icons";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import NoTableItem from "../utils/NoTableItem";
import { isEmpty } from "lodash";

const schema = yup.object().shape({
  Category: yup.string().required(),
});

function CreateVendorCategoryModal({
  showCreateVendorCategoryModal,
  setShowCreateVendorCategoryModal,
  update,
  categoryId,
  category,
  setUpdateCategory,
  refetch,
}) {
  const { backendUrl, token } = useAuth();

  const initialValues = {
    Category: "",
    id: "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      let endpoint;
      if (Boolean(update)) {
        endpoint = `update`;
      } else {
        endpoint = `create`;
      }

      try {
        formik.isSubmitting = true;
        const res = await fetchActionsUtil(
          `${backendUrl}/api/vendors/${endpoint}-vendor-category`,
          "POST",
          "",
          { Category: values?.Category, categoryId: values?.id }
        );

        formik.isSubmitting = false;
        toast.success(res?.message);
        setUpdateCategory(false);
        refetch();
        setShowCreateVendorCategoryModal(false);
      } catch (error) {
        toast.error("Unable to peroform action");
      }
    },
  });

  useEffect(() => {
    if (update) {
      formik.setFieldValue("id", categoryId);
      formik.setFieldValue("Category", category);
    } else {
      formik.setFieldValue("id", "");
      formik.setFieldValue("Category", "");
    }
  }, []);

  return (
    <Modal
      show={showCreateVendorCategoryModal}
      onHide={() => {
        setUpdateCategory(false);
        setShowCreateVendorCategoryModal(false);
      }}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h5">
            {update ? "Update " : "Create "}Vendor Category
          </h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Category</Form.Label>
            <Form.Control
              name="Category"
              placeholder="Category"
              type="text"
              value={formik.values.Category}
              onChange={formik.handleChange}
              isInvalid={formik.touched.Category && !!formik.errors.Category}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.Category}
            </Form.Control.Feedback>
          </Form.Group>

          <Button
            //   disabled={createTransTypesMutation.isLoading}
            variant="primary"
            className="w-100 p-2"
            type="submit"
          >
            {update && !formik.isSubmitting && "Update"}
            {update && formik.isSubmitting && "Please wait..."}
            {!update && formik.isSubmitting && "Please wait..."}
            {!update && !formik.isSubmitting && "Create Now"}

            {/* {createTransTypesMutation.isLoading ? "Please wait…" : "Create Now"} */}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default function VendorCategory() {
  const [
    showCreateVendorCategoryModal,
    setShowCreateVendorCategoryModal,
  ] = useState(false);

  const [updatecategory, setUpdateCategory] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [category, setCategory] = useState("");
  const initialFilterParams = {
    q: "",
  };
  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const { backendUrl, token } = useAuth();

  const tableHead = () => {
    return (
      <thead className="position-sticky top-0">
        <tr>
          <th />
          <th>S/N</th>
          <th>Category</th>
        </tr>
      </thead>
    );
  };

  const debouncedFilterParams = useDebounce(filterParams, 500);

  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const {
    data,
    isSuccess,
    isLoading,
    isFetched,
    refetch,
    isFetching,
  } = useQuery(
    ["GET ALL VENDOR CATEGORY", debouncedFilterParams],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/vendors/get-vendor-category?${queryString.stringify(
          debouncedFilterParams
        )}`,
        "GET"
      ),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  async function deleteCategory(id) {
    const res = await fetchActionsUtil(
      `${backendUrl}/api/vendors/delete-vendor-category`,
      "DELETE",
      "",
      { categoryId: id }
    );

    toast.success(res.message);
    refetch();
  }

  const tableData = (el, index) => {
    return (
      <>
        <td>
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              className="bg-white border-0"
              bsPrefix="print more"
            >
              <DotsVerticalIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu
              popperConfig={{
                strategy: "fixed",
              }}
              renderOnMount
              className="dropdown-with-icons"
            >
              <Dropdown.Item
                as="button"
                onClick={() => {
                  setCategoryId(el?.id);
                  setUpdateCategory(true);
                  setCategory(el?.Category);
                  setShowCreateVendorCategoryModal(true);
                }}
              >
                <EditIcon />
                Update
              </Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => deleteCategory(el?.id)}>
                {" "}
                <DeleteIcon />
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>{" "}
          </Dropdown>
        </td>

        <td>{data?.startIndex + index + 1}</td>
        <td>{el?.Category}</td>
      </>
    );
  };

  return (
    <>
      {showCreateVendorCategoryModal && (
        <CreateVendorCategoryModal
          showCreateVendorCategoryModal={showCreateVendorCategoryModal}
          setShowCreateVendorCategoryModal={setShowCreateVendorCategoryModal}
          update={updatecategory}
          categoryId={categoryId}
          category={category}
          setUpdateCategory={setUpdateCategory}
          refetch={refetch}
        />
      )}
      <main className="bg-white">
        <header className="p-4">
          <div className="search-area d-flex flex-wrap justify-content-between gap-3 w-100">
            <div className="d-flex flex-wrap gap-3 col-md-4">
              <div className="global-search-area w-75">
                <MagnifyIcon />
                <Form.Control
                  className=""
                  name="q"
                  placeholder="Search Vendor Category..."
                  onChange={(e) => handleFilterParamsChange(e)}
                />
              </div>
              <Button
                variant=""
                className="bg-light-blue border-0 text-primary"
                onClick={() => refetch()}
              >
                <CachedIcon />
              </Button>
            </div>

            <div className="actions mr-5">
              <Button
                className="px-3"
                onClick={() => setShowCreateVendorCategoryModal(true)}
              >
                + Create New
              </Button>
            </div>
          </div>
        </header>

        <div className="content">
          <div className="px-md-4">
            <TableComponent
              responsive
              borderless
              striped
              tableHeadsFunction={tableHead}
              mainDataArray={data?.data || []}
              className="product-table text-nowrap"
              tableDataRowFunction={tableData}
            // tableRowClick={(e, el) => manageTransaction(e, el)}
            />

            {!isFetching && isSuccess && isEmpty(data?.data) ? (
              <NoTableItem queryParams={queryParams} />
            ) : null}
          </div>
        </div>

        <div className="d-flex justify-content-between px-4 align-items-center pagination pb-4 ">
          <div className="pagination_left d-flex gap-3 align-items-center">
            <p className="m-0 p-0">Show</p>
            <select
              value={queryParams.limit}
              name="limit"
              className="form-select "
              onChange={(e) => handleFilterParamsChange(e)}
            >
              <option value="10">10 rows</option>
              <option value="20">20 rows</option>
              <option value="30">30 rows</option>
              <option value="40">40 rows</option>
            </select>
          </div>

          <ReactPaginate
            {...paginationOptions}
            pageCount={Math.ceil(data?.count / queryParams.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={0}
            onPageChange={({ selected }) => {
              document.body.scrollTop = document.documentElement.scrollTop = 0;
              setFilterParams({
                ...queryParams,
                page: selected + 1,
              });
            }}
            forcePage={queryParams.page - 1}
          />
        </div>
      </main>
    </>
  );
}
