import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Button, Form, Modal, Nav, Table } from "react-bootstrap";
import MessageProcessingOutlineIcon from "mdi-react/MessageProcessingOutlineIcon";
import "./../../assets/scss/requisition/index.scss";
import ApprovalBadge from "../utils/ApprovalBadge";
import {
  ApproveCheckIcon,
  DeleteIcon,
  DisapprovedCheckIcon,
  EditIcon,
  MessageIcon,
  PendingCheckIcon,
  RefreshIcon,
  UserIcon,
} from "../Icons";
import { useNavigate, useParams } from "react-router-dom";
import { services } from "../../config";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { isEmpty, lowerCase, max } from "lodash";
import { format } from "date-fns";
import {
  ApproveAndCloseDialog,
  ApproveOnlyDialog,
  EditLeaveCommentDialog,
  LeaveCommentDialog,
  RejectDialog,
} from "./Dialog";
import { requisitionActions } from "../../utils/reactQueryActions";
import Avatar from "../utils/Avatar";
import { useAuth } from "../../hooks/useAuth";
import { last } from "lodash";
import { LinkWithQuery as Link } from "../utils/LinkWithQuery";
import eventBus from "../../utils/EventBus";
import {
  formatDate,
  getUserThatRequestedToMe,
  isInventoryOrFundRequest,
  resolveApprovalBadgeBg,
  styleHelper,
} from "../../utils/helpers";
import { useCanGoBack, useIsAdmin } from "../../utils/hooks";
import CachedIcon from "mdi-react/CachedIcon";
import ConfirmDialog from "../ConfirmDialogue";
import { toast } from "react-toastify";
import { useStoreState } from "easy-peasy";
import RequisitionApproveFooter from "./RequisitionApproveFooter";
import { uniq } from "lodash";
import { size } from "lodash";

function Comments({ requisitionComments, refetch, requisitionid }) {
  const { user: authUser, backendUrl } = useAuth();

  const deleteComments = async (payload) => {
    let response = await fetch(`${backendUrl}/api/requisition/delete-Comment`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteCommentsMutation = useMutation(
    (payload) => deleteComments(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        console.log(refetch);
        if (refetch) refetch();
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const deleteComment = async (comment) => {
    if (
      await ConfirmDialog({
        title: "Delete Comment",
        description: `"${comment.comment}"`,
      })
    ) {
      deleteCommentsMutation.mutate({
        ...comment,
        requestid: requisitionid,
      });
    }
  };

  const editComment = async (comment) => {
    await EditLeaveCommentDialog({
      oldComment: comment.comment,
      id: comment.id,
      refetch,
      requestid: requisitionid,
    });
  };

  return (
    <>
      {!isEmpty(requisitionComments) ? (
        <div className="comments">
          <p className="mt-2 fw-6">Comments</p>
          {requisitionComments.map((comment, index) => (
            <p key={index}>
              <b>{comment.UserName}:</b> <span>{comment.comment}</span>{" "}
              <span> - {formatDate(comment.regdate)}</span>
              {authUser.Staff_ID === comment.Staff_ID && (
                <span>
                  {" "}
                  <Button
                    variant=""
                    className="p-1"
                    onClick={() => editComment(comment)}
                  >
                    <EditIcon />
                  </Button>
                  <Button
                    variant=""
                    className="p-1"
                    onClick={() => deleteComment(comment)}
                  >
                    <DeleteIcon />
                  </Button>
                </span>
              )}
            </p>
          ))}
        </div>
      ) : null}
    </>
  );
}

export default function DetailsModal({ location }) {
  const generalSettings = useStoreState((state) => state.generalSettings);
  const { user: authUser, backendUrl } = useAuth();
  // const queryClient = useQueryClient();
  let { id } = useParams();
  const [activeKey, setActiveKey] = useState("details");
  const isAdmin = useIsAdmin();

  const handleSelect = (eventKey) => setActiveKey(eventKey);
  let navigate = useNavigate();
  const goBack = useCanGoBack();
  const [reciever, setReceiver] = useState();

  const getNextUserToPerformAction = (requisition) => {
    const requisitiontracks = requisition.requisitiontracks;
    let permissions = [];
    const page = JSON.parse(requisition.filledFormData)[0];
    const signatories = page.children
      .filter((el) => el.type === "signatoryElement")
      .map((el) => el.props.fields);
    if (signatories) {
      permissions = signatories.flat().map((el) => el.permission);

      if (permissions) {
        //  user we want  to  send  to
        const lastTrack = requisitiontracks.length + 1;
        setReceiver(permissions[lastTrack]);
      }
    }
  };

  const getNextUserToPerformActionForSystemForm = (requisition) => {
    const requisitiontracks = requisition?.requisitiontracks;
    let permissions = JSON.parse(requisition?.preSelectedSignatory);

    if (permissions) {
      //  user we want  to  send  to
      const lastTrack = requisitiontracks.length + 1;
      setReceiver(permissions[lastTrack]);
    }
  };

  const getRequisitionDetails = async (id) => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/requisition/${id}`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    if (data.requisition?.filledFormData) {
      getNextUserToPerformAction(data.requisition);
    } else if (data.requisition?.preSelectedSignatory) {
      getNextUserToPerformActionForSystemForm(data.requisition);
    }
    return data;
  };

  const {
    error,
    data: { requisition } = { requisition: null },
    isSuccess,
    refetch,
    isFetching,
  } = useQuery(
    [requisitionActions.GET_REQUISITION_DETAILS, id],
    () => getRequisitionDetails(id),
    {}
  );

  useEffect(() => {
    console.log(error);
  }, [error]);

  const reject = async ({ isReservedUser } = { isReservedUser: false }) => {
    console.log(isReservedUser);
    await RejectDialog({
      authUser,
      requisitionTitle: requisition.title,
      requisition: last(requisition?.requisitiontracks),
      previousRequisitionTrackId: last(requisition?.requisitiontracks).ID,
      isReservedUser,
    });
  };

  const approveAndClose = async (
    { isReservedUser } = { isReservedUser: false }
  ) => {
    if (
      lowerCase(requisition.type) === "fund request" &&
      isEmpty(requisition?.paymentType)
    ) {
      await ApproveAndCloseDialog({
        authUser,
        requisitionTitle: requisition.title,
        requisition: last(requisition?.requisitiontracks),
        previousRequisitionTrackId: last(requisition?.requisitiontracks).ID,
        isReservedUser,
      });

      // navigate(`/requisition/request/approve-and-close/${id}`);
    } else {
      await ApproveAndCloseDialog({
        authUser,
        requisitionTitle: requisition.title,
        requisition: last(requisition?.requisitiontracks),
        previousRequisitionTrackId: last(requisition?.requisitiontracks).ID,
        tableData: [],
        isReservedUser,
      });
    }
  };

  const approveOnly = async (
    { isReservedUser } = { isReservedUser: false }
  ) => {
    if (
      lowerCase(requisition.type) === "fund request" &&
      isEmpty(requisition?.paymentType)
    ) {
      await ApproveOnlyDialog({
        authUser,
        requisitionTitle: requisition.title,
        requisition: last(requisition?.requisitiontracks),
        previousRequisitionTrackId: last(requisition?.requisitiontracks).ID,
        // isReservedUser,
      });
    } else {
      await ApproveOnlyDialog({
        authUser,
        requisitionTitle: requisition.title,
        requisition: last(requisition?.requisitiontracks),
        previousRequisitionTrackId: last(requisition?.requisitiontracks).ID,
        tableData: [],
        isReservedUser,
      });
    }
  };

  const canReservedUserSend = useMemo(() => {
    if (!requisition) return false;

    const numberOfMandatoryApprovalsBeforeApproveAndClose = Number(
      generalSettings.numberOfMandatoryApprovalsBeforeApproveAndClose
    );

    // Reserved Users - Mandatory Signatories
    const reservedUsers = generalSettings.fundRequestMandatorySignatory.map(
      (el) => el.staff
    );

    const usersThatHaveApproved = uniq(
      requisition?.requisitiontracks.map((el) => {
        return el.status === "Approved" ? el.receivedby : el.sentby;
      })
    );

    // How many mandatory has Approved
    const howManyMandatoryUserHasApproved = size(
      usersThatHaveApproved.filter((el) => reservedUsers.includes(el))
    );

    // Has User Approved Before
    const hasUserApproved = usersThatHaveApproved.find(
      (el) => el === authUser.Staff_ID
    );

    return (
      !hasUserApproved &&
      Number(howManyMandatoryUserHasApproved) <
      Number(numberOfMandatoryApprovalsBeforeApproveAndClose) &&
      reservedUsers.includes(authUser?.Staff_ID) &&
      requisition.type === "Fund Request" &&
      !requisition.status.includes("Approved & Closed") &&
      !requisition.status.includes("Disapproved")
    );
  }, [requisition]);

  const canReservedUserPerformAction = useMemo(() => {
    if (!requisition) return false;
    const numberOfMandatoryApprovalsBeforeApproveAndClose = Number(
      generalSettings.numberOfMandatoryApprovalsBeforeApproveAndClose
    );

    // Reserved Users - Mandatory Signatories
    const reservedUsers = generalSettings.fundRequestMandatorySignatory.map(
      (el) => el.staff
    );

    const usersThatHaveApproved = uniq(
      requisition?.requisitiontracks.map((el) => {
        return el.status === "Approved" || el.status === "Approved & Closed"
          ? el.receivedby
          : el.sentby;
      })
    );

    // How many mandatory has Approved
    const howManyMandatoryUserHasApproved = size(
      usersThatHaveApproved.filter((el) => reservedUsers.includes(el))
    );

    // Has User Approved Before
    const hasUserApproved = usersThatHaveApproved.find(
      (el) => el === authUser.Staff_ID
    );

    /*  console.log(
      !hasUserApproved,
      Number(numberOfMandatoryApprovalsBeforeApproveAndClose) ===
        Number(howManyMandatoryUserHasApproved),
      reservedUsers.includes(authUser?.Staff_ID),
      requisition.type === "Fund Request",
      !requisition.status.includes("Approved & Closed"),
      !requisition.status.includes("Disapproved")
    ); */

    return (
      !hasUserApproved &&
      Number(numberOfMandatoryApprovalsBeforeApproveAndClose) ===
      Number(howManyMandatoryUserHasApproved) &&
      reservedUsers.includes(authUser?.Staff_ID) &&
      requisition.type === "Fund Request" &&
      !requisition.status.includes("Approved & Closed") &&
      !requisition.status.includes("Disapproved")
    );
  }, [requisition]);

  const canPerformAction = (requisitionData) => {
    if (!requisition) return false;

    // No Table Data that they need here
    if (["rfq", "po"].includes(lowerCase(requisition?.type))) {
      return false;
    }
    const pendingRequisition = last(requisitionData?.requisitiontracks);

    // By Department
    if (
      pendingRequisition?.sentToDepartment === authUser.Department &&
      pendingRequisition?.sentby !== authUser.Staff_ID &&
      pendingRequisition?.status === "Pending"
    ) {
      return true;
    }

    // console.log(pendingRequisition);
    return (
      pendingRequisition &&
      pendingRequisition?.status === "Pending" &&
      pendingRequisition?.receivedby === authUser.Staff_ID
    );
  };

  const commentOnRequisition = async () => {
    await LeaveCommentDialog({
      requisition,
      refetch,
    });
  };

  const canApproveAndClose = (requisition = {}) => {
    const resolveLimit = (selection) => {
      if (selection?.limitType === "any") return Infinity;
      return selection && selection.limit ? Number(selection.limit) : 0;
    };

    if (["rfq", "po"].includes(lowerCase(requisition?.type))) {
      return false;
    }

    // check if user is second level approval
    if (
      ["fund request"].includes(lowerCase(requisition?.type)) &&
      generalSettings?.secondLineFundRequestApproval &&
      generalSettings.secondLineFundRequestApproval.find(
        (el) => el.staff === authUser.Staff_ID
      )
    ) {
      const permissions = generalSettings?.secondLineFundRequestApproval
        ? generalSettings.secondLineFundRequestApproval
        : [];

      const forStaff = permissions.find((el) => el.staff === authUser.Staff_ID);
      const anyDepartment = permissions.find(
        (el) => el.department === "" && el.staff === ""
      );
      const anyJobGrade = permissions.find(
        (el) => el.department === authUser.Department && el.jobGrade === ""
      );
      const anyStaff = permissions.find(
        (el) =>
          el.department === authUser.Department &&
          el.jobGrade === authUser.jobGrade &&
          el.staff === ""
      );

      const limits = [
        resolveLimit(forStaff),
        resolveLimit(anyDepartment),
        resolveLimit(anyJobGrade),
        resolveLimit(anyStaff),
      ];

      const highestLimit = max(limits);
      return Number(requisition?.amount) <= Number(highestLimit);
    }
    // -----------------------------------------------------------------------------------------

    if (
      ["fund request", "material request"].includes(
        lowerCase(requisition?.type)
      ) &&
      requisition.requiredSignatoriesStaff_ID
    ) {
      let permissions = JSON.parse(requisition.requiredSignatoriesStaff_ID);

      //  for Staff
      const forStaff = permissions.find((el) => el.staff === authUser.Staff_ID);

      //  Department
      const anyDepartment = permissions.find(
        (el) => el.department === "" && el.staff === ""
      );

      //
      const anyJobGrade = permissions.find(
        (el) => el.department === authUser.Department && el.jobGrade === ""
      );

      const anyStaff = permissions.find(
        (el) =>
          el.department === authUser.Department &&
          el.jobGrade === authUser.jobGrade &&
          el.staff === ""
      );

      if ("fund request" === lowerCase(requisition?.type)) {
        const limits = [
          resolveLimit(forStaff),
          resolveLimit(anyDepartment),
          resolveLimit(anyJobGrade),
          resolveLimit(anyStaff),
        ];

        const highestLimit = max(limits);
        /*  console.log(
          highestLimit,
          requisition?.amount,
          Number(requisition?.amount) <= Number(highestLimit)
        ); */
        return Number(requisition?.amount) <= Number(highestLimit);
      } else {
        // console.log(forStaff, anyDepartment, anyJobGrade, anyStaff);
        return forStaff || anyDepartment || anyJobGrade || anyStaff;
      }
    } else {
      return true;
    }
  };

  const preSelectedSignatoryData = useMemo(() => {
    try {
      if (requisition?.preSelectedSignatory) {
        return JSON.parse(requisition?.preSelectedSignatory);
      }
      return [];
    } catch (err) {
      console.log(err);
      return [];
    }
  }, [requisition?.preSelectedSignatory]);

  return (
    <Modal
      show={true}
      onHide={() => goBack("/requisition/request")}
      dialogClassName="requisition-details-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      fullscreen={styleHelper.isMobile}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>
            {" "}
            Requisition Details{" "}
            <Button onClick={() => refetch()} variant="">
              <RefreshIcon />
            </Button>
          </h1>
          <p>More information about the request sent</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-end">
          <Nav
            variant="tabs"
            onSelect={handleSelect}
            // defaultActiveKey="1"
            activeKey={activeKey}
          >
            <Nav.Item>
              <Nav.Link className={`ga-nav-tab`} eventKey="details">
                Details
              </Nav.Link>
            </Nav.Item>

            {isInventoryOrFundRequest(requisition?.type) && (
              <Nav.Item>
                <Nav.Link
                  className={`ga-nav-tab`}
                // eventKey="requisition-form"
                >
                  <Link
                    to={`/requisition/request/preview/${id}`}
                    state={{ backgroundLocation: location }}
                  >
                    Requisition Form
                  </Link>
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
          {/*
          <Link
            to={`/requisition/request/conversations/${id}`}
            state={{ backgroundLocation: location }}
            className="btn border text-primary message-btn"
          >
            <MessageProcessingOutlineIcon />
          </Link> */}
        </div>

        {activeKey === "details" ? (
          <>
            <div className="info p-3 my-3 border rounded">
              <p className="border-bottom pb-3 mb-3 d-flex gap-3 title">
                <span>From:</span>
                <span className="fw-5">
                  {requisition
                    ? getUserThatRequestedToMe({
                      requisitionTracks: requisition?.requisitiontracks,
                      receivedby: authUser?.Staff_ID,
                    })
                    : ""}
                </span>
              </p>

              <h2>{requisition?.title}</h2>
              <p>{requisition?.type}</p>

              <div className="d-md-flex">
                <Table borderless className="info-table mb-0 mb-md-3">
                  <tbody>
                    <tr>
                      <td>ID requisition :</td>
                      <td>#{id}</td>
                    </tr>
                    <tr>
                      <td>Date:</td>
                      <td>
                        {requisition?.regdate
                          ? format(
                            new Date(requisition?.regdate),
                            "dd / MM / yyyy"
                          )
                          : "..."}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Table borderless className="info-table mb-0 mb-md-3">
                  <tbody>
                    <tr>
                      <td>Status :</td>
                      <td>
                        <ApprovalBadge
                          text={requisition?.status}
                          className="approval"
                          bg={resolveApprovalBadgeBg(requisition?.status)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Requester:</td>
                      <td className="fw-5">
                        {requisition?.requestbyUser?.Name ||
                          requisition?.sentByName ||
                          "..."}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>

            <div className="approval p-3 my-3 border rounded">
              <p className="border-bottom pb-3 mb-3 title">
                <span>Approval</span>
              </p>

              <div className="approval-history">
                {requisition?.requisitiontracks?.map((el, i, array) => (
                  <div key={i} className="item">
                    {i + 1 != array.length && <div className="line" />}
                    {lowerCase(el.status) === "pending" ? (
                      <>
                        <div className="timeline border">
                          <PendingCheckIcon />
                        </div>

                        <div className="detail">
                          <Avatar
                            className="image"
                            name={el.receivedbyUser?.Name}
                            userId={el.receivedbyUser?.Staff_ID}
                          />

                          <div>
                            <ApprovalBadge
                              text={el.status}
                              className="approval"
                              bg="orange-warning"
                            />
                            <p className="mb-1">
                              <span className="fw-5">
                                {el.sentbyUser?.Name || el?.sentByName || "..."}
                              </span>{" "}
                              is waiting for a response from{" "}
                              <span className="fw-5">
                                {el.receivedbyUser?.Name ||
                                  el?.sentToDepartment ||
                                  "..."}
                              </span>
                            </p>
                            <i>{el?.comment}</i>

                            <Comments
                              refetch={refetch}
                              requisitionComments={el.requisitionComments}
                              requisitionid={requisition?.requestid}
                            />
                          </div>
                        </div>
                      </>
                    ) : lowerCase(el.status) === "disapproved" ? (
                      <>
                        <div className="timeline border">
                          <DisapprovedCheckIcon />
                        </div>

                        <div className="detail">
                          <Avatar
                            className="image"
                            name={el.receivedbyUser?.Name}
                            userId={el.receivedbyUser?.Staff_ID}
                          />

                          <div>
                            <ApprovalBadge
                              text={el.status}
                              className="approval"
                              bg="danger"
                            />
                            <p className="mb-1">
                              <span className="fw-5">
                                {el.receivedbyUser?.Name ||
                                  el?.receivedByName ||
                                  "..."}
                              </span>{" "}
                              disapproved a request from{" "}
                              <span className="fw-5">
                                {el.sentbyUser?.Name || el?.sentByName || "..."}
                              </span>
                            </p>
                            <i>{el?.comment}</i>

                            <Comments
                              refetch={refetch}
                              requisitionComments={el.requisitionComments}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="timeline border">
                          <ApproveCheckIcon />{" "}
                        </div>

                        <div className="detail">
                          <Avatar
                            className="image"
                            name={el.receivedbyUser?.Name}
                            userId={el.receivedbyUser?.Staff_ID}
                          />

                          <div>
                            <ApprovalBadge
                              text={el.status}
                              className={"approval"}
                              bg={resolveApprovalBadgeBg(el.status)}
                            />
                            <p className="mb-1">
                              {el.status === "Approved & Sent" ? (
                                <>
                                  <span className="fw-5">
                                    {el.receivedbyUser?.Name ||
                                      el?.receivedByName ||
                                      "..."}
                                  </span>{" "}
                                  has <span className="fw-5">approved</span> and
                                  sent
                                </>
                              ) : el.status === "Approved & Closed" ? (
                                <>
                                  <span className="fw-5">
                                    {el.receivedbyUser?.Name ||
                                      el?.receivedByName ||
                                      "..."}
                                  </span>{" "}
                                  has <span className="fw-5">approved</span> and
                                  closed
                                </>
                              ) : (
                                <>
                                  <span className="fw-5">
                                    {el.receivedbyUser?.Name ||
                                      el?.receivedByName ||
                                      "..."}{" "}
                                    {el.status}
                                  </span>{" "}
                                </>
                              )}
                            </p>
                            <i>{el?.comment}</i>

                            <Comments
                              refetch={refetch}
                              requisitionComments={el.requisitionComments}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          {
            /* <div className="info p-3 my-3 border rounded">
                  <h2>Material Marketing Request</h2>

                  <Table borderless className="info-table">
                    <tbody>
                      <tr className="text-dark">
                        <td>Type:</td>
                        <td>
                          Procurement request <br />
                          <span className="text-light">
                            Material Procurement
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  <Link
                    to={`/requisition/preview/${id}`}
                    state={{ backgroundLocation: location }}
                    replace
                    className="btn btn-l-ght w-100 text-primary border-0 bg-light-blue preview-btn lh-1"
                  >
                    Preview Requisition Form
                  </Link>
              </div>*/
          }
        )}
        <div className="d-flex gap-3">
          {(canApproveAndClose(requisition) || isAdmin) &&
            requisition?.status?.includes("Approved & Closed") ? (
            <Button
              variant="light-blue"
              onClick={() =>
                navigate(`/requisition/request/approve-and-close/${id}`)
              }
            >
              <UserIcon />
              Send To
            </Button>
          ) : null}
          <Button variant="light-blue" onClick={() => commentOnRequisition()}>
            <MessageIcon />
            {"  "}
            Comment
          </Button>
        </div>
      </Modal.Body>
      <RequisitionApproveFooter
        {...{
          requisition,
          canPerformAction,
          canReservedUserSend,
          reciever,
          approveAndClose,
          canReservedUserPerformAction,
          reject,
          preSelectedSignatoryData,
          approveOnly,
          id,
          canApproveAndClose,
          location,
        }}
      />
    </Modal>
  );
}
