import { Modal, Form, Button } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import Select from "react-select";
import Creatable from "react-select/creatable";
import ModalLoader from "./utils/ModalLoader";
import { useAuth } from "../hooks/useAuth";
import { fetchActionsUtil } from "../utils/helpers";
import ConfirmDialog from "./ConfirmDialogue";
import CachedIcon from "mdi-react/CachedIcon";
import { isEmpty } from "lodash";
import { useCustomerBackendUrl } from "../utils/hooks";

export default function CitySelector({ formik }) {
  const [cities, setcities] = useState([]);
  const [allStates, setAllStates] = useState();
  const [allCountries, setAllCountries] = useState();
  const [fetchingCities, setFetchingCities] = useState(false);
  const { backendUrl } = useAuth();
  const customerBackendUrl = useCustomerBackendUrl();

  const fetchICountries = async () => {
    const { data } = await fetchActionsUtil(
      `${backendUrl || customerBackendUrl}/api/customers/countries`
    );
    setAllCountries([
      { label: "Select Country", value: "" },
      ...data.map((el) => ({ label: el?.name, value: el?.name })),
    ]);
  };

  const fetchStates = async (country) => {
    const { states } = await fetchActionsUtil(
      `${backendUrl ||
      customerBackendUrl}/api/customers/nigeria-states?country=${country}`
    );
    setAllStates([
      { label: "Select State", value: "" },
      ...states.map((el) => ({ label: el, value: el })),
    ]);
  };

  const { data = { count: 0, costs: [] }, refetch, isFetching } = useQuery(
    ["GET_ALL_SHOPPING_COST_FOR_SELECTOR"],
    () => fetchICountries(),
    {
      keepPreviousData: true,
    }
  );

  const fetchLGA = async (state) => {
    if (!state) return;
    setFetchingCities(true);
    const { lga } = await fetchActionsUtil(
      `${backendUrl || customerBackendUrl}/api/customers/nigeria-lga/${state}`
    );

    if (lga) {
      setcities([
        { label: "Select City", value: "" },
        ...lga?.map((el) => ({ label: el, value: el })),
      ]);
    }
    setFetchingCities(false);
  };

  useEffect(() => {
    if (formik.values.state) {
      fetchLGA(formik.values.state);
    }
  }, [formik.values.state]);
  useEffect(() => {
    if (formik.values.country) {
      fetchStates(formik.values.country);
    }
  }, [formik.values.country]);

  return (
    <>
      <Form.Group className="col mb-3 pb-1">
        <Form.Label>
          Country
          <Button variant="" onClick={() => refetch()}>
            <CachedIcon />
          </Button>
        </Form.Label>

        <Creatable
          classNamePrefix={`form-select`}
          placeholder="Select or input your state / province"
          options={allCountries}
          isValidNewOption={() => false}
          value={allCountries?.find((el) => el.value === formik.values.country)}
          onChange={({ value }) => {
            fetchStates(value);
            formik.setFieldValue("country", value);
            formik.setFieldValue("state", "");
          }}
          onBlur={() => formik.setFieldTouched("country", true)}
          isLoading={isFetching}
          inputProps={{ autoComplete: "off" }}
        />
        {formik.touched.state && !!formik.errors.state ? (
          <span className="custom-invalid-feedback">{formik.errors.state}</span>
        ) : null}
      </Form.Group>
      <Form.Group className="col mb-3 pb-1">
        <Form.Label>
          State / Province{" "}
          {/* <Button variant="" onClick={() => refetch()}>
            <CachedIcon />
          </Button> */}
        </Form.Label>

        <Select
          classNamePrefix={`form-select`}
          placeholder="Select"
          options={allStates}
          value={
            allStates?.find((el) => el.value === formik.values.state) || {
              value: formik.values.state,
              label: formik.values.state,
            }
          }
          onChange={({ value }) => {
            fetchLGA(value);
            formik.setFieldValue("state", value);
            formik.setFieldValue("LGA", "");
          }}
          onBlur={() => formik.setFieldTouched("state", true)}
          isLoading={isFetching}
          inputProps={{ autoComplete: "off" }}
        />
        {formik.touched.state && !!formik.errors.state ? (
          <span className="custom-invalid-feedback">{formik.errors.state}</span>
        ) : null}
      </Form.Group>

      <Form.Group className="col mb-3 pb-1">
        <Form.Label>City</Form.Label>
        <Creatable
          isLoading={fetchingCities}
          isSearchable
          classNamePrefix={`form-select`}
          placeholder="Select or input your city"
          options={cities || []}
          value={
            cities?.find((el) => el.value === formik.values.LGA) || {
              value: formik.values.LGA,
              label: formik.values.LGA,
            }
          }
          onChange={({ value }) => formik.setFieldValue("LGA", value)}
          inputProps={{ autoComplete: "off" }}
        />
      </Form.Group>
    </>
  );
}
// export default function CitySelector({ formik }) {
//   const [cities, setcities] = useState([]);
//   const [allStates, setAllStates] = useState();
//   const [fetchingCities, setFetchingCities] = useState(false);
//   const { backendUrl } = useAuth();
//   const customerBackendUrl = useCustomerBackendUrl();

//   const fetchICountries = async () => {
//     const { states } = await fetchActionsUtil(
//       `${backendUrl || customerBackendUrl}/api/customers/countries`
//     );
//     setAllStates([
//       { label: "Select State", value: "" },
//       ...states.map((el) => ({ label: el, value: el })),
//     ]);
//   };
//   const fetchItems = async () => {
//     const { states } = await fetchActionsUtil(
//       `${backendUrl || customerBackendUrl}/api/customers/nigeria-states`
//     );
//     setAllStates([
//       { label: "Select State", value: "" },
//       ...states.map((el) => ({ label: el, value: el })),
//     ]);
//   };

//   const { data = { count: 0, costs: [] }, refetch, isFetching } = useQuery(
//     ["GET_ALL_SHOPPING_COST_FOR_SELECTOR"],
//     () => fetchItems(),
//     {
//       keepPreviousData: true,
//     }
//   );

//   const fetchLGA = async (state) => {
//     if (!state) return;
//     setFetchingCities(true);
//     const { lga } = await fetchActionsUtil(
//       `${backendUrl || customerBackendUrl}/api/customers/nigeria-lga/${state}`
//     );

//     if (lga) {
//       setcities([
//         { label: "Select City", value: "" },
//         ...lga?.map((el) => ({ label: el, value: el })),
//       ]);
//     }
//     setFetchingCities(false);
//   };

//   useEffect(() => {
//     if (formik.values.state) {
//       fetchLGA(formik.values.state);
//     }
//   }, [formik.values.state]);

//   return (
//     <>
//       <Form.Group className="col mb-3 pb-1">
//         <Form.Label>
//           State{" "}
//           <Button variant="" onClick={() => refetch()}>
//             <CachedIcon />
//           </Button>
//         </Form.Label>

//         <Select
//           classNamePrefix={`form-select`}
//           options={allStates}
//           value={allStates?.find((el) => el.value === formik.values.state)}
//           onChange={({ value }) => {
//             fetchLGA(value);
//             formik.setFieldValue("state", value);
//             formik.setFieldValue("LGA", "");
//           }}
//           onBlur={() => formik.setFieldTouched("state", true)}
//           isLoading={isFetching}
//         />
//         {formik.touched.state && !!formik.errors.state ? (
//           <span className="custom-invalid-feedback">{formik.errors.state}</span>
//         ) : null}
//       </Form.Group>

//       <Form.Group className="col mb-3 pb-1">
//         <Form.Label>City</Form.Label>
//         <Select
//           isLoading={fetchingCities}
//           classNamePrefix={`form-select`}
//           options={cities}
//           value={cities?.find((el) => el.value === formik.values.LGA)}
//           onChange={({ value }) => formik.setFieldValue("LGA", value)}
//         />
//       </Form.Group>
//     </>
//   );
// }
