import Modal from "react-bootstrap/Modal";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import { useState, useMemo, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import Select from "react-select";

import { reportActions } from "../../utils/reactQueryActions";
import { fetchActionsUtil, allAcountDetailIDs } from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";
import ModalLoader from "../utils/ModalLoader";
import ConfirmDialog from "../ConfirmDialogue";
import { useEffectOnce } from "../../utils/hooks";
import { isEmpty, toUpper } from "lodash";
import { DeleteIcon } from "../Icons";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import ExpensesSelector from "../utils/ExpensesSelector";
import currency from "currency.js";

const DeleteAccounts = (props) => {
  const [accountDetails, setAccountDetails] = useState([]);
  const [allAccoutTypes, setAllAccoutTypes] = useState([]);
  const [misTypeError, setMisTypeError] = useState("");
  const [accID, setAccID] = useState("");

  const { backendUrl } = useAuth();

  const fetchDetailTypeMutation = useMutation(
    (name) =>
      fetchActionsUtil(`${backendUrl}/api/accounts/accountDesc/${name}`, "GET"),
    {
      onError: ({ message = "" }) => {
        toast.error(message);
      },
    }
  );

  const fetchGenerateAccountIdMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/accounts/generate-account-id`,
        "POST",
        "",
        payload
      ),
    {
      onError: ({ message = "" }) => {
        toast.error(message);
      },
    }
  );

  const getAccountDetails = async (name, usage = "") => {
    fetchDetailTypeMutation.mutate(name, {
      onSuccess: ({ accountDes }) => {
        const data = [...new Set(accountDes.map((d) => d.AccountDesc))].map(
          (el) => ({
            value: el,
            label: el,
          })
        );
        setAccountDetails(data);
        if (usage === "once") return;
        if (name === "CASH AND CASH EQUIVALENT") {
          formik.setFieldValue("DetailType", "BANK");
        } else {
          formik.setFieldValue("DetailType", data[0]?.value);
        }
      },
    });

    // var random = Math.round(Math.random() * (max - min) + min);

    let rangers = allAcountDetailIDs.filter((item) => {
      if (item.data.some((d) => d === name)) {
        return [item.num1, item.num2];
      }
    });

    fetchGenerateAccountIdMutation.mutate(
      { num1: rangers[0].num1, num2: rangers[0].num2 },
      {
        onSuccess: (res) => {
          formik.setFieldValue("AccountID", res.id);
        },
      }
    );
  };

  const { data } = useQuery(
    ["GET_ACCOUNT_TYPES"],
    () => fetchActionsUtil(`${backendUrl}/api/accounts/accountTypes`, "GET"),
    {
      keepPreviousData: true,
    }
  );

  useMemo(() => {
    const mainData = data?.accountTypes?.map((d) => ({
      value: d.AccountType,
      label: d.AccountType,
    }));
    setAllAccoutTypes(mainData);
  }, [data]);

  const warehouseData = useMemo(() => {
    return props?.warehouse ? props?.warehouse : "";
  }, [props?.warehouse]);

  useEffectOnce(() => {
    // -------
    if (["imprest", "cash on hand", "bank"].includes(props?.usage)) {
      formik.setFieldValue("Type", "CASH AND CASH EQUIVALENT");
      formik.setFieldValue("DetailType", toUpper(props.usage));
      getAccountDetails("CASH AND CASH EQUIVALENT", "once");
    }
    if (props?.type) {
      formik.setFieldValue("Type", props?.type);
      getAccountDetails(props?.type, "once");
    }
  });

  useEffect(() => {
    if (props?.warehouse) {
      formik.setFieldValue("Type", "FIXED ASSET");
      formik.setFieldValue("DetailType", "STOCK");
      formik.setFieldValue("Description", warehouseData);
      formik.setFieldValue("Account_Desc", warehouseData);
      getAccountDetails("FIXED ASSET", "once");
    }
  }, [props?.warehouse]);

  const sendAccountMutation = useMutation(
    (payload) =>
      fetchActionsUtil(`${backendUrl}/api/accounts/delete`, "POST", "", payload),
    {
      onSuccess: ({ message, accountCreated }) => {
        formik.resetForm({
          values: {
            AccountID: "",
            Description: "",
            Account_Desc: "",
            Type: "",
            DetailType: "",
          },
        });
        toast.success(message);
        if (props?.refetch) props.refetch();
        props.setAccountReturn_WH && accID && props.setAccountReturn_WH(accID);
        // if (props.accountCreated) props.accountCreated(accountCreated);
        props.onHide();
      },
      onError: ({ message = "" }) => {
        toast.error(message);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      AccountID: props.selectedAccountToDelete.AccountID,
      Description: props.selectedAccountToDelete.Description,
      Account_Desc: props.selectedAccountToDelete.Account_Desc,
      Type: props.selectedAccountToDelete.Type,
      DetailType: props.selectedAccountToDelete.DetailType,
      accountToMovePreviousEntriesToAccountID: '',
      accountToMovePreviousEntriesToDescription: '',
      accountToMovePreviousEntriesToType: ''
    },
    validationSchema: yup.object().shape({
      AccountID: yup.string().required("Account ID is required"),
      Description: yup.string().required("Account Name is required"),
      Account_Desc: yup.string().required("Description is required"),
      Type: yup.string().required("Account Type is required"),
      DetailType: yup.string().required("Type Detail is required"),
    }),
    onSubmit: async (values) => {
      if (misTypeError) {
        return;
      }
      // console.log(values);
      setAccID(values.AccountID);

      if ((props.selectedAccountToDelete.TotalDebit || props.selectedAccountToDelete.TotalCredit) && isEmpty(values.accountToMovePreviousEntriesToAccountID)) {
        toast.error('Please select account where previous entries will be moved to')
        return
      }

      if (
        await ConfirmDialog({
          type: 'danger',
          title: "Delete Account",
          description: "Are you sure you want to delete? \n This action cannot be undone.",
        })
      ) {
        sendAccountMutation.mutate(values);
      }
    },
  });

  useMemo(() => {
    if (formik.values.AccountID !== "" && formik.values.Type !== "") {
      allAcountDetailIDs.every((el) => {
        if (
          el.data.some((d) => d === formik.values.Type) &&
          (parseInt(formik.values.AccountID) < el.num1 ||
            parseInt(formik.values.AccountID) > el.num2)
        ) {
          setMisTypeError(`Enter number from ${el.num1} to ${el.num2}`);
          return false;
        }
        setMisTypeError("");
        return true;
      });
    }
  }, [formik.values.AccountID, formik.values.Type]);

  const matches = useMemo(() => {
    const data = [];
    if (formik.values.Type) {
      allAcountDetailIDs.every((el) => {
        if (el.data.some((d) => d === formik.values.Type)) {
          data.push(el.num1, el.num2);
          return false;
        }
        return true;
      });
    }
    return data;
  }, [formik.values.Type]);


  // GA ===============================================================
  const [showJournalPopover, setShowJournalPopover] = useState(false);
  const handleSelectedAccount = (account) => {
    // console.log(account);
    formik.setFieldValue("accountToMovePreviousEntriesToAccountID", account?.AccountID);
    formik.setFieldValue("accountToMovePreviousEntriesToDescription", account?.Description);
    formik.setFieldValue("accountToMovePreviousEntriesToType", account?.Type);
    setShowJournalPopover(false);
  };
  // ===================================================================

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      backdropClassName={`global-backdrop`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="my-style-modal"
      animation={false}
      enforceFocus={false}
      size="md"
      contentClassName="border border-danger"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <header>
            <h1>Delete Account</h1>
          </header>

          <p>Delete Account</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="px-md-4 m-auto">
            <form
              noValidate
              onSubmit={formik.handleSubmit}
              className="form-relative"
            >
              <Form.Group as={Row} className="mb-3" controlId="Account ID">
                <Form.Label column sm="4">
                  Account ID
                </Form.Label>
                <Col sm="8">
                  <p>{formik.values.AccountID}</p>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="Account ID">
                <Form.Label column sm="4">
                  Account Name*
                </Form.Label>
                <Col sm="8">
                  <p>{formik.values.Description}</p>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="Account ID">
                <Form.Label column sm="4">
                  Description
                </Form.Label>
                <Col sm="8">
                  <p>{formik.values.Account_Desc}</p>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="Account ID">
                <Form.Label column sm="4">
                  Account Type
                </Form.Label>
                <Col sm="8">
                  {formik.values.Type}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="Account ID">
                <Form.Label column sm="4">
                  Type Detail
                </Form.Label>
                <Col sm="8">
                  {formik.values.DetailType}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="Account ID">
                <Form.Label column sm="4">
                  Type Detail
                </Form.Label>
                <Col sm="8">
                  {formik.values.DetailType}
                </Col>
              </Form.Group>

              <hr />

              <Form.Group as={Row} className="mb-3" controlId="Account ID">
                <Form.Label column sm="4">
                  Total Debit
                </Form.Label>
                <Col sm="8">
                  {currency(props?.selectedAccountToDelete.TotalDebit, {
                    symbol: ''
                  }).format()}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="Account ID">
                <Form.Label column sm="4">
                  Total Credit
                </Form.Label>
                <Col sm="8">
                  {currency(props?.selectedAccountToDelete.TotalCredit, {
                    symbol: ''
                  }).format()}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="Account ID">
                <Form.Label column sm="4">
                  Balance
                </Form.Label>
                <Col sm="8">
                  {currency(props?.selectedAccountToDelete.Balance, {
                    symbol: ''
                  }).format()}
                </Col>
              </Form.Group>


              <Form.Group className="mb-3">
                <Form.Label>Move Previous Entries to </Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    placeholder="Select Account"
                    value={`${formik.values?.accountToMovePreviousEntriesToAccountID || "..."} / ${formik
                      .values?.accountToMovePreviousEntriesToDescription || "..."} / ${formik
                        .values?.accountToMovePreviousEntriesToType || "..."}`}
                    onChange={() => { }}
                    readOnly
                  />

                  <Popover
                    isOpen={showJournalPopover}
                    onClickOutside={() => setShowJournalPopover(false)}
                    content={() => (
                      <ExpensesSelector
                        handleSelectedExpense={handleSelectedAccount}
                        usage={"chart of accounts"}
                        onHide={() => setShowJournalPopover(false)}
                        Type={formik.values.Type}
                      />
                    )}
                    position="bottom"
                  >
                    <InputGroup.Text
                      onClick={() => setShowJournalPopover(true)}
                    >
                      <MagnifyIcon />
                    </InputGroup.Text>
                  </Popover>
                </InputGroup>
              </Form.Group>


            </form>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn bg print mx-3 btn-lg"
          onClick={props.onHide}
        >
          Cancel
        </button>

        <button
          onClick={() => formik.submitForm()}
          className="btn btn-danger btn-lg text-white"
        >
          Delete
        </button>
      </Modal.Footer>
      <ModalLoader show={sendAccountMutation.isLoading} />
    </Modal>
  );
};

export default DeleteAccounts;
